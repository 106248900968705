import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./footer";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import CountUp from "react-countup";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
} from "@material-ui/core";
import saudee from "../img/saude.png";
import ideiass from "../img/ideias.png";
import estudoss from "../img/estudos.png";
import denunciass from "../img/denuncias.png";
import { NavLink } from "react-router-dom";
import Sidebar from "./appbarAdmin";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(17),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    /*     backgroundColor: "#0f172b", */
  },

  cardContent: {
    flexGrow: 1,
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const [openSide, setOpenSide] = useState(false);

  /* -------CONTADORES PARA DENÚNCIAS------- */
  const [denuncias, setDenuncias] = useState();
  const [denunciasPendentes, setDenunciasPendentes] = useState();
  const [denunciasAprovadas, setDenunciasAprovadas] = useState();
  const [denunciasRejeitadas, setDenunciasRejeitadas] = useState();
  const [denunciasFechadas, setDenunciasFechadas] = useState();

  /* -------CONTADORES PARA SAÚDE------- */
  const [saude, setSaude] = useState();
  const [saudePendentes, setSaudePendentes] = useState();
  const [saudeAprovadas, setSaudeAprovadas] = useState();
  const [saudeRejeitadas, setSaudeRejeitadas] = useState();
  const [saudeFechadas, setSaudeFechadas] = useState();

  /* -------CONTADORES PARA ESTUDOS------- */
  const [estudos, setEstudos] = useState();
  const [estudosPendentes, setEstudosPendentes] = useState();
  const [estudosAprovadas, setEstudosAprovadas] = useState();
  const [estudosRejeitadas, setEstudosRejeitadas] = useState();
  const [estudosFechadas, setEstudosFechadas] = useState();

  /* -------CONTADORES PARA IDEIAS------- */
  const [ideias, setIdeias] = useState();
  const [ideiasPendentes, setIdeiasPendentes] = useState();
  const [ideiasAprovadas, setIdeiasAprovadas] = useState();
  const [ideiasRejeitadas, setIdeiasRejeitadas] = useState();
  const [ideiasFechadas, setIdeiasFechadas] = useState();

  /* -------CONTADORES PARA DOADORES------- */
  const [doadores, setDoadores] = useState();

  /* -------CONTADORES PARA UTILIZADORES------- */
  const [utilizadores, setUtilizadores] = useState();
  const [utilizadoresNormal, setUtilizadoresNormal] = useState();
  const [utilizadoresAdmin, setUtilizadoresAdmin] = useState();
  const [utilizadoresSuperAdmin, setUtilizadoresSuperAdmin] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/contarTipo/Denuncia`)
      .then((response) => response.json())
      .then((data) => {
        setDenuncias(data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/contarTipo/Saude`)
      .then((response) => response.json())
      .then((data) => {
        setSaude(data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/contarTipo/Estudos`)
      .then((response) => response.json())
      .then((data) => {
        setEstudos(data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/contarTipo/Projecto`)
      .then((response) => response.json())
      .then((data) => {
        setIdeias(data);
      });
      fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/countAll`)
      .then((response) => response.json())
      .then((data) => {
        setUtilizadores(data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/countByTipo/Normal`)
      .then((response) => response.json())
      .then((data) => {
        setUtilizadoresNormal(data);
      });
      fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/countByTipo/Super Administrador`)
      .then((response) => response.json())
      .then((data) => {
        setUtilizadoresSuperAdmin(data);
      });
      fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/countByTipo/Administrador`)
      .then((response) => response.json())
      .then((data) => {
        setUtilizadoresAdmin(data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/doadores/countByDoador`)
      .then((response) => response.json())
      .then((data) => {
        setDoadores(data);
      });
    return;
  }, []);

  /* -----------DENÚNCIAS-------------- */
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Denuncia",
        estado: "Pendente",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDenunciasPendentes(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Denuncia",
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDenunciasAprovadas(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Denuncia",
        estado: "Rejeitado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDenunciasRejeitadas(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Denuncia",
        estado: "Fechado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDenunciasFechadas(data);
      });
  }, []);

  /* -----------SAÚDE-------------- */
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        estado: "Pendente",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSaudePendentes(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSaudeAprovadas(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        estado: "Rejeitado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSaudeRejeitadas(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        estado: "Fechado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSaudeFechadas(data);
      });
  }, []);

  /* -----------ESTUDOS-------------- */
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Estudos",
        estado: "Pendente",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setEstudosPendentes(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Estudos",
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setEstudosAprovadas(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Estudos",
        estado: "Rejeitado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setEstudosRejeitadas(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Estudos",
        estado: "Fechado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setEstudosFechadas(data);
      });
  }, []);

  /* -----------IDEIAS-------------- */
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Projecto",
        estado: "Pendente",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIdeiasPendentes(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Projecto",
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIdeiasAprovadas(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Projecto",
        estado: "Rejeitado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIdeiasRejeitadas(data);
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Projecto",
        estado: "Fechado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/countByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIdeiasFechadas(data);
      });
  }, []);

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        {/* End hero unit */}
        {/*         <div className=="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h2 className=="section-title ff-secondary text-center text-primary fw-normal mb-3">
            Dados da plataforma
          </h2>
        </div> */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <div className="d-flex  justify-content-end dropdownrounded">
                <Card className="bg-dark w-25">
                  <div className="text-center">
                    <h3 className="text-primary">
                      <CountUp
                        className="account-balance"
                        start={0}
                        end={denuncias}
                        duration={5}
                        useEasing={true}
                        useGrouping={true}
                      />
                    </h3>
                  </div>
                </Card>
              </div>
              <CardContent className={classes.cardContent}>
                <h2 className="text-dark">Denúncias</h2>
              </CardContent>
              <CardMedia className="sub-link">
                {/* <img
                  src={menina2}
                  alt=""
                  title="Alzira Xerinda"
                  style={{ width: "40%" }}
                /> */}
                <img src={denunciass} alt="Denúncias" style={{ width: "50px" }}/>
{/*                 <EqualizerIcon
                  className="text-primary"
                  style={{ fontSize: 40 }}
                /> */}
              </CardMedia>

              <div className="divider"></div>
              <div className="d-flex  justify-content-between">
                <NavLink
                  to={"/denuncias/pendentes"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Pendentes <br /> ({denunciasPendentes}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/denuncias/aprovadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Aprovadas <br /> ({denunciasAprovadas}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/denuncias/rejeitadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Rejeitadas <br /> ({denunciasRejeitadas}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/denuncias/fechadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Fechadas <br /> ({denunciasFechadas}){" "}
                </NavLink>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <div className="d-flex  justify-content-end dropdownrounded">
                <Card className="bg-dark w-25">
                  <div className="text-center">
                    <h3 className="text-primary">
                      <CountUp
                        className="account-balance"
                        start={0}
                        end={saude}
                        duration={5}
                      />
                    </h3>
                  </div>
                </Card>
              </div>
              <CardContent className={classes.cardContent}>
                <h2 className="text-dark">Saúde</h2>
              </CardContent>
              <CardMedia className="sub-link">
                {/* <img
                  src={menina2}
                  alt=""
                  title="Alzira Xerinda"
                  style={{ width: "40%" }}
                /> */}
                <img src={saudee} alt="Saúde" style={{ width: "50px" }}/>
                {/* <MultilineChartIcon
                  className="text-primary"
                  style={{ fontSize: 40 }}
                /> */}
              </CardMedia>

              <div className="divider"></div>
              <div className="d-flex  justify-content-between">
                <NavLink
                  to={"/saude/pendentes"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Pendentes <br /> ({saudePendentes}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/saude/aprovadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Aprovadas <br /> ({saudeAprovadas}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/saude/rejeitadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Rejeitadas <br /> ({saudeRejeitadas}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/saude/fechadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Fechadas <br /> ({saudeFechadas}){" "}
                </NavLink>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <div className="d-flex  justify-content-end dropdownrounded">
                <Card className="bg-dark w-25">
                  <div className="text-center">
                    <h3 className="text-primary">
                      <CountUp
                        className="account-balance"
                        start={0}
                        end={estudos}
                        duration={5}
                      />
                    </h3>
                  </div>
                </Card>
              </div>
              <CardContent className={classes.cardContent}>
                <h2 className="text-dark">Estudos</h2>
              </CardContent>
              <CardMedia className="sub-link">
                {/* <img
                  src={menina2}
                  alt=""
                  title="Alzira Xerinda"
                  style={{ width: "40%" }}
                /> */}
                <img src={estudoss} alt="Estudos" style={{ width: "50px" }}/>
                {/* <DonutSmallIcon
                  className="text-primary"
                  style={{ fontSize: 40 }}
                /> */}
              </CardMedia>

              <div className="divider"></div>
              <div className="d-flex  justify-content-between">
                <NavLink
                  to={"/estudos/pendentes"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Pendentes <br /> ({estudosPendentes}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/estudos/aprovadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Aprovadas <br /> ({estudosAprovadas}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/estudos/rejeitadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Rejeitadas <br /> ({estudosRejeitadas}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/estudos/fechadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Fechadas <br /> ({estudosFechadas}){" "}
                </NavLink>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <div className="d-flex  justify-content-end dropdownrounded">
                <Card className="bg-dark w-25">
                  <div className="text-center">
                    <h3 className="text-primary">
                      <CountUp
                        className="account-balance"
                        start={0}
                        end={ideias}
                        duration={5}
                      />
                    </h3>
                  </div>
                </Card>
              </div>
              <CardContent className={classes.cardContent}>
                <h2 className="text-dark">Ideias</h2>
              </CardContent>
              <CardMedia className="sub-link">
                {/* <img
                  src={menina2}
                  alt=""
                  title="Alzira Xerinda"
                  style={{ width: "40%" }}
                /> */}
                <img src={ideiass} alt="Ideias" style={{ width: "50px" }}/>
                {/* <TableChartIcon
                  className="text-primary"
                  style={{ fontSize: 40 }}
                /> */}
              </CardMedia>

              <div className="divider"></div>
              <div className="d-flex  justify-content-between">
                <NavLink
                  to={"/ideias/pendentes"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Pendentes <br /> ({ideiasPendentes}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/ideias/aprovadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Aprovadas <br /> ({ideiasAprovadas}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/ideias/rejeitadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Rejeitadas <br /> ({ideiasRejeitadas}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/ideias/fechadas"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Fechadas <br />({ideiasFechadas}){" "}
                </NavLink>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <div className="d-flex  justify-content-end dropdownrounded">
                <Card className="bg-dark w-25">
                  <div className="text-center">
                    <h3 className="text-primary">
                      <CountUp
                        className="account-balance"
                        start={0}
                        end={doadores}
                        duration={5}
                      />
                    </h3>
                  </div>
                </Card>
              </div>
              <CardContent className={classes.cardContent}>
                <h2 className="text-dark">Doadores</h2>
              </CardContent>
              <CardMedia className="sub-link">
                {/* <img
                  src={menina2}
                  alt=""
                  title="Alzira Xerinda"
                  style={{ width: "40%" }}
                /> */}
                <Icon className="text-primary" icon="fa6-solid:handshake-angle" width="61" height="61" />
                {/* <PanToolIcon
                  className="text-primary"
                  style={{ fontSize: 40 }}
                /> */}
              </CardMedia>

              <div className="divider"></div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <div className="d-flex  justify-content-end dropdownrounded">
                <Card className="bg-dark w-25">
                  <div className="text-center">
                    <h3 className="text-primary">
                      <CountUp
                        className="account-balance"
                        start={0}
                        end={utilizadores}
                        duration={2}
                      />
                    </h3>
                  </div>
                </Card>
              </div>
              <CardContent className={classes.cardContent}>
                <h2 className="text-dark">Utilizadores</h2>
              </CardContent>
              <CardMedia className="sub-link">
                {/* <img
                  src={menina2}
                  alt=""
                  title="Alzira Xerinda"
                  style={{ width: "40%" }}
                /> */}
                <PeopleAltIcon
                  className="text-primary"
                  style={{ fontSize: 40 }}
                />
              </CardMedia>

              <div className="divider"></div>
              <div className="d-flex  justify-content-between">
                <NavLink
                  to={"/utilizadores/academia"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Super Admin <br /> ({utilizadoresSuperAdmin}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/utilizadores/academia"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Admin <br /> ({utilizadoresAdmin}){" "}
                </NavLink>
                |
                <NavLink
                  to={"/utilizadores/publico"}
                  className="link-dashboard"
                  style={{ fontSize: 10, color: "gray" }}
                >
                  Normal <br /> ({utilizadoresNormal}){" "}
                </NavLink>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </div>
  );
}
