import React from "react";
import logo from "../img/logo.png";
import jquery from "jquery";
import { NavLink, useNavigate } from "react-router-dom";
import Confirmacao from "./confirmarOperacao";
import { useState } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Icon } from "@iconify/react";
import { Avatar, Button, IconButton, Menu, MenuItem } from "@material-ui/core";

export default function Appbar(props) {
  (function ($) {
    "use strict";

    /*     // Initiate the wowjs
    new WOW().init(); */

    // Sticky Navbar
    $(window).bind("scroll", function () {
      if ($(this).scrollTop() > 45) {
        $(".navbar").addClass("sticky-top shadow-sm");
      } else {
        $(".navbar").removeClass("sticky-top shadow-sm");
      }
    });
  })(jquery);

  const currentURL = window.location.href;
  const isLogin = currentURL.split("/").pop();

  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));
  var navigate = useNavigate();

  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };
  const closeModal1 = () => {
    setShow(false);
    navigate("/home");
    localStorage.setItem("userLogado", JSON.stringify(""));
    localStorage.setItem("isAuthenticated", false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark px-4 ps-lg-5 pe-lg-3  py-3 py-lg-0">
        {/* <a href="#_" className="navbar-brand p-0"> */}
        <NavLink to={"/home"}>
          <h1 className="text-primary m-0">
            H
            <img
              src={logo}
              alt="Change Logo"
              width="50px"
              style={{ marginTop: -14 }}
            />
            PE
          </h1>
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0 pe-4">
            <NavLink to={"/home"} className="nav-item nav-link">
              Home
            </NavLink>

            <NavLink to={"/formulario"} className="nav-item nav-link">
              Denúncias
            </NavLink>
            <NavLink to={"/formSaude"} className="nav-item nav-link">
              Saúde
            </NavLink>
            <NavLink to={"/formEstudos"} className="nav-item nav-link">
              Estudos
            </NavLink>
            <NavLink to={"/formIdeias"} className="nav-item nav-link">
              Ideias
            </NavLink>
            {usuarioLogado !== "" ? (
              <NavLink to={"/meusPosts"} className="nav-item nav-link">
                Meus Posts
              </NavLink>
            ) : (
              " "
            )}

            {usuarioLogado !== "" ? (
              <>
                {usuarioLogado?.tipo !== "Normal" ? (
                  <NavLink to={"/dashboard"} className="nav-item nav-link">
                    Dashboard
                  </NavLink>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>

          {usuarioLogado !== "" ? (
            <>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{marginTop: -5}}
              >
                {usuarioLogado?.img_link === "Sem imagem" ? (
                  <Avatar
                    alt={usuarioLogado?.nome}
                    title={usuarioLogado?.nome}
                    className="avatar-style"
                  >
                    {usuarioLogado?.nome
                      .match(/\b(\w)/g)
                      .join("")
                      .substring(0, 2)}{" "}
                  </Avatar>
                ) : (
                  <Avatar
                    src={usuarioLogado?.img_link}
                    alt={usuarioLogado?.nome}
                    title={usuarioLogado?.nome}
                    className="avatar-style"
                  />
                )}
              </IconButton>{" "}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="mt-3"
              >
                <NavLink to={usuarioLogado?.tipo !== "Normal" ? ("/perfilAdmin"):("/perfilNormal") } style={{ color: "inherit" }}>
                  <MenuItem>Meu perfil</MenuItem>
                </NavLink>
                <MenuItem
                  onClick={() => {
                    setShow(true);
                    handleClose();
                  }}
                >
                  Terminar sessão
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              hidden={isLogin === "login"}
              variant="contained"
              type="submit"
              className="fw-bold bg-primary py-2 px-4 me-3 contact-us text-light"
              style={{ width: "100px" }}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          )}
        </div>
      </nav>

      <Confirmacao
        show={show}
        closeModal={closeModal}
        closeModal1={closeModal1}
        confirmar={"Confirmar"}
        iconn={
          <Icon
            icon="eva:done-all-outline"
            color="white"
            width="24"
            height="24"
            inline={true}
          />
        }
        icon={<ExitToAppIcon width="23" height="23" color="text-danger" />}
        titulo="Terminar sessão"
        descricao={"Tem certeza que pretende terminar sessão?"}
        color1="text-danger"
      />
    </div>
  );
}
