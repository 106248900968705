import React, { useEffect, useState } from "react";
import Appbar from "./appbar";
import jquery from "jquery";
import Footer from "./footer";
import { useParams } from "react-router-dom";
import { Fab } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import PanToolIcon from "@material-ui/icons/PanTool";
import logoprov from "../img/logoprov.png";

export default function Detalhes(props) {
  (function ($) {
    "use strict";

    // Spinner
    var spinner = function () {
      setTimeout(function () {
        if ($("#spinner").length > 0) {
          $("#spinner").removeClass("show");
        }
      }, 1);
    };
    spinner();
  })(jquery);

  const { id } = useParams();
  const [pedido, setPedido] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/show/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setPedido(data.data);
        setLoading(false);
      });
  }, [id]);

  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Appbar />
        {/* -----------------BANNER - CAPPA--------------- */}
        <div className="container-fluid py-5 bg-dark hero-header-detalhes mb-5">
          <div className="container text-center my-5 pt-2">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Detalhes - {pedido?.tipo_caso === "Projecto" && "Ideia"}{" "}
              {pedido?.tipo_caso === "Saude" && "Saúde"}{" "}
              {pedido?.tipo_caso === "Estudos" && "Estudos"}{" "}
              {pedido?.tipo_caso === "Denuncia" && "Denúncia"}
            </h1>
          </div>
        </div>
      </div>

      {/* ---------------- HORA DE AGIR -------------- */}
      <div className="container-xxl py-2 mt-5">
        <div className="container">
          <div className="row g-4" style={{ marginTop: -50 }}>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <a
                href={
                  pedido?.img_link === "Sem imagem" ||
                  pedido?.img_link === "capa.png"
                    ? logoprov
                    : pedido?.img_link
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    pedido?.img_link === "Sem imagem" ||
                    pedido?.img_link === "capa.png"
                      ? logoprov
                      : pedido?.img_link
                  }
                  alt={pedido?.nome}
                  title={pedido?.nome}
                  style={{ width: "100%", height: "auto" }}
                />
              </a>
            </div>
            <div className="col-md-6 text-start">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                {loading ? (
                  <div className="mt-5 pt-5  text-center d-flex w-100 align-items-center justify-content-center">
                    <div
                      className="spinner-border text-primary"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="row g-3">
                      <div className="col-12 text-center ">
                        <h3 className="">
                          {pedido?.nome}
                        </h3>
                      </div>

                      <div className="col-md-4">
                        <small className="fw-bold text-primary">Morada: </small>
                      </div>
                      <div className="col-md-8">
                        <LocationOnIcon
                          className="me-1"
                          style={{ fontSize: 17, marginTop: -2 }}
                        />

                        {pedido?.morada}
                      </div>

                      <div className="col-md-4">
                        <small className="fw-bold text-primary">
                          Contacto:{" "}
                        </small>
                      </div>
                      <div className="col-md-8">
                        <LocalPhoneIcon
                          className="me-1"
                          style={{ fontSize: 17, marginTop: -2 }}
                        />
                        <a
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                            opacity: 0.7,
                          }}
                          title="Entrar em contacto"
                          href={"tel:+258" + pedido?.contacto}
                        >
                          {pedido?.contacto}
                        </a>{" "}
                        /{" "}
                        <a
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                            opacity: 0.7,
                          }}
                          title="Entrar em contacto"
                          href={"tel:+258" + pedido?.contacto_op}
                        >
                          {pedido?.contacto_op}
                        </a>{" "}
                      </div>

                      <div className="col-md-4">
                        <small className="fw-bold text-primary">
                          Breve descrição:{" "}
                        </small>
                      </div>
                      <div className="col-md-8">
                        <AccessibilityNewIcon
                          className="me-1"
                          style={{ fontSize: 17, marginTop: -2 }}
                        />
                        {pedido?.d_curta}
                      </div>

                      <div className="col-md-12">
                        <small className="fw-bold text-primary">
                          História:{" "}
                        </small>
                      </div>
                      <div className="col-md-12">{pedido?.d_longa}</div>

                      <div className="col-12 text-center pt-3">
                        <a href={"tel:+258" + pedido?.contacto}>
                          <Fab
                            variant="extended"
                            className=" text-light fw-bold me-1 button-details mb-2"
                            //startIcon={<TelegramIcon />}
                            style={{
                              backgroundColor: "#0EC244",
                              width: 165,
                              height: 45,
                            }}
                          >
                            {/* <Icon className="me-2"  icon="la:hands-helping" color="white" width="24" height="24" inline={true} /> */}
                            <PanToolIcon
                              className="me-2"
                              style={{ fontSize: 19 }}
                            />
                            Ajudar
                          </Fab>
                        </a>

                        <a href="tel:+258827890800">
                          <Fab
                            variant="extended"
                            className=" text-light fw-bold ms-1 button-details mb-2"
                            style={{
                              backgroundColor: "#3f51b5",
                              width: 210,
                              height: 45,
                            }}
                          >
                            <AddIcCallIcon className="me-2" />
                            Academia
                          </Fab>
                        </a>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------- HORA DE AGIR -------------- */}

      {/* ------- ------- FOOTER ------- ------- */}
      <Footer />
      {/* ------- ------- FOOTER ------- ------- */}

      {/* <BackTop /> */}
    </div>
  );
}
