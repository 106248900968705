import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import denunciass from "../img/denuncias.png";
import saudee from "../img/saude.png";
import ideiass from "../img/ideias.png";
import estudoss from "../img/estudos.png";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HistoryIcon from "@material-ui/icons/History";
import {
  AppBar,
  Avatar,
  Badge,
  Collapse,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { Icon } from "@iconify/react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Confirmacao from "./confirmarOperacao";
import logo from "../img/logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 290,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  /*   avatar: {
    marginTop: theme.spacing(),
    backgroundColor: "",
  }, */
  nested: {
    paddingLeft: theme.spacing(9),
  },
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
}));

export default function Sidebar(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  const [denunciasPendentes, setDenunciasPendentes] = useState();

  useEffect(() => {
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/contarEstado/Pendente")
      .then((response) => response.json())
      .then((data) => {
        setDenunciasPendentes(data);
      });
  }, []);

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
  };

  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };
  const closeModal1 = () => {
    setShow(false);
    navigate("/home");
    localStorage.setItem("userLogado", JSON.stringify(""));
    localStorage.setItem("isAuthenticated", false);
  };

  return (
    <>
      <Drawer
        anchor={"left"}
        open={props.openSide}
        onClose={() => props.setOpenSide(false)}
        className="side-bar"
      >
        <div className={classes.list} role="presentation">
          <div className="text-center">
            <div className="d-flex justify-content-center pb-2 mt-4">
              <NavLink to="/perfilAdmin">
                {usuarioLogado?.img_link === "Sem imagem" ? (
                  <Avatar
                    alt={usuarioLogado?.nome}
                    title={usuarioLogado?.nome}
                    className="avatar-style1"
                  >
                    {usuarioLogado?.nome
                      .match(/\b(\w)/g)
                      .join("")
                      .substring(0, 2)}{" "}
                  </Avatar>
                ) : (
                  <Avatar
                    src={usuarioLogado?.img_link}
                    alt={usuarioLogado?.nome}
                    title={usuarioLogado?.nome}
                    className="avatar-style1"
                  />
                )}
              </NavLink>
            </div>

            <h6 style={{ color: "white" }}>{usuarioLogado.nome}</h6>
            <small>{usuarioLogado.tipo}</small>
          </div>

          <List className="mt-3">
            {/* ----------------DASHBOARD---------------- */}
            <NavLink
              className="sub-link"
              style={({ isActive }) => ({
                color: isActive ? "#ea8b0f" : "white",
              })}
              to={"/dashboard"}
            >
              <ListItem button className="list-item">
                <ListItemIcon>
                  <DashboardIcon style={{ color: "#ea8b0f" }} />
                </ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
              </ListItem>
            </NavLink>
            {/* ----------------DENUNCIAS---------------- */}
            <ListItem button className="list-item" onClick={handleClick}>
              <ListItemIcon>
                <img
                  src={denunciass}
                  alt="Denúncias"
                  width="25px"
                  style={{ color: "#ea8b0f" }}
                />
              </ListItemIcon>
              <ListItemText>Denúncias</ListItemText>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit disablePadding>
              <List component="div" disablePadding>
                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/denuncias/pendentes"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <HourglassEmptyIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Pendentes" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/denuncias/aprovadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Aprovadas" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/denuncias/fechadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <SpeakerNotesOffIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fechadas" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/denuncias/rejeitadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ThumbDownAltIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Rejeitadas" />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>

            {/* ----------------SAÚDE---------------- */}
            <ListItem button className="list-item" onClick={handleClick1}>
              <ListItemIcon>
                <img
                  src={saudee}
                  alt="Denúncias"
                  width="25px"
                  style={{ color: "#ea8b0f" }}
                />
              </ListItemIcon>
              <ListItemText>Saúde</ListItemText>
              {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open1} timeout="auto" unmountOnExit disablePadding>
              <List component="div" disablePadding>
                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/saude/pendentes"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <HourglassEmptyIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Pendentes" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/saude/aprovadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Aprovadas" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/saude/fechadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <SpeakerNotesOffIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fechadas" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/saude/rejeitadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ThumbDownAltIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Rejeitadas" />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>

            {/* ----------------ESTUDOS---------------- */}
            <ListItem button className="list-item" onClick={handleClick2}>
              <ListItemIcon>
                <img
                  src={estudoss}
                  alt="Denúncias"
                  width="25px"
                  style={{ color: "#ea8b0f" }}
                />
              </ListItemIcon>
              <ListItemText>Estudos</ListItemText>
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open2} timeout="auto" unmountOnExit disablePadding>
              <List component="div" disablePadding>
                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/estudos/pendentes"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <HourglassEmptyIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Pendentes" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/estudos/aprovadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Aprovadas" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/estudos/fechadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <SpeakerNotesOffIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fechadas" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/estudos/rejeitadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ThumbDownAltIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Rejeitadas" />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>

            {/* ----------------IDEIAS---------------- */}
            <ListItem button className="list-item" onClick={handleClick3}>
              <ListItemIcon>
                <img
                  src={ideiass}
                  alt="Denúncias"
                  width="25px"
                  style={{ color: "#ea8b0f" }}
                />
              </ListItemIcon>
              <ListItemText>Ideias</ListItemText>
              {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open3} timeout="auto" unmountOnExit disablePadding>
              <List component="div" disablePadding>
                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/ideias/pendentes"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <HourglassEmptyIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Pendentes" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/ideias/aprovadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Aprovadas" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/ideias/fechadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <SpeakerNotesOffIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fechadas" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/ideias/rejeitadas"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ThumbDownAltIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Rejeitadas" />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>

            {/* ----------------DOADORES---------------- */}
            <ListItem button className="list-item" onClick={handleClick4}>
              <ListItemIcon>
                <Icon
                  icon="fa-solid:hands-helping"
                  color="#ea8b0f"
                  width="25"
                  height="25"
                  inline={true}
                />
              </ListItemIcon>
              <ListItemText>Doadores</ListItemText>
              {open4 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open4} timeout="auto" unmountOnExit disablePadding>
              <List component="div" disablePadding>
                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/doadores/registo"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <PersonAddIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Registo" />
                  </ListItem>
                </NavLink>

                <NavLink
                  className="sub-link"
                  style={({ isActive }) => ({
                    color: isActive ? "#ea8b0f" : "white",
                  })}
                  to={"/doadores/lista"}
                >
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <FormatListBulletedIcon
                        className="sub-link"
                        style={{ color: "white", opacity: 0.7, fontSize: 19 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Lista" />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
            {/* ----------------TESTEMUNHOS---------------- */}
            <NavLink
              className="sub-link"
              style={({ isActive }) => ({
                color: isActive ? "#ea8b0f" : "white",
              })}
              to={"/testemunhos"}
            >
              <ListItem button className="list-item">
                <ListItemIcon>
                  <EmojiEmotionsIcon style={{ color: "#ea8b0f", height: 25 }} />
                </ListItemIcon>
                <ListItemText>Testemunhos</ListItemText>
              </ListItem>
            </NavLink>

            {/* ----------------UTILIZADORES---------------- */}

            {usuarioLogado.tipo === "Super Administrador" ? (
              <>
                {" "}
                <ListItem button className="list-item" onClick={handleClick5}>
                  <ListItemIcon>
                    <SupervisorAccountIcon
                      style={{ color: "#ea8b0f", height: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText>Utilizadores</ListItemText>
                  {open5 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={open5}
                  timeout="auto"
                  unmountOnExit
                  disablePadding
                >
                  <List component="div" disablePadding>
                    <NavLink
                      className="sub-link"
                      style={({ isActive }) => ({
                        color: isActive ? "#ea8b0f" : "white",
                      })}
                      to={"/utilizadores/academia"}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <PersonAddIcon
                            className="sub-link"
                            style={{
                              color: "white",
                              opacity: 0.7,
                              fontSize: 19,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Academia" />
                      </ListItem>
                    </NavLink>

                    <NavLink
                      className="sub-link"
                      style={({ isActive }) => ({
                        color: isActive ? "#ea8b0f" : "white",
                      })}
                      to={"/utilizadores/publico"}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <FormatListBulletedIcon
                            className="sub-link"
                            style={{
                              color: "white",
                              opacity: 0.7,
                              fontSize: 19,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Público" />
                      </ListItem>
                    </NavLink>

                    <NavLink
                      className="sub-link"
                      style={({ isActive }) => ({
                        color: isActive ? "#ea8b0f" : "white",
                      })}
                      to={"/utilizadores/historico"}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <HistoryIcon
                            className="sub-link"
                            style={{
                              color: "white",
                              opacity: 0.7,
                              fontSize: 22,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Histórico" />
                      </ListItem>
                    </NavLink>
                  </List>
                </Collapse>
              </>
            ) : (
              ""
            )}
            {/* ----------------SOCIAL MEDIA---------------- */}
            <NavLink
              className="sub-link"
              style={({ isActive }) => ({
                color: isActive ? "#ea8b0f" : "white",
              })}
              to={"/media/registo"}
            >
              <ListItem button className="list-item">
                <ListItemIcon>
                  <Icon
                    icon="mdi:web"
                    width="24"
                    height="24"
                    inline={true}
                    style={{ color: "#ea8b0f" }}
                  />
                </ListItemIcon>
                <ListItemText>Redes sociais</ListItemText>
              </ListItem>
            </NavLink>

            {/* ----------------Terminar sessão---------------- */}
            <ListItem
              button
              className="list-item"
              onClick={() => {
                props.setOpenSide(false);
                setShow(true);
              }}
            >
              <ListItemIcon>
                <Icon
                  icon="ri:logout-circle-line"
                  color="#ea8b0f"
                  width="25"
                  height="25"
                  inline={true}
                />
              </ListItemIcon>
              <ListItemText>Terminar sessão</ListItemText>
            </ListItem>
          </List>
          <Divider />
        </div>
      </Drawer>
      <div className={classes.grow}>
        <AppBar position="fixed" className="appbar-admin">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={() => props.setOpenSide(true)}
              style={{ color: "#c4710e" }}
            >
              <MenuIcon style={{ fontSize: 35 }} />
            </IconButton>

            <NavLink to={"/home"}>
              <h1 className="text-primary m-0">
                H
                <img
                  src={logo}
                  alt="Change Logo"
                  width="50px"
                  style={{ marginTop: -14 }}
                />
                PE
              </h1>
            </NavLink>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                color="inherit"
                edge="end"
                aria-haspopup="true"
                id="dropdownMenu3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="me-3"
                hidden={denunciasPendentes === 0}
              >
                <Badge
                  badgeContent={denunciasPendentes}
                  className="mt-2"
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <ul
                className="dropdown-menu detalhes-menu1 p-2 text-center"
                aria-labelledby="dropdownMenu3"
              >
                <span className="text-dark">
                  Tem {denunciasPendentes} caso(s) por aprovar
                </span>{" "}
                <br />
                <Link to={"/casosPendentes"}>
                  <small
                    className=""
                    style={{ color: "#3f51b5", textDecoration: "underline" }}
                  >
                    Ver mais
                  </small>
                </Link>
              </ul>
              <IconButton
                edge="end"
                aria-haspopup="true"
                color="inherit"
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {usuarioLogado?.img_link === "Sem imagem" ? (
                  <Avatar
                    alt={usuarioLogado?.nome}
                    title={usuarioLogado?.nome}
                    className="avatar-style1"
                  >
                    {usuarioLogado?.nome
                      .match(/\b(\w)/g)
                      .join("")
                      .substring(0, 2)}{" "}
                  </Avatar>
                ) : (
                  <Avatar
                    src={usuarioLogado?.img_link}
                    alt={usuarioLogado?.nome}
                    title={usuarioLogado?.nome}
                    className="avatar-style1"
                  />
                )}
              </IconButton>{" "}
              <ul
                className="dropdown-menu detalhes-menu"
                aria-labelledby="dropdownMenu2"
              >
                <NavLink to={"/perfilAdmin"}>
                  <li>
                    <button className="dropdown-item" type="button">
                      Meu perfil
                    </button>
                  </li>
                </NavLink>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => setShow(true)}
                  >
                    Terminar sessão
                  </button>
                </li>
              </ul>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <Confirmacao
        show={show}
        closeModal={closeModal}
        closeModal1={closeModal1}
        confirmar={"Confirmar"}
        iconn={
          <Icon
            icon="eva:done-all-outline"
            color="white"
            width="24"
            height="24"
            inline={true}
          />
        }
        icon={<ExitToAppIcon width="23" height="23" color="text-danger" />}
        titulo="Terminar sessão"
        descricao={"Tem certeza que pretende terminar sessão?"}
        color1="text-danger"
      />
    </>
  );
}
