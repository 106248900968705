import React, { useEffect } from "react";
import Detalhes from "../../components/detalhesMeus";



const DetalhesMeusPag = () => {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, []);
  return (
    <div >
      <Detalhes />
    </div>
  );
};
export default DetalhesMeusPag;

