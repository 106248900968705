import React, { useEffect } from "react";
import Formulario from "../../components/formEstudos";



const FormularioEstudos = () => {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, []);
  return (
    <div >
      <Formulario />
    </div>
  );
};
export default FormularioEstudos;

