import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

const DetalhesDoador = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        style={{ marginTop: 5 }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-primary">
          <Modal.Title>
            {" "}
            <PersonIcon className="text-light" />{" "}
            <span className="text-light" style={{ fontSize: 21 }}>
              Detalhes do doador
            </span>
          </Modal.Title>
          {/*           <CloseButton variant="white" /> */}
          <Button
            className="me-1 fw-bold ps-3 pe-3 accordion-buttons text-secondary"
            onClick={props.closeModal}
          >
            <CloseIcon />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-3 div-detalhes">
            <div className="col-md-4">
              <small className="fw-bold text-secondary">Nome completo: </small>
            </div>
            <div className="col-md-8">{props?.detalhe?.nome}</div>

            <div className="col-md-4">
              <small className="fw-bold text-secondary">Contacto: </small>
            </div>
            <div className="col-md-8">
              <a
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  opacity: 0.7,
                }}
                title="Entrar em contacto"
                href={"tel:+258" + props?.detalhe?.contacto}
              >
                (+258) {props?.detalhe?.contacto}
              </a>{" "}
            </div>

            <div className="col-md-4">
              <small className="fw-bold text-secondary">Email: </small>
            </div>
            <div className="col-md-8">
              <a
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  opacity: 0.7,
                }}
                title="Enviar email"
                href={"mailto:" + props?.detalhe?.email}
              >
                {props?.detalhe?.email}
              </a>{" "}
            </div>

            <div className="col-md-4">
              <small className="fw-bold text-secondary">Morada: </small>
            </div>
            <div className="col-md-8">{props?.detalhe?.morada}</div>

            <div className="col-md-4">
              <small className="fw-bold text-secondary">
                País de residência:{" "}
              </small>
            </div>
            <div className="col-md-8">{props?.detalhe?.pais}</div>

            <div className="col-md-4">
              <small className="fw-bold text-secondary">Empresa: </small>
            </div>
            <div className="col-md-8">{props?.detalhe?.empresa}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Link
              to={"/doadores/editar/" + props?.detalhe?.id}
              state={{ state: props?.detalhe }}
            >
              <Button
                className="me-1 fw-bold ps-3 pe-3 accordion-buttons"
                color="primary"
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetalhesDoador;

/*             <Button
              style={{ color: "white", textTransform: "capitalize" }}
              onClick={props.closeModal}
              className="text-primary fw-bold close-modal"
              variant="outlined"
            >
              Fechar
            </Button>
 */
