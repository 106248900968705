import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./footer";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Button,
  Collapse,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import { Link, NavLink } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FilterListIcon from "@material-ui/icons/FilterList";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import DetalhesDoador from "./detalhesDoador";
import Sidebar from "./appbarAdmin";
import Confirmacao from "./confirmarOperacao";
import { Spinner } from "react-bootstrap";
import SnackBar from "./snackbar";
import nothing22 from "../img/box32.png";
import searchGif from "../img/searchGif.gif";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(11),
    backgroundColor: "",
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
}));

export default function Doadores(props) {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));
  const classes = useStyles();

  const [openSide, setOpenSide] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [doadores, setDoadores] = useState();
  const [detalhe, setDetalhe] = useState();
  const [existentes, setExistentes] = useState(true);
  const [existentes1, setExistentes1] = useState(false);

  /* ------DADOS PARA SALVAR OPERAÇAO */
  const [descEvent, setDescEvent] = useState();

  const fetchData = () => {
    setLoading(true);
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/doadores")
      .then((response) => response.json())
      .then((data) => {
        setDoadores(data.data);
        setLoading(false);
        if (data.data.length !== 0) {
          setExistentes(true);
        } else {
          setExistentes(false);
        }
      });
  };

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetchData();
  }, []);

  const verDetalhes = (id) => {
    setShowDetails(true);
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/doadores/show/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setDetalhe(data.data);
        setLoading(false);
      });
  };

  const [deleting, setDeleting] = useState(false);

  const apagar = () => {
    setDeleting(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(
      `https://hope.academiavirtual.ac.mz/api_hope.php/api/doadores/delete/${doadorID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/doadores")
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Eliminação",
              `Eliminou o doador  "${nomeModal}", da empresa ${descEvent}.`
            );
            setDeleting(false);
            setOpen(true);
            setShowDelete(false);
            setDoadores(data.data);
          });
      });
  };

  const [showDetails, setShowDetails] = useState(false);
  const closeDetails = () => {
    setShowDetails(false);
  };

  /* ----------SNACKBAR------------- */
  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  var show1 = JSON.parse(localStorage.getItem("showSnackbar"));

  /* ----------SNACKBAR------------- */
  const [open1, setOpen1] = useState(show1);

  const closeSnackbar1 = () => {
    setOpen1(false);
    localStorage.setItem("showSnackbar", JSON.stringify(false));
  };

  /* ----------Modal para Confirmar a eliminação de doador*------------- */
  const [showDelete, setShowDelete] = useState(false);
  const [nomeModal, setNomeModal] = useState();
  const [doadorID, setDoadorID] = useState();

  const openModalDelete = (id, nome, emp) => {
    setNomeModal(nome);
    setDoadorID(id);
    setDescEvent(emp);
    setShowDelete(true);
  };
  const closeModalDelete = () => {
    setShowDelete(false);
  };
  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [searchNome, setSearchNome] = useState();
  const [searchEmpresa, setSearchEmpresa] = useState();

  const [searching, setSearching] = useState(false);
  const [searching1, setSearching1] = useState(false);

  const searchByNome = (nome) => {
    setSearching(true);

    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/doadores/searchByName/${nome}`)
      .then((response) => response.json())
      .then((data) => {
        setSearching(false);
        setDoadores(data);
        if (data.length === 0) {
          setExistentes1(true);
        } else {
          setExistentes1(false);
        }
      });
  };

  const searchByEmpresa = (empresa) => {
    setSearching1(true);

    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/doadores/searchByEmpresa/${empresa}`)
      .then((response) => response.json())
      .then((data) => {
        setSearching1(false);
        setDoadores(data);
        if (data.length === 0) {
          setExistentes1(true);
        } else {
          setExistentes1(false);
        }
      });
  };

  const saveEvent = (user, event, description) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: event,
        descricao: description,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        <div className="container-xxl py-2 mt-2">
          <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h2 className="section-title ff-secondary text-center text-primary fw-normal ">
                Doadores
              </h2>
              <h3 className="">Listagem de doadores</h3>
            </div>
            <div className="w-100 text-end mb-2">
              <Button
                hidden={!existentes}
                name="Basic button"
                value="default"
                size="medium"
                onClick={() => setFilter(!filter)}
                aria-expanded={filter}
                aria-controls="collapse-filter"
                title="Filtrar"
              >
                <FilterListIcon style={{ fontSize: 32, color: "#ea8b0f" }} />
                {filter ? (
                  <ArrowDropUpIcon style={{ color: "#ea8b0f" }} />
                ) : (
                  <ArrowDropDownIcon style={{ color: "#ea8b0f" }} />
                )}
              </Button>
              <NavLink className="" to={"/doadores/registo"}>
                <Button
                  variant="contained"
                  className="me-1 bg-primary text-light fw-bold"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "capitalize" }}
                >
                  Adicionar
                </Button>
              </NavLink>
            </div>
            <Collapse in={filter} timeout="auto" unmountOnExit disablePadding>
              <div className="row g-4 mb-2 d-flex justify-content-end me-2">
                <div
                  className="position-relative col-md-6"
                  style={{ maxWidth: "320px", marginTop: 20 }}
                >
                  <input
                    className="form-control border-primary py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Pesquisar por nome"
                    style={{ height: 45 }}
                    value={searchNome}
                    onChange={(e) => setSearchNome(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search "
                    style={{ width: 55, height: 45 }}
                    onClick={() =>
                      searchNome ? searchByNome(searchNome) : fetchData()
                    }
                    disabled={searching}
                  >
                    {searching ? (
                      <Spinner
                        animation="border"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : (
                      <SearchIcon
                        className="text-light btn-search"
                        style={{ marginTop: -5 }}
                      />
                    )}
                  </button>
                </div>
                <div
                  className="position-relative col-md-6"
                  style={{ maxWidth: "320px", marginTop: 20 }}
                >
                  <input
                    className="form-control border-primary py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Pesquisar por empresa"
                    style={{ height: 45 }}
                    value={searchEmpresa}
                    onChange={(e) => setSearchEmpresa(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search "
                    style={{ width: 55, height: 45 }}
                    onClick={() =>
                      searchEmpresa
                        ? searchByEmpresa(searchEmpresa)
                        : fetchData()
                    }
                    disabled={searching1}
                  >
                    {searching1 ? (
                      <Spinner
                        animation="border"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : (
                      <SearchIcon
                        className="text-light btn-search"
                        style={{ marginTop: -5 }}
                      />
                    )}
                  </button>
                </div>
              </div>
            </Collapse>

            <TableContainer component={Paper}>
              {loading ? (
                <div className="  text-center d-flex w-100 align-items-center justify-content-center">
                  <div
                    className="spinner-border text-primary"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </div>
              ) : (
                <>
                  {existentes ? (
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell className="fw-bold">ID</TableCell>
                          <TableCell className="fw-bold" /* align="right" */>
                            Nome completo
                          </TableCell>
                          <TableCell className="fw-bold" /* align="right" */>
                            Email
                          </TableCell>
                          <TableCell className="fw-bold" /* align="right" */>
                            País de residência
                          </TableCell>
                          <TableCell className="fw-bold" /* align="right" */>
                            Empresa
                          </TableCell>
                          <TableCell className="fw-bold" align="right">
                            Acções
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {doadores?.map((d) => (
                          <TableRow key={d.id}>
                            <TableCell component="th" scope="row">
                              {d.id}
                            </TableCell>
                            <TableCell /* align="right" */>{d.nome}</TableCell>
                            <TableCell /* align="right" */>{d.email}</TableCell>
                            <TableCell /* align="right" */>{d.pais}</TableCell>
                            <TableCell /* align="right" */>
                              {d.empresa}
                            </TableCell>
                            <TableCell align="right" className="ms-4">
                              <div className="text-end dropdownrounded">
                                <Button
                                  id="dropdownMenu2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <MoreVertIcon style={{ color: "#ea8b0f" }} />
                                </Button>
                                <ul
                                  className="dropdown-menu detalhes-menu"
                                  aria-labelledby="dropdownMenu2"
                                >
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() => verDetalhes(d.id)}
                                    >
                                      Detalhes
                                    </button>
                                  </li>

                                  <Link
                                    to={"/doadores/editar/" + d.id}
                                    state={{ state: d }}
                                  >
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        //onClick={() => verDetalhes(d.id)}
                                      >
                                        Editar
                                      </button>
                                    </li>
                                  </Link>

                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() =>
                                        openModalDelete(d.id, d.nome, d.empresa)
                                      }
                                    >
                                      Eliminar
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <div className="p-5 text-center d-inline-block w-100 align-items-center justify-content-center">
                      {/* <span>{props.nada}</span> */}
                      <div>
                        <img
                          src={nothing22}
                          alt=""
                          title="Nada para mostrar"
                          style={{ width: "170px" }}
                        />
                      </div>
                      <span className="text-secondary">
                        Nenhum doador registado!
                      </span>{" "}
                    </div>
                  )}
                  {existentes1 && (
                    <div className="mt-3 text-center d-inline-block w-100 align-items-center justify-content-center">
                      {/* <span>{props.nada}</span> */}
                      <div>
                        <img
                          src={searchGif}
                          alt=""
                          title="Nada para mostrar"
                          style={{ width: "170px" }}
                        />
                      </div>
                      <span className="text-secondary">
                        Nenhum resultado encontrado!
                      </span>{" "}
                    </div>
                  )}
                </>
              )}
            </TableContainer>
          </div>
        </div>
      </Container>

      <DetalhesDoador
        show={showDetails}
        closeModal={closeDetails}
        detalhe={detalhe}
      />

      <Confirmacao
        show={showDelete}
        closeModal={closeModalDelete}
        closeModal1={() => apagar()}
        confirmar={"Confirmar"}
        iconn={
          deleting ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <DeleteOutlineIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={
          <>
            Tem certeza que pretende eliminar o doador
            <span className="fw-bold"> {nomeModal}</span> ?
          </>
        }
        color1="text-danger"
      />
      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={"Doador eliminado com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />

      <SnackBar
        open={open1}
        handleClose={closeSnackbar1}
        message={"Dados do doador actualizados com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />
      <Footer />
    </div>
  );
}
