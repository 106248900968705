import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./footer";
import academia from "../img/academia.png";
import logo from "../img/logo.png";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import SnackBar from "./snackbar";
import { CloseButton, Modal, Spinner } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import Appbar from "./appbar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});
const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(11),
    backgroundColor: "red",
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },

  floatingLabelFocusStyle: {
    "& .MuiFormLabel-root": {
      color: "#ea8b0f",
    },
  },

  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
}));

export default function Perfil(props) {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  const classes = useStyles();

  const [textoSobre, setTextoSobre] = useState();
  const [missao, setMissao] = useState();
  const [visao, setVisao] = useState();
  const [valores, setValores] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/11`)
      .then((response) => response.json())
      .then((data) => {
        setTextoSobre(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/12`)
      .then((response) => response.json())
      .then((data) => {
        setMissao(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/13`)
      .then((response) => response.json())
      .then((data) => {
        setVisao(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/14`)
      .then((response) => response.json())
      .then((data) => {
        setValores(data.data);
      });
  }, []);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [loader2, setLoader2] = useState(false);
  const [mediaID, setMediaID] = useState();
  const [nome, setNome] = useState();
  const [mediaLink, setMediaLink] = useState();

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorLink, setErrorLink] = useState(false);
  const [errorLinkMessage, setErrorLinkMessage] = useState("");

  const requiredFields = () => {
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }

    if (mediaLink === "" || mediaLink === undefined) {
      setErrorLink(true);
      setErrorLinkMessage("Por favor, preencha este campo!");
    } else {
      setErrorLink(false);
      setErrorLinkMessage(true);
    }
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorNome === false && errorLink === false) {
      editar();
    } else {
      console.log("N");
    }
  };

  const dados = {
    nome: nome,
    mediaLink: mediaLink,
  };

  const editar = () => {
    //console.log(dados);
    setLoader2(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: mediaID,
        nome: dados.nome,
        descricao: dados.mediaLink,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/links/edit", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/${mediaID}`)
          .then((response) => response.json())
          .then((data) => {
            setLoader2(false);
            setShowEdit(false);
            setOpen(true);
            if (mediaID === 11) {
              setTextoSobre(data.data);
            }
            if (mediaID === 12) {
              setMissao(data.data);
            }
            if (mediaID === 13) {
              setVisao(data.data);
            }
            if (mediaID === 14) {
              setValores(data.data);
            }
          });
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [iconModal, setIconModal] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  const openModalEdit = (id, nome, mediaLink) => {
    setMediaID(id);
    setNome(nome);
    setMediaLink(mediaLink);
    setShowEdit(true);
  };

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Appbar />
        {/* -----------------BANNER - CAPPA--------------- */}
        <div className="container-fluid py-5 bg-dark hero-header-sobre mb-0">
          <div className="container text-center pt-4 mt-5">
            <h1 className="display-3 text-white mb-0 animated slideInDown">
              Quem somos?
            </h1>

            <div className="container text-center justify-content-center d-flex ">
              <span className="text-start text-primary mb-1">
                <img
                  src={academia}
                  alt="Academia de Pesquisa Virtual"
                  title="Academia de Pesquisa Virtual"
                  className="academia-logo"
                  //style={{ width: "50px", height: "50px" }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------------CONTAINER--------------------------------------- */}
      <div className="container-xxl mt-5">
        <div className="container parent">
          <div className="text-start wow fadeInUp" data-wow-delay="0.1s">
            <h2 className="section-title ff-secondary text-start text-primary fw-normal">
              Sobre nós
            </h2>
            <div
              className="text-end pe-1 pb-1 child position-absolute"
              style={{ top: 0, right: 0 }}
              hidden={usuarioLogado === "" || usuarioLogado?.tipo === "Normal"}
              onClick={() => {
                openModalEdit(
                  textoSobre?.id,
                  textoSobre?.nome,
                  textoSobre?.descricao
                );
                setIconModal(
                  <span>
                    <img
                      src={logo}
                      alt="Academia de Pesquisa Virtual"
                      title="Academia de Pesquisa Virtual"
                      className="academia-logo"
                      style={{ width: "30px" }}
                    />
                  </span>
                );
              }}
            >
              <IconButton color="primary" component="span">
                <EditIcon />
              </IconButton>
            </div>
          </div>

          <div className="text-start mt-4">
            <p>
              {textoSobre?.descricao} <br /> <br /> Respondemos a crescente
              procura por conhecimentos na área de pesquisa científica, análise
              de dados, ensino à distância, design e grafismo e Tecnologias de
              Informação e Comunicação (TIC).
            </p>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row g-4 text-center">
            <div className="col-md-4 parent position-relative">
              <Card className={classes.card}>
                <CardMedia
                  className="sub-link centerr pt-3"
                  //style={{ height: "170px" }}
                >
                  <Icon
                    icon="fluent:target-arrow-20-filled"
                    color="#ea8b0f"
                    width="72"
                    height="72"
                    inline={true}
                    className="images1"
                  />
                </CardMedia>

                <CardContent className={classes.cardContent}>
                  <h5 className="fw-bold">MISSÃO</h5>
                  <div className="ps-2 pe-2 pt-2">
                    <p className="text-secondary">{missao?.descricao}</p>
                  </div>
                </CardContent>
                <div
                  className="text-end pe-1 pb-1 child position-absolute"
                  style={{ bottom: 2, right: 12 }}
                  hidden={
                    usuarioLogado === "" || usuarioLogado?.tipo === "Normal"
                  }
                  onClick={() => {
                    openModalEdit(missao?.id, missao?.nome, missao?.descricao);
                    setIconModal(
                      <span>
                        <Icon
                          icon="fluent:target-arrow-20-filled"
                          width="24"
                          height="24"
                          inline={true}
                        />
                      </span>
                    );
                  }}
                >
                  <IconButton color="primary" component="span">
                    <EditIcon />
                  </IconButton>
                </div>
              </Card>
            </div>

            <div className="col-md-4 parent position-relative">
              <Card className={classes.card}>
                <CardMedia
                  className="sub-link centerr pt-3"
                  //style={{ height: "170px" }}
                >
                  <Icon
                    icon="fa-regular:eye"
                    color="#ea8b0f"
                    width="72"
                    height="72"
                    inline={true}
                    className="images1"
                  />
                </CardMedia>

                <CardContent className={classes.cardContent}>
                  <h5 className="fw-bold">VISÃO</h5>
                  <div className="ps-2 pe-2 pt-2">
                    <p className="text-secondary">{visao?.descricao}</p>
                  </div>
                </CardContent>

                <div
                  className="text-end pe-1 pb-1 child position-absolute"
                  style={{ bottom: 2, right: 12 }}
                  hidden={
                    usuarioLogado === "" || usuarioLogado?.tipo === "Normal"
                  }
                  onClick={() => {
                    openModalEdit(visao?.id, visao?.nome, visao?.descricao);
                    setIconModal(
                      <span>
                        <Icon
                          icon="fa-regular:eye"
                          width="24"
                          height="24"
                          inline={true}
                        />
                      </span>
                    );
                  }}
                >
                  <IconButton color="primary" component="span">
                    <EditIcon />
                  </IconButton>
                </div>
              </Card>
            </div>

            <div className="col-md-4 parent position-relative">
              <Card className={classes.card}>
                <CardMedia
                  className="sub-link centerr pt-3"
                  //style={{ height: "170px" }}
                >
                  <Icon
                    icon="ant-design:like-filled"
                    color="#ea8b0f"
                    width="72"
                    height="72"
                    inline={true}
                    className="images1"
                  />
                </CardMedia>

                <CardContent className={classes.cardContent}>
                  <h5 className="fw-bold">VALORES</h5>
                  <div className="ps-2 pe-2 pt-2">
                    <p className="text-secondary">{valores?.descricao}</p>
                  </div>
                </CardContent>

                <div
                  className="text-end pe-1 pb-1 child position-absolute"
                  style={{ bottom: 2, right: 12 }}
                  hidden={
                    usuarioLogado === "" || usuarioLogado?.tipo === "Normal"
                  }
                  onClick={() => {
                    openModalEdit(
                      valores?.id,
                      valores?.nome,
                      valores?.descricao
                    );
                    setIconModal(
                      <span>
                        <Icon
                          icon="ant-design:like-filled"
                          width="24"
                          height="24"
                          inline={true}
                        />
                      </span>
                    );
                  }}
                >
                  <IconButton color="primary" component="span">
                    <EditIcon />
                  </IconButton>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------------CONTAINER--------------------------------------- */}

      <Modal
        show={showEdit}
        style={{ marginTop: 0 }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-dark">
          <Modal.Title>
            {" "}
            <span className="text-light" style={{ fontSize: 21 }}>
              <EditIcon /> {"   "}
              Editar link de {nome}
            </span>{" "}
          </Modal.Title>
          <CloseButton variant="white" onClick={() => setShowEdit(false)} />
        </Modal.Header>
        <Modal.Body>
          <div className="row g-4  div-detalhes mt-1">
            <form onSubmit={formSubmit}>
              <div className="row g-1">
                <div className="col-md-12 text-start">
                  <ThemeProvider theme={theme}>
                    <TextField
                      disabled
                      variant="outlined"
                      size={"small"}
                      fullWidth
                      name="nome"
                      label="Nome da rede"
                      className="mb-4"
                      type={"text"}
                      id="nome"
                      value={nome}
                      defaultValue={nome}
                      error={errorNome}
                      helperText={errorNomeMessage}
                      onChange={(e) => setNome(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {iconModal}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ThemeProvider>
                </div>
                <div className="col-md-12 text-start">
                  <ThemeProvider theme={theme}>
                    <TextField
                      variant="outlined"
                      size={"small"}
                      fullWidth
                      name="mediaLink"
                      label="Descrição"
                      className="mb-4"
                      type={"text"}
                      multiline
                      rows={4}
                      value={mediaLink}
                      defaultValue={mediaLink}
                      error={errorLink}
                      helperText={errorLinkMessage}
                      onChange={(e) => setMediaLink(e.target.value)}
                      InputProps={{}}
                    />
                  </ThemeProvider>
                </div>
                <div className="col-md-12 text-end">
                  <div className="">
                    <Button
                      style={{ color: "white", textTransform: "capitalize" }}
                      onClick={() => setShowEdit(false)}
                      className="bg-danger me-3 fw-bold"
                    >
                      <Icon
                        icon="iconoir:cancel"
                        color="white"
                        width="22"
                        height="22"
                        inline={true}
                      />
                      Cancelar
                    </Button>
                    <Button
                      style={{ color: "white", textTransform: "capitalize" }}
                      className="text-light bg-dark fw-bold"
                      type="submit"
                      onClick={() => requiredFields()}
                      disabled={loader2}
                    >
                      {loader2 ? (
                        <Spinner
                          animation="border"
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <Icon
                          className="me-2 text-light"
                          icon="fluent:save-arrow-right-20-regular"
                          width="24"
                          height="24"
                          inline={true}
                        />
                      )}
                      Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={"Link actualizado com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />

      <Footer />
    </div>
  );
}
