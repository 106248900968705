import React, { useEffect } from "react";
import Doadores from "../../../components/listaDoadores";



const ListaDoadores = () => {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, []);
  return (
    <div >
      <Doadores />
    </div>
  );
};
export default ListaDoadores;

