import React, { useRef } from "react";
import Appbar from "./appbar";
import Footer from "./footer";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../img/logo.png";
import EmailIcon from "@material-ui/icons/Email";
import { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import SnackBar from "./snackbar";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { IconButton, InputAdornment, Paper } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Modal, Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";
import emailjs from "@emailjs/browser";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});

const Login = () => {
  const [usuario, setUsuario] = useState();
  const [senha, setSenha] = useState();

  const dados = {
    usuario: usuario,
    senha: senha,
  };
  const [loader, setLoader] = useState(false);
  const [usuarioLogado, setUsuarioLogado] = useState("");
  const [open, setOpen] = useState();
  const [mensagem, setMensagem] = useState();
  const [snackColor, setSnackColor] = useState();
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const loggar = () => {
    setLoader(true);
    //console.log(dados);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: dados.usuario,
        senha: dados.senha,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/loginNormal", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);

        if (data?.message === 100) {
          setOpen(true);
          setSnackColor("error");
          setMensagem("Dados incorrectos, por favor tente novamente!");
          //localStorage.setItem("userLogado", JSON.stringify(""));
        }
        if (data?.data?.length !== 0) {
          if (data?.data?.[0]?.estado === "Bloqueado") {
            setUsuarioLogado(data?.data);
            setOpen(true);
            setSnackColor("error");
            setMensagem(
              "A sua conta foi bloqueada, por favor contacte a administração!"
            );
          } else {
            localStorage.setItem("userLogado", JSON.stringify(data?.data?.[0]));
            localStorage.setItem("isAuthenticated", true);
            saveEvent(data?.data?.[0]?.usuario);
            if (data?.data?.[0]?.tipo === "Normal") {
              navigate("/home", { state: usuarioLogado });
            }
            if (
              data?.data?.[0]?.tipo === "Administrador" ||
              data?.data?.[0]?.tipo === "Super Administrador"
            ) {
              navigate("/dashboard", { state: usuarioLogado });
            }
          }
        }

        //console.log(data);
        /* console.log(data?.data?.[0].nivel_acesso); */
      });
    //console.log(usuarioLogado);

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const saveEvent = (user) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: "Login",
        descricao: "Iníciou sessão no sistema",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const formSubmit = (event) => {
    event.preventDefault();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const form = useRef();

  /* ----RECUPERRAR SENHA-------------- */

  const [email, setEmail] = useState();
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const requiredFields = () => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }
  };

  const formSubmit2 = (event) => {
    event.preventDefault();

    if (errorEmail === false) {
      searchUser();
    } else {
      console.log("N");
    }
  };

  const [searching, setSearching] = useState(false);

  const searchUser = () => {
    setSearching(true);
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/searchByEmail/${email}`)
      .then((response) => response.json())
      .then((data) => {
        //setUtilizador(data);
        if (data?.length === 0) {
          setOpen(true);
          setSnackColor("error");
          setMensagem("Email inexistente");
          setSearching(false);
        } else {
          sendmail(
            data?.[0]?.nome,
            data?.[0]?.usuario,
            data?.[0]?.senha,
            data?.[0]?.email
          );
        }
      });
  };

  (function () {
    emailjs.init("f1ibuUWzvcn7ZVdey");
  })();

  function sendmail(nome, user, senha1, email1) {
    var contactParams = {
      to_name: nome,
      senha: senha1,
      username: user,
      to_email: email1,
    };

    emailjs
      .send("service_xwg0ysa", "template_9dz8ypj", contactParams)
      .then(function (res) {
        setSearching(false);
        setShow(false);
        setOpen(true);
        setSnackColor("info");
        setMensagem("Senha recuperada, por favor consulte seu email!");
      });
  }

  return (
    <div>
      <Appbar />
      <div className="container-fluid py-5 bg-dark hero-header-login">
        <div className="container text-center my-5 pt-5 pb-4">
          <Paper
            className="container paper  justify-content-center pe-5 mt-5"
            elevation={5}
            style={{ paddingLeft: 11 }}
          >
            <div className="row g-4">
              <div
                className="col-md-6 wow fadeIn bg-dark mt-0 pt-5 pb-0 mb-0"
                data-wow-delay="0.1s"
                //style={{ height: '1rem' }}
              >
                <img
                  src={logo}
                  alt="Holding hands"
                  style={{ width: "55%", marginLeft: -25 }}
                />
              </div>
              <div className="col-md-6 pb-5 ps-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                  <h5 className="section-title ff-secondary text-center text-primary fw-normal ">
                    Hope for the world
                  </h5>

                  <h3 className="mb-5 mt-3">Bem Vindo - Iniciar sessão</h3>
                </div>
                <div className="wow fadeInUp mt-4" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-3">
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="email"
                            label="Email/Username"
                            type={"text"}
                            id="usuario"
                            value={usuario}
                            defaultValue={usuario}
                            onChange={(e) => setUsuario(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="senha"
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            id="senha"
                            value={senha}
                            defaultValue={senha}
                            onChange={(e) => setSenha(e.target.value)}
                            autoComplete="current-password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-md-6 text-start">
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "grey",
                            cursor: "pointer",
                          }}
                          className="link-login"
                          onClick={() => setShow(true)}
                        >
                          <small for="senha">Recuperar senha</small>
                        </span>
                      </div>

                      <div className="col-md-6 text-end">
                        <NavLink
                          to={"/registar"}
                          style={{ textDecoration: "underline", color: "grey" }}
                          className="link-login"
                        >
                          <small for="senha">Não tem conta? Registe-se!</small>
                        </NavLink>
                      </div>

                      <div className="col-12">
                        <Button
                          variant="contained"
                          type="submit"
                          className=" w-100 bg-primary text-light fw-bold"
                          //startIcon={<SaveIcon />}
                          onClick={() => loggar()}
                          disabled={loader}
                        >
                          {loader ? "A verificar" : "Entrar"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Paper>

          <div className="container paper1" style={{ marginTop: 35 }}>
            <div className="row g-4" style={{ marginTop: -80 }}>
              <div
                className="col-md-6 wow fadeIn bg-dark p-3"
                data-wow-delay="0.1s"
              >
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                  <h5 className="section-title ff-secondary text-center text-primary fw-normal ">
                    Hope for the world
                  </h5>
                  <h3 className="mt-3 mb-2 text-light">Bem Vindo</h3>
                </div>
                <img
                  src={logo}
                  alt="Holding hands"
                  style={{ width: "50%", marginLeft: -25 }}
                />
              </div>
              <div className="col-md-6 bg-light mt-0 pb-4 pt-3">
                <div className="wow fadeInUp" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-3">
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="usuario"
                            label="Email/Username"
                            type={"text"}
                            id="usuario"
                            value={usuario}
                            defaultValue={usuario}
                            onChange={(e) => setUsuario(e.target.value)}
                            autoComplete="current-password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="senha"
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            id="senha"
                            value={senha}
                            defaultValue={senha}
                            onChange={(e) => setSenha(e.target.value)}
                            autoComplete="current-password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-12 d-flex text-start">
                        <div className="text-start w-50">
                          <span
                            style={{
                              textDecoration: "underline",
                              color: "grey",
                              cursor: "pointer",
                            }}
                            className="link-login"
                            onClick={() => setShow(true)}
                          >
                            <small for="senha">Recuperar senha</small>
                          </span>
                        </div>

                        <div className="text-end w-50">
                          <NavLink
                            to={"/registar"}
                            style={{
                              textDecoration: "underline",
                              color: "grey",
                            }}
                            className="link-login"
                          >
                            <small for="senha">
                              Não tem conta? Registe-se!
                            </small>
                          </NavLink>
                        </div>
                      </div>

                      <div className="col-12">
                        <Button
                          variant="contained"
                          className=" w-100 bg-primary text-light fw-bold"
                          //startIcon={<SaveIcon />}
                          onClick={() => loggar()}
                          disabled={loader}
                        >
                          {loader ? "A verificar" : "Entrar"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        style={{ marginTop: 5 }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className="">
            {" "}
            <AssignmentIcon className="text-light" />{" "}
            <span className="text-light" style={{ fontSize: 21 }}>
              Recuperar senha
            </span>
          </Modal.Title>
          <Button
            className="me-1 fw-bold ps-3 pe-3 accordion-buttons text-secondary"
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-4  div-detalhes mt-1">
            <div className="col-md-12">
              <div className="wow fadeInUp mt-2" data-wow-delay="0.2s">
                <form /* ref={form} */ onSubmit={formSubmit2}>
                  <div className="row g-1">
                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="email"
                          label="Email do utilizador"
                          type={"text"}
                          id="email"
                          value={email}
                          defaultValue={email}
                          error={errorEmail}
                          helperText={errorEmailMessage}
                          onChange={(e) => setEmail(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon style={{ color: "#EA8B0F" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                      <Button
                        variant="contained"
                        type="submit"
                        className="ms-1 bg-primary text-light fw-bold"
                        onClick={() => requiredFields()}
                        //style={{ width: "49%" }}
                        disabled={searching}
                      >
                        {searching ? (
                          <Spinner
                            animation="border"
                            style={{ width: 24, height: 24 }}
                          />
                        ) : (
                          <Icon
                            className="me-2"
                            icon="fluent:save-arrow-right-20-regular"
                            color="white"
                            width="24"
                            height="24"
                            inline={true}
                          />
                        )}
                        Enviar
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={mensagem}
        key={"bottom" + "center"}
        autoHideDuration={4000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={snackColor}
      />

      <Footer />
    </div>
  );
};

export default Login;
