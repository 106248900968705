import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import urgent from "../img/urgent2.png";
import dateFormat from "dateformat";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  makeStyles,
} from "@material-ui/core";
import logoprov from "../img/logoprov.png";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    /*     backgroundColor: "#0f172b", */
  },

  cardContent: {
    flexGrow: 1,
  },
}));

export default function Slidee(props) {
  const classes = useStyles();

  const [afixados, setAfixados] = useState();
  const [afixadosD, setAfixadosD] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByDenunciaAfixado")
      .then((response) => response.json())
      .then((data) => {
        setAfixadosD(data);
        //setLoading(false);
        //console.log(data);
      });
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByExcludeDenuncia")
      .then((response) => response.json())
      .then((data) => {
        setAfixados(data);
        //setLoading(false);
        //console.log(data);
      });
  }, []);

  return (
    <div>
      {/* ---------------- DESTAQUEEEEEEES-------------- */}
      <div
        className="container-xxl py-5"
        hidden={
          (!afixados && !afixadosD) ||
          (afixados?.length === 0 && afixadosD?.length === 0)
        }
      >
        <div className="container border rounded pb-3">
          <div
            className="text-center wow fadeInUp border mb-3 rounded d-flex "
            style={{ marginTop: -35, backgroundColor: "white", width: "300px" }}
            data-wow-delay="0.1s"
          >
            {/* <h5 className="section-title ff-secondary text-center text-danger fw-normal">
              Destaques
            </h5> */}
            <span>
              <img
                src={urgent}
                alt=""
                title="Urgente"
                style={{ width: "61px" }}
              />
            </span>
            <span>
              <h3 className="mt-3 mb-2 text-danger ms-2"> Destaques</h3>
            </span>
          </div>
          <Splide
            options={{
              rewind: true,
              arrows: true,
              perPage: 3,
              type: "loop",
              gap: "3rem",
              perMove: 1,
              pagination: false,
              autoplay: true,
              speed: 400,
              interval: 2700,
              breakpoints: {
                400: {
                  perPage: 1,
                  padding: "10px",
                },
                767: {
                  perPage: 1,
                  padding: "70px",
                },
                1000: {
                  perPage: 2,
                  padding: "70px",
                },
              },
            }}
          >
            {afixadosD?.map((a) => (
              <SplideSlide
                className="splide__slide pb-3 pt-3 ps-2 pe-2"
                key={a.id}
              >
                <Card className={classes.card}>
                  <div className="d-flex  justify-content-end dropdownrounded"></div>
                  <CardContent
                    className="d-flex align-items-center justify-content-center"
                    style={{ flexGrow: 1 }}
                  >
                    <h5 className="text-dark">{a.d_curta}</h5>
                  </CardContent>
                  <CardMedia className="sub-link card-carousel">
                    <a
                      href={
                        a.img_link === "Sem imagem" || a.img_link === "capa.png"
                          ? logoprov
                          : a.img_link
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          a.img_link === "Sem imagem" ||
                          a.img_link === "capa.png"
                            ? logoprov
                            : a.img_link
                        }
                        alt={"Anónimo"}
                        title={"Anónimo"}
                        style={{ width: "180px", height: "180px" }}
                      />
                    </a>
                  </CardMedia>
                  <div className="mt-2 blur-name-home">
                    <span>{"Academia de Pesquisa"}</span> <br />
                    <small className="text-secondary mt-2">
                      {" "}
                      Publicado em: {dateFormat(a.created_at, "dd-mm-yyyy")}
                    </small>
                  </div>

                  <div className="divider"></div>
                  <div className="">
                    <div className="d-flex w-100 justify-content-between text-center">
                      <div className="w-50 text-center">
                        <NavLink
                          to={
                            a.tipo_caso === "Denuncia"
                              ? "/detalhesDenuncia/" + a.id
                              : "/detalhes/" + a.id
                          }
                        >
                          <Button
                            size="small"
                            color="primary"
                            className="fw-bold text-primary p-2 w-100 actions-button"
                            style={{ textTransform: "capitalize" }}
                            //onClick={() => navigate("/detalhes/" + a.id)}
                            //startIcon={<ThumbDownAltIcon />}
                          >
                            Detalhes
                          </Button>
                        </NavLink>
                      </div>
                      <div className="w-50 text-center">
                        <a href={"tel:+258845757847"}>
                          <Button
                            size="small"
                            color="primary"
                            className="fw-bold text-success p-2 w-100 actions-button"
                            style={{ textTransform: "capitalize" }}
                            //onClick={() => setTitulo("")}
                            //startIcon={<CheckCircleIcon />}
                          >
                            Ajudar
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Card>
              </SplideSlide>
            ))}
            {afixados?.map((a) => (
              <SplideSlide
                className="splide__slide pb-3 pt-3 ps-2 pe-2"
                key={a.id}
              >
                <Card className={classes.card}>
                  <div className="d-flex  justify-content-end dropdownrounded"></div>
                  <CardContent
                    className="d-flex align-items-center justify-content-center"
                    style={{ flexGrow: 1 }}
                  >
                    <h5 className="text-dark">{a.d_curta}</h5>
                  </CardContent>
                  <CardMedia className="sub-link card-carousel">
                    <a
                      href={
                        a.img_link === "Sem imagem" || a.img_link === "capa.png"
                          ? logoprov
                          : a.img_link
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          a.img_link === "Sem imagem" ||
                          a.img_link === "capa.png"
                            ? logoprov
                            : a.img_link
                        }
                        alt={a.tipo_caso === "Denuncia" ? "Anónimo" : a.nome}
                        title={a.tipo_caso === "Denuncia" ? "Anónimo" : a.nome}
                        style={{ width: "180px", height: "180px" }}
                      />
                    </a>
                  </CardMedia>
                  <div className="mt-2">
                    <span>
                      {a.tipo_caso === "Denuncia" ? "Anónimo" : a.nome}
                    </span>{" "}
                    <br />
                    <small className="text-secondary mt-2">
                      {" "}
                      Publicado em: {dateFormat(a.created_at, "dd-mm-yyyy")}
                    </small>
                  </div>

                  <div className="divider"></div>
                  <div className="">
                    <div className="d-flex w-100 justify-content-between text-center">
                      <div className="w-50 text-center">
                        <NavLink
                          to={
                            a.tipo_caso === "Denuncia"
                              ? "/detalhesDenuncia/" + a.id
                              : "/detalhes/" + a.id
                          }
                        >
                          <Button
                            size="small"
                            color="primary"
                            className="fw-bold text-primary p-2 w-100 actions-button"
                            style={{ textTransform: "capitalize" }}
                            //onClick={() => navigate("/detalhes/" + a.id)}
                            //startIcon={<ThumbDownAltIcon />}
                          >
                            Detalhes
                          </Button>
                        </NavLink>
                      </div>
                      <div className="w-50 text-center">
                        <a href={"tel:+258845757847"}>
                          <Button
                            size="small"
                            color="primary"
                            className="fw-bold text-success p-2 w-100 actions-button"
                            style={{ textTransform: "capitalize" }}
                            //onClick={() => setTitulo("")}
                            //startIcon={<CheckCircleIcon />}
                          >
                            Ajudar
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Card>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </div>
  );
}
