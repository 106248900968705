import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("userLogado"));
  console.log("user", user);

  if (!user) {
    return false;
  } else {
    return true;
  }
};

const PublicRoutes = (props) => {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PublicRoutes;
