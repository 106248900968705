import React from "react";
import Appbar from "./appbar";
import Footer from "./footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import hope from "../img/hopeEdit.jpg";
import { Button, TextField } from "@material-ui/core";
import { Icon } from "@iconify/react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import logoprov from "../img/logoprov.png";
import { storage } from "../firebase";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});
const Formulario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  /* -------------DADOS DO FORMULÁRIO -------------- */
  const [nome, setNome] = useState(state?.state?.nome);
  const [morada, setMorada] = useState(state?.state?.morada);
  const [contacto, setContacto] = useState(state?.state?.contacto);
  const [contacto_op, setContacto_op] = useState(state?.state?.contacto_op);
  const [apoio, setApoio] = useState(state?.state?.d_curta);
  const [d_longa, setD_longa] = useState(state?.state?.d_longa);
  const [filename, setFilename] = useState(state?.state?.img_link);
  const [loader, setLoader] = useState(false);

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorMorada, setErrorMorada] = useState(false);
  const [errorMoradaMessage, setErrorMoradaMessage] = useState("");

  const [errorContacto, setErrorContacto] = useState(false);
  const [errorContactoMessage, setErrorContactoMessage] = useState("");

  const [errorContacto_op, setErrorContacto_op] = useState(false);
  const [errorContacto_opMessage, setErrorContacto_opMessage] = useState("");

  const [errorDcurta, setErrorDcurta] = useState(false);
  const [errorDcurtaMessage, setErrorDcurtaMessage] = useState("");

  const [errorDlonga, setErrorDlonga] = useState(false);
  const [errorDlongaMessage, setErrorDlongaMessage] = useState("");

  const dados = {
    nome: nome,
    contacto: contacto,
    contacto_op: contacto_op,
    morada: morada,
    filename: filename,
    apoio: apoio,
    d_longa: d_longa,
    tipo_caso: state?.state?.tipo_caso,
    estado: state?.state?.estado,
    afixado: state?.state?.afixado,
    usuario: state?.state?.usuario,
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorMorada === false &&
      errorContacto === false &&
      errorDcurta === false &&
      errorDlonga === false
    ) {
      handleUpload();
    } else {
      console.log("N");
    }
  };

  const requiredFields = () => {
    const re = /^[0-9\b]+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (morada === "" || morada === undefined) {
      setErrorMorada(true);
      setErrorMoradaMessage("Por favor, preencha este campo!");
    } else {
      setErrorMorada(false);
      setErrorMoradaMessage(true);
    }
    if (
      contacto === "" ||
      contacto?.length < 9 ||
      contacto === undefined ||
      !contacto?.match(re)
    ) {
      setErrorContacto(true);
      setErrorContactoMessage(
        "Número de telefone inválido, campo obrigatório!"
      );
    } else {
      setErrorContacto(false);
      setErrorContactoMessage(true);
    }
    if (contacto_op) {
      if (!contacto_op?.match(re) || contacto_op?.length < 9) {
        setErrorContacto_op(true);
        setErrorContacto_opMessage("Número de telefone inválido!");
      } else {
        setErrorContacto_op(false);
        setErrorContacto_opMessage(true);
      }
    }

    if (apoio === "" || apoio === undefined) {
      setErrorDcurta(true);
      setErrorDcurtaMessage("Por favor, preencha este campo!");
    } else {
      setErrorDcurta(false);
      setErrorDcurtaMessage(true);
    }
    if (d_longa === "" || d_longa === undefined) {
      setErrorDlonga(true);
      setErrorDlongaMessage("Por favor, preencha este campo!");
    } else {
      setErrorDlonga(false);
      setErrorDlongaMessage(true);
    }
  };

  const salvar = (linkImage) => {
    setLoader(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: id,
        nome: dados.nome,
        contacto: dados.contacto,
        contacto_op: dados.contacto_op,
        morada: dados.morada,
        img_link: linkImage,
        d_longa: dados.d_longa,
        d_curta: dados.apoio,
        tipo_caso: dados.tipo_caso,
        usuario: dados.usuario,
        estado: dados.estado,
        afixado: dados.afixado,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/edit", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (usuarioLogado.tipo !== "Normal") {
          saveEvent(
            usuarioLogado.usuario,
            "Edição",
            `Editou o caso "${dados.apoio}", de ${dados.nome}.`
          );
        }
        setLoader(false);
        localStorage.setItem("editado", JSON.stringify(true));
        navigate(-1);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const saveEvent = (user, event, description) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: event,
        descricao: description,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {
      });
  };

  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    setLoader(true);
    if (image) {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setFilename(url);
              salvar(url);
            });
        }
      );
    } else {
      salvar(filename);
    }
  };

  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Appbar />
        {/* -----------------BANNER - CAPPA--------------- */}
        <div className="container-fluid py-5 bg-dark hero-header-detalhes mb-5">
          <div className="container text-center my-5 pt-2">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Editar publicação
            </h1>
          </div>
        </div>
      </div>

      {/* -------------- Contact Start --------------- */}
      <div className="container-xxl py-2 mt-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h3 className="section-title ff-secondary text-center text-primary fw-normal ">
              Formulário
            </h3>
          </div>
          <div className="row g-4 mt-1">
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              {/* <iframe
                className="position-relative rounded w-100 h-100"
                title="frame"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                frameborder="0"
                style={{ minHeight: "350px", border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe> */}
              <a href={hope} target="_blank" rel="noreferrer">
                <img
                  src={hope}
                  alt="Holding hands"
                  style={{ width: "100%", height: "100%", marginLeft: 0 }}
                />
              </a>
            </div>
            <div className="col-md-6">
              <div className="wow fadeInUp text-start" data-wow-delay="0.2s">
                <h5>
                  Tipo de pedido:{" "}
                  {state?.state?.tipo_caso === "Projecto" && "Ideia"}{" "}
                  {state?.state?.tipo_caso === "Saude" && "Saúde"}{" "}
                  {state?.state?.tipo_caso === "Estudos" && "Estudos"}{" "}
                  {state?.state?.tipo_caso === "Denuncia" && "Denúncia"}{" "}
                </h5>
                <form onSubmit={formSubmit}>
                  <div className="row g-3 mt-2">
                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="nome"
                          label="Nome Completo"
                          type={"text"}
                          id="nome"
                          value={nome}
                          defaultValue={nome}
                          error={errorNome}
                          helperText={errorNomeMessage}
                          onChange={(e) => setNome(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>

                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="morada"
                          label="Morada"
                          type={"text"}
                          id="morada"
                          value={morada}
                          defaultValue={morada}
                          error={errorMorada}
                          helperText={errorMoradaMessage}
                          onChange={(e) => setMorada(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="contacto"
                          label="Contacto"
                          type={"text"}
                          id="contacto"
                          value={contacto}
                          defaultValue={contacto}
                          error={errorContacto}
                          helperText={errorContactoMessage}
                          onChange={(e) => setContacto(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="contacto_op"
                          label="Contacto opcional"
                          type={"text"}
                          id="contacto_op"
                          value={contacto_op}
                          defaultValue={contacto_op}
                          error={errorContacto_op}
                          helperText={errorContacto_opMessage}
                          onChange={(e) => setContacto_op(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="apoio"
                          label="Breve descrição do problema"
                          type={"text"}
                          id="apoio"
                          value={apoio}
                          defaultValue={apoio}
                          error={errorDcurta}
                          helperText={errorDcurtaMessage}
                          onChange={(e) => setApoio(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>

                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="d_longa"
                          label="Conte-nos sua história..."
                          multiline
                          rows={3}
                          type={"text"}
                          id="d_longa"
                          value={d_longa}
                          defaultValue={d_longa}
                          error={errorDlonga}
                          helperText={errorDlongaMessage}
                          onChange={(e) => setD_longa(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-2">
                      <a
                        href={
                          state?.state.img_link === "Sem imagem" ||
                          state?.state.img_link === "capa.png"
                            ? logoprov
                            : state?.state.img_link
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={
                            state?.state.img_link === "Sem imagem" ||
                            state?.state.img_link === "capa.png"
                              ? logoprov
                              : state?.state.img_link
                          }
                          alt="firebase-imag"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </a>
                    </div>
                    <div className="col-10">
                      <div className="form-floating">
                        <input
                          type="file"
                          alt="Clique para seleccionar uma foto"
                          className="form-control text-dark"
                          id="filename"
                          onChange={handleChangeImage}
                          style={{ backgroundColor: "white" }}
                        />
                        <label htmlFor="filename">
                          {" "}
                          Clique para alterar a foto
                        </label>
                      </div>
                    </div>

                    <div className="col-12 justify-content-center d-flex ">
                      <Button
                        variant="contained"
                        className="me-1 bg-danger text-light fw-bold"
                        //startIcon={<SaveIcon />}
                        onClick={() => navigate(-1)}
                        style={{ width: "49%" }}
                      >
                        <Icon
                          className="me-2"
                          icon="icon-park-outline:back"
                          color="white"
                          width="20"
                          height="20"
                          inline={true}
                        />
                        Voltar
                      </Button>

                      <Button
                        variant="contained"
                        type="submit"
                        className="ms-1 bg-primary text-light fw-bold"
                        //startIcon={<SaveIcon />}
                        onClick={() => requiredFields()}
                        style={{ width: "49%" }}
                        disabled={loader}
                      >
                        <Icon
                          className="me-2"
                          icon="fluent:save-arrow-right-20-regular"
                          color="white"
                          width="24"
                          height="24"
                          inline={true}
                        />
                        {loader ? "A enviar..." : "Enviar"}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*--------- Contact End --------------- */}

      <Footer />
    </div>
  );
};

export default Formulario;
