import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("userLogado"));
  console.log("user", user);

  if (!user || user?.tipo === "Normal") {
    return false;
  } else {
    return true;
  }
};
/* const isAuthenticated = localStorage.getItem("isAuthenticated");
console.log('isAuthenticated', isAuthenticated) */

const ProtectedRoutes = (props) => {
  const auth = useAuth();
  console.log("auth", auth);
  return auth ? <Outlet /> : <Navigate to="/permissiondenied" />;
};

export default ProtectedRoutes;
