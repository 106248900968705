import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Facebook from "../img/media/facebook32.png";
import Instagram from "../img/media/instagram.png";
import Twitter from "../img/media/twitter1.png";
import Youtube from "../img/media/youtube2.png";
import Video_Home from "../img/media/video2.png";
import Whatsapp from "../img/media/whatsap.png";
import Telefone from "../img/media/telephone.png";
import LinkedIn from "../img/media/linkedin2.png";
import socialMedia from "../img/socialMedia.jpg";
import Footer from "./footer";
import {
  Button,
  Container,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import Sidebar from "./appbarAdmin";
import { Modal, Spinner } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import SnackBar from "./snackbar";
import { CloseButton } from "react-bootstrap";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f50b5", //your color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(11),
    backgroundColor: "",
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
}));

export default function SocialMedia(props) {
  const classes = useStyles();
  const [openSide, setOpenSide] = useState(false);

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const [links, setLinks] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links`)
      .then((response) => response.json())
      .then((data) => {
        setLinks(data.data);
      });
  }, []);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [loader2, setLoader2] = useState(false);
  const [mediaID, setMediaID] = useState();
  const [accao, setaccao] = useState();
  const [nome, setNome] = useState();
  const [mediaLink, setMediaLink] = useState();

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorNome === false && errorLink === false) {
      editar();
    } else {
      console.log("N");
    }
  };

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorLink, setErrorLink] = useState(false);
  const [errorLinkMessage, setErrorLinkMessage] = useState("");

  const requiredFields = () => {
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }

    if (mediaLink === "" || mediaLink === undefined) {
      setErrorLink(true);
      setErrorLinkMessage("Por favor, preencha este campo!");
    } else {
      setErrorLink(false);
      setErrorLinkMessage(true);
    }
  };

  const [showEdit, setShowEdit] = useState(false);
  const [iconModal, setIconModal] = useState("");

  const openModalEdit = (id, nome, mediaLink) => {
    setMediaID(id);
    setNome(nome);
    setMediaLink(mediaLink);
    setShowEdit(true);
  };

  const dados = {
    nome: nome,
    mediaLink: mediaLink,
  };

  const editar = () => {
    //console.log(dados);
    setLoader2(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: mediaID,
        nome: dados.nome,
        descricao: dados.mediaLink,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/links/edit", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/links")
          .then((response) => response.json())
          .then((data) => {
            setLoader2(false);
            setShowEdit(false);
            setOpen(true);
            setLinks(data.data);
          });
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

/*   const salvar = () => {
    //console.log(dados);
    setLoader2(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        nome: dados.nome,
        descricao: dados.mediaLink,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/links/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader2(false);
        setShowEdit(false);
        //setOpen1(true);
        //setTestemunhos(data.data);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }; */

  const setImageName = (nome) => {
    if (nome === "Facebook") {
      return Facebook;
    }
    if (nome === "Instagram") {
      return Instagram;
    }
    if (nome === "Twitter") {
      return Twitter;
    }
    if (nome === "Youtube") {
      return Youtube;
    }
    if (nome === "Video_Home") {
      return Video_Home;
    }
    if (nome === "Telefone") {
      return Telefone;
    }
    if (nome === "Whatsapp") {
      return Whatsapp;
    }
    if (nome === "LinkedIn") {
      return LinkedIn;
    }
  };

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        <div className="container-xxl py-2 mt-2">
          <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h2 className="section-title ff-secondary text-center text-primary fw-normal ">
                Social Media
              </h2>
              <h3 className="mb-5">Links da Academia</h3>
            </div>
            <div className="row g-4" style={{ marginTop: -50 }}>
              <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                <img
                  src={socialMedia}
                  alt="Holding hands"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="col-md-6">
                <div className="wow fadeInUp mt-2" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-1">
                      {links?.map((l) => (
                        <>
                          {l.id < 9 && (
                            <div className="col-md-12 d-flex mb-2" key={l.id}>
                              <div className="dados text-start d-flex justify-content-between">
                                <span>
                                  <img
                                    src={setImageName(l.nome)}
                                    alt={l.nome}
                                    style={{ width: "30px" }}
                                  />
                                </span>
                                <a
                                  href={l.descricao}
                                  className="ms-2 mt-1 links"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {l?.nome}
                                </a>
                                <span className="">
                                  <Button
                                    className="me-0 fw-bold accordion-buttons ms-2"
                                    color="primary"
                                    onClick={() => {
                                      openModalEdit(
                                        l?.id,
                                        l?.nome,
                                        l?.descricao
                                      );
                                      setaccao("editar");
                                      setIconModal(
                                        <span>
                                          <img
                                            src={setImageName(l.nome)}
                                            alt={l.nome}
                                            style={{ width: "30px" }}
                                          />
                                        </span>
                                      );
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ))}

                      {/* <div className="col-12 justify-content-end d-flex ">
                        <Fab
                          variant="extended"
                          className=" text-light fw-bold ms-1 button-details1 mb-2"
                          onClick={() => {
                            openModalEdit("", "", "");
                            setaccao("adicionar");
                          }}
                          style={{
                            backgroundColor: "#3f51b5",
                            width: 120,
                            height: 45,
                          }}
                        >
                          <AddIcon className="me-2" />
                          Link
                        </Fab>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        show={showEdit}
        style={{ marginTop: 0 }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-dark">
          <Modal.Title>
            {" "}
            <span className="text-light" style={{ fontSize: 21 }}>
              <EditIcon /> {"   "}
              Editar link do {nome}
            </span>{" "}
          </Modal.Title>
          <CloseButton variant="white" onClick={() => setShowEdit(false)} />
        </Modal.Header>
        <Modal.Body>
          <div className="row g-4  div-detalhes mt-1">
            <form onSubmit={formSubmit}>
              <div className="row g-1">
                <div className="col-md-12">
                  <ThemeProvider theme={theme}>
                    <TextField
                      disabled
                      variant="outlined"
                      size={"small"}
                      fullWidth
                      name="nome"
                      label="Nome da rede"
                      className="mb-4"
                      type={"text"}
                      id="nome"
                      value={nome}
                      defaultValue={nome}
                      error={errorNome}
                      helperText={errorNomeMessage}
                      onChange={(e) => setNome(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {iconModal}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ThemeProvider>
                </div>
                <div className="col-md-12">
                  <ThemeProvider theme={theme}>
                    <TextField
                      variant="outlined"
                      size={"small"}
                      fullWidth
                      name="mediaLink"
                      label="Link"
                      className="mb-4"
                      type={"text"}
                      value={mediaLink}
                      defaultValue={mediaLink}
                      error={errorLink}
                      helperText={errorLinkMessage}
                      onChange={(e) => setMediaLink(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon
                              icon="akar-icons:link-chain"
                              width="24"
                              height="24"
                              inline={true}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ThemeProvider>
                </div>
                <div className="col-md-12 text-end">
                  <div className="">
                    <Button
                      style={{ color: "white", textTransform: "capitalize" }}
                      onClick={() => setShowEdit(false)}
                      className="bg-danger me-3 fw-bold"
                    >
                      <Icon
                        icon="iconoir:cancel"
                        color="white"
                        width="22"
                        height="22"
                        inline={true}
                      />
                      Cancelar
                    </Button>
                    <Button
                      style={{ color: "white", textTransform: "capitalize" }}
                      className="text-light bg-dark fw-bold"
                      type="submit"
                      onClick={() => requiredFields()}
                      disabled={loader2}
                    >
                      {loader2 ? (
                        <Spinner
                          animation="border"
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <Icon
                          className="me-2 text-light"
                          icon="fluent:save-arrow-right-20-regular"
                          width="24"
                          height="24"
                          inline={true}
                        />
                      )}
                      Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={"Link actualizado com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />

      <Footer />
    </div>
  );
}
