import React from "react";
import Appbar from "./appbar";
import happy from "../img/allow2.png";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";

const NaoPermitido = () => {
  let navigate = useNavigate();
  // run this function from an event handler or an effect to execute scroll

  return (
    <div>
      <Appbar />
      <div className="container-fluid py-5 bg-dark hero-header-permissao">
        <div className="container text-center my-5 pt-5 pb-4">
          <div className="row align-items-center g-5 mt-2">
            <div className="col-lg-6 text-center text-lg-start">
              <h1 className="display-4 text-danger animated slideInLeft">
                Não tem permissão para aceder a esta página!
              </h1>

              <span className="" onClick={() => navigate(-1)}>
                <Button
                  variant="contained"
                  className="fw-bold bg-danger mt-3 py-2 px-4 me-3 contact-us text-white"
                  style={{ width: "100px", textTransform: "capitalize" }}
                  onClick={() => navigate(-1)}
                >
                  Voltar
                </Button>
              </span>
            </div>
            <div className="col-lg-6 text-center text-lg-center overflow-hidden">
              <img src={happy} alt="hero" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NaoPermitido;
