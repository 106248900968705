import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./footer";
import {
  Button,
  Collapse,
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableFooter,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FilterListIcon from "@material-ui/icons/FilterList";
import Sidebar from "./appbarAdmin";
import { Spinner } from "react-bootstrap";
import dateFormat from "dateformat";
import nothing22 from "../img/box32.png";
import searchGif from "../img/searchGif.gif";
import Pagination from "./pagination";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(11),
    backgroundColor: "",
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
}));

let PageSize = 10;

export default function Historico(props) {
  const classes = useStyles();
  const [openSide, setOpenSide] = useState(false);

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [actividades, setActividades] = useState();

  const [existentes, setExistentes] = useState(true);
  const [existentes1, setExistentes1] = useState(false);

  const fetchData = () => {
    setLoading(true);
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs")
      .then((response) => response.json())
      .then((data) => {
        setActividades(
          data?.data.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          })
        );
        setLoading(false);
        if (data.data.length !== 0) {
          setExistentes(true);
        } else {
          setExistentes(false);
        }
      });
  };
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetchData();
  }, []);
  const [currentPage, setCurrentPage] = useState(2);
  
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return actividades?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, actividades]);

  const [searching, setSearching] = useState(false);
  const [searching1, setSearching1] = useState(false);
  const [searchNome, setSearchNome] = useState();
  const [searchDescricao, setSearchDescricao] = useState();

  const searchByNome = (nome) => {
    setSearching(true);
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/searchByUsuario/${nome}`)
      .then((response) => response.json())
      .then((data) => {
        setActividades(
          data.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          })
        );
        setSearching(false);
        if (data.length === 0) {
          setExistentes1(true);
        } else {
          setExistentes1(false);
        }
      });
  };

  const searchByDescricao = (descricao) => {
    setSearching1(true);
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/searchByEvento/${descricao}`)
      .then((response) => response.json())
      .then((data) => {
        setActividades(
          data.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          })
        );
        setSearching1(false);
        if (data.length === 0) {
          setExistentes1(true);
        } else {
          setExistentes1(false);
        }
      });
  };

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        <div className="container-xxl mt-2">
          <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
            <h3 className="section-title ff-secondary text-center text-primary fw-normal ">
              Controle de actividades
            </h3>
            <h3 className="mb-2">Histórico de utilizadores</h3>
          </div>
          <div className="row g-4">
            {/* -------------------TABELA DE LISTAGEM------------- */}

            <div className="col-md-12">
              <TableContainer component={Paper}>
                <div className="w-100 text-end mb-2">
                  <Button
                    hidden={!existentes}
                    name="Basic button"
                    value="default"
                    size="medium"
                    onClick={() => setFilter(!filter)}
                    aria-expanded={filter}
                    aria-controls="collapse-filter"
                    title="Filtrar"
                  >
                    <FilterListIcon
                      style={{ fontSize: 32, color: "#ea8b0f" }}
                    />
                    {filter ? (
                      <ArrowDropUpIcon style={{ color: "#ea8b0f" }} />
                    ) : (
                      <ArrowDropDownIcon style={{ color: "#ea8b0f" }} />
                    )}
                  </Button>
                </div>
                <Collapse
                  in={filter}
                  timeout="auto"
                  unmountOnExit
                  disablePadding
                >
                  <div className="row g-4 mb-2 d-flex justify-content-end me-2">
                    <div
                      className="position-relative col-md-6"
                      style={{ maxWidth: "320px", marginTop: 20 }}
                    >
                      <input
                        className="form-control border-primary py-3 ps-4 pe-5"
                        type="text"
                        placeholder="Pesquisar por usuário"
                        style={{ height: 45 }}
                        value={searchNome}
                        onChange={(e) => setSearchNome(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search "
                        style={{ width: 55, height: 45 }}
                        onClick={() =>
                          searchNome ? searchByNome(searchNome) : fetchData()
                        }
                        disabled={searching}
                      >
                        {searching ? (
                          <Spinner
                            animation="border"
                            style={{ width: 24, height: 24 }}
                          />
                        ) : (
                          <SearchIcon
                            className="text-light btn-search"
                            style={{ marginTop: -5 }}
                          />
                        )}
                      </button>
                    </div>
                    <div
                      className="position-relative col-md-6"
                      style={{ maxWidth: "320px", marginTop: 20 }}
                    >
                      <input
                        className="form-control border-primary py-3 ps-4 pe-5"
                        type="text"
                        placeholder="Pesquisar por evento"
                        style={{ height: 45 }}
                        value={searchDescricao}
                        onChange={(e) => setSearchDescricao(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search "
                        style={{ width: 55, height: 45 }}
                        onClick={() =>
                          searchDescricao
                            ? searchByDescricao(searchDescricao)
                            : fetchData()
                        }
                        disabled={searching1}
                      >
                        {searching1 ? (
                          <Spinner
                            animation="border"
                            style={{ width: 24, height: 24 }}
                          />
                        ) : (
                          <SearchIcon
                            className="text-light btn-search"
                            style={{ marginTop: -5 }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </Collapse>

                {loading ? (
                  <div className="  text-center d-flex w-100 align-items-center justify-content-center">
                    <div
                      className="spinner-border text-primary"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {existentes ? (
                      <>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead className="table-head">
                            <TableRow>
                              <TableCell
                                className="fw-bold" /* align="right" */
                              >
                                ID
                              </TableCell>
                              <TableCell
                                className="fw-bold" /* align="right" */
                              >
                                Usuário
                              </TableCell>
                              <TableCell
                                className="fw-bold" /* align="right" */
                              >
                                Evento
                              </TableCell>
                              <TableCell className="fw-bold">
                                Descrição
                              </TableCell>
                              <TableCell className="fw-bold">
                                Data e hora
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentTableData?.map((d) => (
                              <>
                                <TableRow key={d.id} className="log-row">
                                  <TableCell /* align="right" */>
                                    {d.id}
                                  </TableCell>
                                  <TableCell /* align="right" */>
                                    {d.usuario}
                                  </TableCell>
                                  <TableCell /* align="right" */>
                                    {d.evento}
                                  </TableCell>
                                  <TableCell /* align="right" */>
                                    {d.descricao}
                                  </TableCell>
                                  <TableCell /* align="right" */>
                                    {dateFormat(
                                      d.created_at,
                                      "dd/mm/yyyy | HH:MM:ss "
                                    )}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                          <TableFooter></TableFooter>
                        </Table>{" "}
                        <div className="w-100 d-flex justify-content-center p-3">
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={actividades?.length}
                            pageSize={PageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="p-5 text-center d-inline-block w-100 align-items-center justify-content-center">
                        {/* <span>{props.nada}</span> */}
                        <div>
                          <img
                            src={nothing22}
                            alt=""
                            title="Nada para mostrar"
                            style={{ width: "170px" }}
                          />
                        </div>
                        <span className="text-secondary">Histórico vazio!</span>{" "}
                      </div>
                    )}
                    {existentes1 && (
                      <div className="mt-3 text-center d-inline-block w-100 align-items-center justify-content-center">
                        {/* <span>{props.nada}</span> */}
                        <div>
                          <img
                            src={searchGif}
                            alt=""
                            title="Nada para mostrar"
                            style={{ width: "170px" }}
                          />
                        </div>
                        <span className="text-secondary">
                          Nenhum resultado encontrado!
                        </span>{" "}
                      </div>
                    )}
                  </>
                )}
              </TableContainer>
            </div>
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
}
