import React, { useEffect } from "react";
import Home from "../../components/home";



const HomePage = () => {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, []);
  return (
    <div >
      <Home />
    </div>
  );
};
export default HomePage;

