import React, { useEffect, useState } from "react";
import Appbar from "./appbar";
import jquery from "jquery";
import Footer from "./footer";
import { useParams } from "react-router-dom";
import { Fab } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import PanToolIcon from "@material-ui/icons/PanTool";
import logoprov from "../img/logoprov.png";

export default function Detalhes(props) {
  (function ($) {
    "use strict";

    // Spinner
    var spinner = function () {
      setTimeout(function () {
        if ($("#spinner").length > 0) {
          $("#spinner").removeClass("show");
        }
      }, 1);
    };
    spinner();
  })(jquery);

  const { id } = useParams();
  const [pedido, setPedido] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/showDenuncia/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setPedido(data?.[0]);
        setLoading(false);
      });
  }, [id]);

  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Appbar />
        {/* -----------------BANNER - CAPPA--------------- */}
        <div className="container-fluid py-5 bg-dark hero-header-detalhes mb-5">
          <div className="container text-center my-5 pt-2">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Detalhes - Denúncia
            </h1>
          </div>
        </div>
      </div>

      {/* ---------------- HORA DE AGIR -------------- */}
      <div className="container-xxl py-2 mt-5">
        <div className="container">
          <div className="row g-4" style={{ marginTop: -50 }}>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <a
                href={
                  pedido?.img_link === "Sem imagem" ||
                  pedido?.img_link === "capa.png"
                    ? logoprov
                    : pedido?.img_link
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    pedido?.img_link === "Sem imagem" ||
                    pedido?.img_link === "capa.png"
                      ? logoprov
                      : pedido?.img_link
                  }
                  alt={"ANÓNIMO"}
                  title={"ANÓNIMO"}
                  style={{ width: "100%", height: "auto" }}
                />
              </a>
            </div>
            <div className="col-md-6 text-start">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                {loading ? (
                  <div className="mt-5 pt-5  text-center d-flex w-100 align-items-center justify-content-center">
                    <div
                      className="spinner-border text-primary"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="row g-3">
                      <div className="col-12 text-center ">
                        <h3 className="">{"ANÓNIMO"}</h3>
                      </div>

                      <div className="col-md-12">
                        <small className="fw-bold text-primary">
                          Breve descrição:{" "}
                        </small>
                      </div>
                      <div className="col-md-12">
                        <AccessibilityNewIcon
                          className="me-1"
                          style={{ fontSize: 17, marginTop: -2 }}
                        />
                        {pedido?.d_curta}
                      </div>

                      <div className="col-md-12">
                        <small className="fw-bold text-primary">
                          História:{" "}
                        </small>
                      </div>
                      <div className="col-md-12">{pedido?.d_longa}</div>

                      <div className="col-12 text-center pt-3">
                        <a href="tel:+258827890800">
                          <Fab
                            variant="extended"
                            className=" text-light fw-bold ms-1 button-details mb-2"
                            style={{
                              backgroundColor: "#3f51b5",
                              width: 210,
                              height: 45,
                              textTransform: "capitalize",
                            }}
                          >
                            <AddIcCallIcon className="me-2" />
                            Academia
                          </Fab>
                        </a>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------- HORA DE AGIR -------------- */}

      {/* ------- ------- FOOTER ------- ------- */}
      <Footer />
      {/* ------- ------- FOOTER ------- ------- */}

      {/* <BackTop /> */}
    </div>
  );
}
