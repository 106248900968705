import React, { useEffect } from "react";
import Utilizadores from "../../../components/utilizadoresP";



const UtilizadoresPublico = () => {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, []);
  return (
    <div >
      <Utilizadores />
    </div>
  );
};
export default UtilizadoresPublico;

