import React, { useEffect, useRef } from "react";
import nothing22 from "../img/down.gif";
import searchGif from "../img/searchGif.gif";
import Appbar from "./appbar";
import hands1 from "../img/hands1.jpeg";
import Footer from "./footer";
import { NavLink, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import logoprov from "../img/logoprov.png";
import { Button, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Icon } from "@iconify/react";
import SnackBar from "./snackbar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { storage } from "../firebase";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});

const Formulario = () => {
  const [showForm, setShowForm] = useState(true);
  const myRef = useRef(null);
  const navigate = useNavigate();

  const executeScroll = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  //console.log('today', today);

  const [nome, setNome] = useState();
  const [morada, setMorada] = useState();
  const [contacto, setContacto] = useState();
  const [contacto_op, setContacto_op] = useState();
  const [filename, setFilename] = useState("");
  const [apoio, setApoio] = useState();
  const [d_longa, setD_longa] = useState();
  const [anonimo, setAnonimo] = useState();
  const [denuncias, setdenuncias] = useState();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [existentes, setExistentes] = useState(true);
  const [existentes1, setExistentes1] = useState(false);

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorMorada, setErrorMorada] = useState(false);
  const [errorMoradaMessage, setErrorMoradaMessage] = useState("");

  const [errorContacto, setErrorContacto] = useState(false);
  const [errorContactoMessage, setErrorContactoMessage] = useState("");

  const [errorContacto_op, setErrorContacto_op] = useState(false);
  const [errorContacto_opMessage, setErrorContacto_opMessage] = useState("");

  const [errorDcurta, setErrorDcurta] = useState(false);
  const [errorDcurtaMessage, setErrorDcurtaMessage] = useState(
    "Exemplo de breve descrição: (Pedido de doação de rim)"
  );

  const [errorDlonga, setErrorDlonga] = useState(false);
  const [errorDlongaMessage, setErrorDlongaMessage] = useState("");

  const [open, setOpen] = useState();
  const [mensagem, setMensagem] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const fetchData = () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setdenuncias(
          data?.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          })
        );
        if (data?.length !== 0) {
          setExistentes(true);
        } else {
          setExistentes(false);
        }
      });
  };
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetchData();
  }, []);

  const dados = {
    nome: nome,
    contacto: contacto,
    contacto_op: contacto_op,
    morada: morada,
    filename: filename,
    apoio: apoio,
    d_longa: d_longa,
    anonimo: anonimo,
    tipo_caso: "Saude",
    estado: "Pendente",
    usuario: usuarioLogado.nome,
    afixado: "Nao",
  };

  const cleanFields = () => {
    setNome("");
    setMorada("");
    setContacto("");
    setContacto_op("");
    setAnonimo("Não");
    setFilename("");
    setApoio("");
    setD_longa("");
    setImage1("Nenhuma foto seleccionada");
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorMorada === false &&
      errorContacto === false &&
      errorDcurta === false &&
      errorDlonga === false
    ) {
      handleUpload();
    } else {
      console.log("N");
    }
  };

  const salvar = (linkImage) => {
    //console.log(dados);
    setLoader(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        nome: dados.nome,
        contacto: dados.contacto,
        contacto_op: dados.contacto_op,
        morada: dados.morada,
        img_link: linkImage,
        d_longa: dados.d_longa,
        d_curta: dados.apoio,
        tipo_caso: dados.tipo_caso,
        usuario: dados.usuario,
        estado: dados.estado,
        afixado: dados.afixado,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cleanFields();
        setLoader(false);
        setOpen(true);
        setMensagem("Pedido registado com sucesso!");
        //console.log(data.data);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const verifyLogin = () => {
    if (usuarioLogado === "") {
      navigate("/login");
    } else {
      setShowForm(false);
      executeScroll();
    }
  };

  const [searching, setSearching] = useState(false);
  const [searching1, setSearching1] = useState(false);

  const [searchNome, setSearchNome] = useState();
  const [searchDescricao, setSearchDescricao] = useState();

  const searchByNome = (nome) => {
    setSearching(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        nome: nome,
        estado: "Aprovado",
      }),
    };
    fetch(
      "https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAllName",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setSearching(false);
        setdenuncias(
          data?.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          })
        );
        if (data?.length === 0) {
          setExistentes1(true);
        } else {
          setExistentes1(false);
        }
      });
  };

  const searchByDescricao = (descricao) => {
    setSearching1(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        d_curta: descricao,
        estado: "Aprovado",
      }),
    };
    fetch(
      "https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAllDesc",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setSearching1(false);
        setdenuncias(
          data?.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          })
        );
        if (data?.length === 0) {
          setExistentes1(true);
        } else {
          setExistentes1(false);
        }
      });
  };

  const requiredFields = () => {
    const re = /^[0-9\b]+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (morada === "" || morada === undefined) {
      setErrorMorada(true);
      setErrorMoradaMessage("Por favor, preencha este campo!");
    } else {
      setErrorMorada(false);
      setErrorMoradaMessage(true);
    }
    if (
      contacto === "" ||
      contacto?.length < 9 ||
      contacto === undefined ||
      !contacto?.match(re)
    ) {
      setErrorContacto(true);
      setErrorContactoMessage(
        "Número de telefone inválido, campo obrigatório!"
      );
    } else {
      setErrorContacto(false);
      setErrorContactoMessage(true);
    }
    if (contacto_op) {
      if (!contacto_op?.match(re) || contacto_op?.length < 9) {
        setErrorContacto_op(true);
        setErrorContacto_opMessage("Número de telefone inválido!");
      } else {
        setErrorContacto_op(false);
        setErrorContacto_opMessage(true);
      }
    }

    if (apoio === "" || apoio === undefined) {
      setErrorDcurta(true);
      setErrorDcurtaMessage("Por favor, preencha este campo!");
    } else {
      setErrorDcurta(false);
      setErrorDcurtaMessage(true);
    }
    if (d_longa === "" || d_longa === undefined) {
      setErrorDlonga(true);
      setErrorDlongaMessage("Por favor, preencha este campo!");
    } else {
      setErrorDlonga(false);
      setErrorDlongaMessage(true);
    }
  };

  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [image1, setImage1] = useState("Nenhuma foto seleccionada");

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImage1(e.target.files[0].name);
    }
  };

  const handleUpload = () => {
    setLoader(true);
    if (image) {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setFilename(url);
              setImage1(image.name);
              //console.log("url", url);
              salvar(url);
            });
        }
      );
    } else {
      salvar("Sem imagem");
    }
  };

  return (
    <div>
      <Appbar />
      <div className="container-fluid py-5 bg-dark hero-header-saude mb-5">
        5
        <div className="container text-center my-5 pt-5 pb-4">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Permita que lhe ajudemos a lutar pela vida!
          </h1>
          <div className="container text-center justify-content-center d-flex ">
            <p
              className="nav-link bg-primary2 border-primary fw-bold border mt-3 text-light rounded ver-mais"
              style={{ cursor: "pointer" }}
              onClick={() => verifyLogin()}
              title="Filtrar"
            >
              <span>Submeter pedido</span>
            </p>
          </div>
        </div>
      </div>

      {/* ----------------------LISTA DE DENUNCIAS----------------------- */}
      <div
        className="container-xxl py-2 wow fadeInUp listagem mb-5"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row g-4" style={{ marginTop: -50 }}>
            <div className="text-center col-md-8">
              <h5 className="section-title ff-secondary text-center text-primary fw-normal mt-5">
                Pedidos feitos
              </h5>
              <h1 className="mb-5">Apoios requisitados</h1>
            </div>

            <div
              className="text-center col-md-4 search-div"
              hidden={!existentes}
            >
              <div
                className="position-relative mx-auto "
                style={{ maxWidth: "400px", marginTop: 30 }}
              >
                <input
                  className="form-control border-primary w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Pesquisar por nome"
                  style={{ height: 45 }}
                  value={searchNome}
                  onChange={(e) => setSearchNome(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search "
                  style={{ width: 55, height: 45 }}
                  onClick={() =>
                    searchNome ? searchByNome(searchNome) : fetchData()
                  }
                  disabled={searching}
                >
                  {searching ? (
                    <Spinner
                      animation="border"
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <SearchIcon
                      className="text-light btn-search"
                      style={{ marginTop: -5 }}
                    />
                  )}
                </button>
              </div>
              <div
                className="position-relative mx-auto"
                style={{ maxWidth: "400px", marginTop: 20 }}
              >
                <input
                  className="form-control border-primary w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Pesquisar por título"
                  style={{ height: 45 }}
                  value={searchDescricao}
                  onChange={(e) => setSearchDescricao(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search"
                  style={{ width: 55, height: 45 }}
                  onClick={() =>
                    searchDescricao
                      ? searchByDescricao(searchDescricao)
                      : fetchData()
                  }
                  disabled={searching1}
                >
                  {searching1 ? (
                    <Spinner
                      animation="border"
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <SearchIcon
                      className="text-light btn-search"
                      style={{ marginTop: -5 }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="  text-center d-flex w-100 align-items-center justify-content-center">
              <div
                className="spinner-border text-primary"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {existentes ? (
                <div className="owl-carousel testimonial-carousel text-start">
                  {denuncias?.map((d) => (
                    <div
                      key={d.id}
                      className="testimonial-item bg-transparent border rounded p-4 mb-4"
                    >
                      <div className="d-flex align-items-center">
                        <i className="fa fa-quote-left fa-2x text-primary mb-3">
                          {" "}
                        </i>
                        <h5 className="ms-3 mb-1">{d.d_curta}</h5>
                      </div>

                      <p className="history-desc1" /* style={{maxWidth: "700px"}} */>
                        {d.d_longa}
                      </p>
                      <div className="d-flex align-items-center">
                        <a
                          href={
                            d.img_link === "Sem imagem" ||
                            d.img_link === "capa.png"
                              ? logoprov
                              : d.img_link
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={
                              d.img_link === "Sem imagem" ||
                              d.img_link === "capa.png"
                                ? logoprov
                                : d.img_link
                            }
                            alt={d.nome}
                            title={d.nome}
                            className="img-fluid flex-shrink-0 rounded-circle"
                            style={{ width: "80px", height: "80px" }}
                          />
                        </a>

                        <div className="ps-3 detalhes-nome">
                          <h5 className="mb-1" style={{ fontSize: 15 }}>
                            {d.nome}
                          </h5>
                          <NavLink to={"/detalhes/" + d.id}>
                            {" "}
                            <small className="text-primary detalhes">
                              {"  "} Mais detalhes
                            </small>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                  {/* <span>{props.nada}</span> */}
                  <span className="text-secondary">
                    Ups, nenhuma publicação para mostrar, sê o primeiro a
                    postar!
                  </span>{" "}
                  <div>
                    <img
                      src={nothing22}
                      alt=""
                      title="Nada para mostrar"
                      style={{ width: "50px" }}
                    />
                  </div>
                </div>
              )}

              {existentes1 && (
                <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                  {/* <span>{props.nada}</span> */}
                  <div>
                    <img
                      src={searchGif}
                      alt=""
                      title="Nada para mostrar"
                      style={{ width: "170px" }}
                    />
                  </div>
                  <span className="text-secondary">
                    Nenhum resultado encontrado!
                  </span>{" "}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div ref={myRef}></div>

      {/* ----------------------LISTA DE DENUNCIAS----------------------- */}
      {/* -------------- Contact Start --------------- */}
      <div
        className="container-xxl py-2 mt-5"
        hidden={usuarioLogado !== "" ? false : showForm}
      >
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-primary fw-normal ">
              Formulário
            </h5>
            <h3 className="mb-5">Pedir apoio para saúde</h3>
          </div>
          <div className="row g-4" style={{ marginTop: -50 }}>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <a href={hands1}>
                <img
                  src={hands1}
                  alt="Holding hands"
                  style={{ width: "100%", height: "100%", marginLeft: 0 }}
                />
              </a>
            </div>
            <div className="col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                <form onSubmit={formSubmit}>
                  <div className="row g-3">
                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="nome"
                          label="Nome Completo"
                          type={"text"}
                          id="nome"
                          value={nome}
                          defaultValue={nome}
                          error={errorNome}
                          helperText={errorNomeMessage}
                          onChange={(e) => setNome(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>

                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="morada"
                          label="Morada"
                          type={"text"}
                          id="morada"
                          value={morada}
                          defaultValue={morada}
                          error={errorMorada}
                          helperText={errorMoradaMessage}
                          onChange={(e) => setMorada(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="contacto"
                          label="Contacto"
                          type={"text"}
                          id="contacto"
                          value={contacto}
                          defaultValue={contacto}
                          error={errorContacto}
                          helperText={errorContactoMessage}
                          onChange={(e) => setContacto(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="contacto_op"
                          label="Contacto opcional"
                          type={"text"}
                          id="contacto_op"
                          value={contacto_op}
                          defaultValue={contacto_op}
                          error={errorContacto_op}
                          helperText={errorContacto_opMessage}
                          onChange={(e) => setContacto_op(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>

                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="apoio"
                          label="Breve descrição do problema"
                          type={"text"}
                          id="apoio"
                          value={apoio}
                          defaultValue={apoio}
                          error={errorDcurta}
                          helperText={errorDcurtaMessage}
                          onChange={(e) => setApoio(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>

                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="d_longa"
                          label="Conte-nos sua história..."
                          multiline
                          rows={3}
                          type={"text"}
                          id="d_longa"
                          value={d_longa}
                          defaultValue={d_longa}
                          error={errorDlonga}
                          helperText={errorDlongaMessage}
                          onChange={(e) => setD_longa(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>

                    <div className="col-12">
                      {/* <div className="form-floating">
                        <input
                          type="file"
                          alt="Clique para seleccionar uma foto"
                          className="form-control text-dark"
                          id="filename"
                          onChange={handleChangeImage}
                          style={{ backgroundColor: "white" }}
                        />
                        <label htmlFor="filename">
                          {" "}
                          Clique para seleccionar uma foto
                        </label>
                      </div> */}

                      <div className="row">
                        <div className="col-6">
                          <small id="file-chosen" style={{ marginTop: 30 }}>
                            {image1}
                          </small>
                        </div>
                        <div className="col-6 text-end">
                          <input
                            className="upload-photo"
                            id="contained-button-file"
                            multiple
                            onChange={handleChangeImage}
                            type="file"
                          />
                          <label htmlFor="contained-button-file">
                            <Button
                              style={{
                                textTransform: "capitalize",
                              }}
                              className="fw-bold text-center"
                              color="primary"
                              component="span"
                              variant="contained"
                              startIcon={<PhotoCameraIcon />}
                            >
                              {" "}
                              Escolher foto
                            </Button>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 justify-content-center d-flex ">
                      <Button
                        variant="contained"
                        className="me-1 bg-danger text-light fw-bold"
                        //startIcon={<SaveIcon />}
                        onClick={() => navigate(-1)}
                        style={{ width: "49%" }}
                      >
                        <Icon
                          className="me-2"
                          icon="icon-park-outline:back"
                          color="white"
                          width="20"
                          height="20"
                          inline={true}
                        />
                        Voltar
                      </Button>

                      <Button
                        variant="contained"
                        type="submit"
                        className="ms-1 bg-primary text-light fw-bold"
                        //startIcon={<SaveIcon />}
                        onClick={() => requiredFields()}
                        style={{ width: "49%" }}
                        disabled={loader}
                      >
                        <Icon
                          className="me-2"
                          icon="fluent:save-arrow-right-20-regular"
                          color="white"
                          width="24"
                          height="24"
                          inline={true}
                        />
                        {loader ? "A enviar..." : "Enviar"}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*--------- Contact End --------------- */}

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={mensagem}
        key={"bottom" + "center"}
        autoHideDuration={4000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />
      <Footer />
    </div>
  );
};

export default Formulario;
