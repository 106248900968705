import React, { useEffect, useState } from "react";
import nothing22 from "../img/eyes.gif";
import Appbar from "./appbar";
import saudee from "../img/saude.png";
import ideiass from "../img/ideias.png";
import estudoss from "../img/estudos.png";
import denunciass from "../img/denuncias.png";
import logoprov from "../img/logoprov.png";
import Footer from "./footer";
import { NavLink } from "react-router-dom";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Confirmacao from "./confirmarOperacao";
import { Icon } from "@iconify/react";
import { Snackbar } from "@material-ui/core";

export default function MeusPosts(props) {
  const [loading, setLoading] = useState(true);

  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  const [existentesDenuncias, setExistentesDenuncias] = useState(true);
  const [existentesSaude, setExistentesSaude] = useState(true);
  const [existentesEstudos, setExistentesEstudos] = useState(true);
  const [existentesIdeias, setExistentesIdeias] = useState(true);

  const [show1, setShow1] = useState(false);
  const closeModal1 = () => {
    setShow1(false);
  };

  const [estudos, setEstudos] = useState();

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Estudos",
        usuario: usuarioLogado.nome,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByUser", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setEstudos(data?.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        }));
        if (data?.length !== 0) {
          setExistentesEstudos(true);
        } else {
          setExistentesEstudos(false);
        }
      });
  }, []);

  const [ideias, setIdeias] = useState();

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Projecto",
        usuario: usuarioLogado.nome,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByUser", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIdeias(data?.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        }));
        setLoading(false);
        if (data?.length !== 0) {
          setExistentesIdeias(true);
        } else {
          setExistentesIdeias(false);
        }
      });
  }, []);

  const [saude, setSaude] = useState();

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        usuario: usuarioLogado.nome,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByUser", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setSaude(data?.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        }));
        if (data?.length !== 0) {
          setExistentesSaude(true);
        } else {
          setExistentesSaude(false);
        }
      });
  }, []);

  const [denuncias, setdenuncias] = useState();

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Denuncia",
        usuario: usuarioLogado.nome,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByUser", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setdenuncias(data?.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        }));
        if (data?.length !== 0) {
          setExistentesDenuncias(true);
        } else {
          setExistentesDenuncias(false);
        }
      });
  }, []);

  var snackState = JSON.parse(localStorage.getItem("eliminado"));
  const [open, setOpen] = useState(snackState);
  const handleClose = (event, reason) => {
    setOpen(false);
    localStorage.setItem("eliminado", JSON.stringify(false));
  };

  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Appbar />
        {/* -----------------BANNER - CAPPA--------------- */}
        <div className="container-fluid py-5 bg-dark hero-header-detalhes mb-5">
          <div className="container text-center my-5 pt-2">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Há esperança para si!
            </h1>
            <small>{usuarioLogado?.nome}</small>
          </div>
        </div>
      </div>
      <div className="container-xxl text-end">
        <div className="container">
          <div className="row g-4" style={{ marginTop: -50 }}>
            <div className="col-md-6 ">
              <div className="wow fadeInUp" data-wow-delay="0.2s"></div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------- CASOS REPORTADOS-------------- */}
      <div className="container-xxl ">
        <div className="container">
          <div
            className="tab-class text-center wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="tabCasos">
              <ul className="nav nav-pills d-inline-flex w-100 justify-content-center border-bottom mb-3 ">
                <li className="nav-item tab-casos w-25 ">
                  <a
                    className="d-flex align-items-center text-start mx-3 ms-0 pb-3 active"
                    data-bs-toggle="pill"
                    href="#tab-1"
                  >
                    <img src={denunciass} alt="Denúncias" width="40px" />
                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Denúncias</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos w-25">
                  <a
                    className="d-flex align-items-center text-start mx-3 ms-0 pb-3 "
                    data-bs-toggle="pill"
                    href="#tab-2"
                  >
                    <img src={saudee} alt="Saúde" width="40px" />

                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Saúde</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos w-25">
                  <a
                    className="d-flex align-items-center text-start mx-3 pb-3"
                    data-bs-toggle="pill"
                    href="#tab-3"
                  >
                    <img src={estudoss} alt="Estudos" width="40px" />

                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Estudos</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos w-25">
                  <a
                    className="d-flex align-items-center text-start mx-3 me-0 pb-3"
                    data-bs-toggle="pill"
                    href="#tab-4"
                  >
                    <img src={ideiass} alt="Ideias" width="40px" />

                    <div className="ps-3 pe-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Projectos</h6>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="tabCasos1">
              <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-3">
                <li className="nav-item tab-casos">
                  <a
                    className="d-flex align-items-center text-start mx-3 ms-0 pb-3 active"
                    data-bs-toggle="pill"
                    href="#tab-1"
                  >
                    <img src={denunciass} alt="Denúncias" width="40px" />
                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Denuncias</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos">
                  <a
                    className="d-flex align-items-center text-start mx-3 ms-0 pb-3 "
                    data-bs-toggle="pill"
                    href="#tab-2"
                  >
                    <img src={saudee} alt="Saúde" width="40px" />

                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Saúde</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos">
                  <a
                    className="d-flex align-items-center text-start pb-3"
                    data-bs-toggle="pill"
                    href="#tab-3"
                  >
                    <img src={estudoss} alt="Estudos" width="40px" />

                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Estudos</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos">
                  <a
                    className="d-flex align-items-center text-start mx-3 me-0 pb-3"
                    data-bs-toggle="pill"
                    href="#tab-4"
                  >
                    <img src={ideiass} alt="Ideias" width="40px" />

                    <div className="ps-3 pe-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Projectos</h6>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            {loading ? (
              <div className="  text-center d-flex w-100 align-items-center justify-content-center">
                <div
                  className="spinner-border text-primary"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                  {existentesDenuncias ? (
                    <div className="row g-4">
                      {denuncias?.map((d) => (
                        <div key={d.id} className="col-lg-6">
                          <div className="d-flex align-items-center">
                            <a
                              href={
                                d.img_link === "Sem imagem" ||
                                d.img_link === "capa.png"
                                  ? logoprov
                                  : d.img_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  d.img_link === "Sem imagem" ||
                                  d.img_link === "capa.png"
                                    ? logoprov
                                    : d.img_link
                                }
                                alt={d.nome}
                                title={d.nome}
                                className="img-fluid flex-shrink-0 rounded-circle"
                                style={{ width: "80px", height: "80px" }}
                              />
                            </a>

                            <div className="w-100 d-flex flex-column text-start ps-4">
                              <h5 className="d-flex justify-content-between border-bottom pb-2">
                                <span>{d.nome}</span>

                                <span>
                                  {d.estado === "Pendente" && (
                                    <small>
                                      <span
                                        className="text-warning"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Aprovado" && (
                                    <small>
                                      <span
                                        className="text-success"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Rejeitado" && (
                                    <small>
                                      <span
                                        className="text-danger"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Fechado" && (
                                    <small>
                                      <span
                                        className="text-dark"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}
                                </span>
                              </h5>
                              <small className=" d-flex justify-content-between ">
                                {d.d_curta}
                                <NavLink to={"/detalhesMeus/" + d.id}>
                                  <span
                                    style={{
                                      color: "inherit",
                                    }}
                                    className="detalhes2 text-primary"
                                  >
                                    Detalhes
                                  </span>
                                </NavLink>
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                  ) : (
                    <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                      <div>
                        <img
                          src={nothing22}
                          alt=""
                          title="Nada para mostrar"
                          style={{ width: "100px" }}
                        />
                      </div>
                      {/* <span>{props.nada}</span> */}
                      <span className="text-dark">
                        Ups, você ainda não postou denúncias!
                      </span>{" "}
                      <br />
                      <NavLink
                        to="/formulario"
                        className="text-decoration-underline"
                      >
                        Denunciar
                      </NavLink>
                    </div>
                  )}
                </div>
                <div id="tab-2" className="tab-pane fade show p-0">
                  {existentesSaude ? (
                    <div className="row g-4">
                      {saude?.map((d) => (
                        <div key={d.id} className="col-lg-6">
                          <div className="d-flex align-items-center">
                            <a
                              href={
                                d.img_link === "Sem imagem" ||
                                d.img_link === "capa.png"
                                  ? logoprov
                                  : d.img_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  d.img_link === "Sem imagem" ||
                                  d.img_link === "capa.png"
                                    ? logoprov
                                    : d.img_link
                                }
                                alt={d.nome}
                                title={d.nome}
                                className="img-fluid flex-shrink-0 rounded-circle"
                                style={{ width: "80px", height: "80px" }}
                              />
                            </a>

                            <div className="w-100 d-flex flex-column text-start ps-4">
                              <h5 className="d-flex justify-content-between border-bottom pb-2">
                                <span>{d.nome}</span>

                                <span>
                                  {d.estado === "Pendente" && (
                                    <small>
                                      <span
                                        className="text-warning"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Aprovado" && (
                                    <small>
                                      <span
                                        className="text-success"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Rejeitado" && (
                                    <small>
                                      <span
                                        className="text-danger"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Fechado" && (
                                    <small>
                                      <span
                                        className="text-dark"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}
                                </span>
                              </h5>
                              <small className=" d-flex justify-content-between ">
                                {d.d_curta}
                                <NavLink to={"/detalhesMeus/" + d.id}>
                                  <span
                                    style={{
                                      color: "inherit",
                                    }}
                                    className="detalhes2 text-primary"
                                  >
                                    Detalhes
                                  </span>
                                </NavLink>
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                  ) : (
                    <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                      <div>
                        <img
                          src={nothing22}
                          alt=""
                          title="Nada para mostrar"
                          style={{ width: "100px" }}
                        />
                      </div>
                      {/* <span>{props.nada}</span> */}
                      <span className="text-dark">
                        Ups, você ainda não postou pedidos para saúde!
                      </span>{" "}
                      <br />
                      <NavLink
                        to="/formSaude"
                        className="text-decoration-underline"
                      >
                        Fazer pedido
                      </NavLink>
                    </div>
                  )}
                </div>
                <div id="tab-3" className="tab-pane fade show p-0">
                  {existentesEstudos ? (
                    <div className="row g-4">
                      {estudos?.map((d) => (
                        <div key={d.id} className="col-lg-6">
                          <div className="d-flex align-items-center">
                            <a
                              href={
                                d.img_link === "Sem imagem" ||
                                d.img_link === "capa.png"
                                  ? logoprov
                                  : d.img_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  d.img_link === "Sem imagem" ||
                                  d.img_link === "capa.png"
                                    ? logoprov
                                    : d.img_link
                                }
                                alt={d.nome}
                                title={d.nome}
                                className="img-fluid flex-shrink-0 rounded-circle"
                                style={{ width: "80px", height: "80px" }}
                              />
                            </a>
                            <div className="w-100 d-flex flex-column text-start ps-4">
                              <h5 className="d-flex justify-content-between border-bottom pb-2">
                                <span>{d.nome}</span>

                                <span>
                                  {d.estado === "Pendente" && (
                                    <small>
                                      <span
                                        className="text-warning"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Aprovado" && (
                                    <small>
                                      <span
                                        className="text-success"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Rejeitado" && (
                                    <small>
                                      <span
                                        className="text-danger"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Fechado" && (
                                    <small>
                                      <span
                                        className="text-dark"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}
                                </span>
                              </h5>
                              <small className=" d-flex justify-content-between ">
                                {d.d_curta}
                                <NavLink to={"/detalhesMeus/" + d.id}>
                                  <span
                                    style={{
                                      color: "inherit",
                                    }}
                                    className="detalhes2 text-primary"
                                  >
                                    Detalhes
                                  </span>
                                </NavLink>
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                  ) : (
                    <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                      <div>
                        <img
                          src={nothing22}
                          alt=""
                          title="Nada para mostrar"
                          style={{ width: "100px" }}
                        />
                      </div>
                      {/* <span>{props.nada}</span> */}
                      <span className="text-dark">
                        Ups, você ainda não postou pedidos para estudos!
                      </span>{" "}
                      <br />
                      <NavLink
                        to="/formEstudos"
                        className="text-decoration-underline"
                      >
                        Fazer pedido
                      </NavLink>
                    </div>
                  )}
                </div>
                <div id="tab-4" className="tab-pane fade show p-0">
                  {existentesIdeias ? (
                    <div className="row g-4">
                      {ideias?.map((d) => (
                        <div key={d.id} className="col-lg-6">
                          <div className="d-flex align-items-center">
                            <a
                              href={
                                d.img_link === "Sem imagem" ||
                                d.img_link === "capa.png"
                                  ? logoprov
                                  : d.img_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  d.img_link === "Sem imagem" ||
                                  d.img_link === "capa.png"
                                    ? logoprov
                                    : d.img_link
                                }
                                alt={d.nome}
                                title={d.nome}
                                className="img-fluid flex-shrink-0 rounded-circle"
                                style={{ width: "80px", height: "80px" }}
                              />
                            </a>

                            <div className="w-100 d-flex flex-column text-start ps-4">
                              <h5 className="d-flex justify-content-between border-bottom pb-2">
                                <span>{d.nome}</span>

                                <span>
                                  {d.estado === "Pendente" && (
                                    <small>
                                      <span
                                        className="text-warning"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Aprovado" && (
                                    <small>
                                      <span
                                        className="text-success"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Rejeitado" && (
                                    <small>
                                      <span
                                        className="text-danger"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}

                                  {d.estado === "Fechado" && (
                                    <small>
                                      <span
                                        className="text-dark"
                                        onClick={() => setShow1(true)}
                                        style={{
                                          fontSize: 14,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {d.estado}
                                      </span>
                                    </small>
                                  )}
                                </span>
                              </h5>
                              <small className=" d-flex justify-content-between ">
                                {d.d_curta}
                                <NavLink to={"/detalhesMeus/" + d.id}>
                                  <span
                                    style={{
                                      color: "inherit",
                                    }}
                                    className="detalhes2 text-primary"
                                  >
                                    Detalhes
                                  </span>
                                </NavLink>
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                  ) : (
                    <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                      <div>
                        <img
                          src={nothing22}
                          alt=""
                          title="Nada para mostrar"
                          style={{ width: "100px" }}
                        />
                      </div>
                      {/* <span>{props.nada}</span> */}
                      <span className="text-dark">
                        Ups, você ainda não postou ideias!
                      </span>{" "}
                      <br />
                      <NavLink
                        to="/formIdeias"
                        className="text-decoration-underline"
                      >
                        Pedir apoio
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* ---------------- CASOS REPORTADOS -------------- */}

      {/* ------- ------- FOOTER ------- ------- */}
      <Footer />
      {/* ------- ------- FOOTER ------- ------- */}

      {/*  <BackTop /> */}

      <Confirmacao
        show={show1}
        closeModal={closeModal1}
        confirmar={"Confirmar"}
        iconn={
          <Icon
            icon="eva:done-all-outline"
            color="white"
            width="24"
            height="24"
            inline={true}
          />
        }
        icon={
          <Icon
            className="me-2"
            icon="emojione:warning"
            width="23"
            height="23"
            inline={true}
          />
        }
        titulo="Tem certeza que pretende fechar este pedido?"
        descricao={
          "Ao confirmar esta operação, o seu pedido não estará mais visível ao público!"
        }
      />

      <Snackbar
        key={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          <>
            <DoneAllIcon className="me-1 text-primary" />O seu post foi
            eliminado com sucesso!
          </>
        }
      />
    </div>
  );
}
