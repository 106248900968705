import React, { useEffect } from "react";
import academia from "../img/academia.png";
import mailgif from "../img/media/mailgif22.gif";
import phonegif from "../img/media/phonegif.gif";
import whatsgif22 from "../img/media/whatsgif22.gif";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useNavigate, NavLink } from "react-router-dom";
import Confirmacao from "./confirmarOperacao";
import { useState } from "react";
import { Icon } from "@iconify/react";
import {
  Badge,
  Button,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
  withStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Modal, Spinner } from "react-bootstrap";
import { CloseButton } from "react-bootstrap";
import SnackBar from "./snackbar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f50b5", //your color
    },
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -14,
    top: 8,
    padding: "0 4px",
    cursor: "pointer",
  },
}))(Badge);

export default function Footer(props) {
  const navigate = useNavigate();

  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };
  const closeModal1 = () => {
    setShow(false);
    navigate("/home");
    localStorage.setItem("userLogado", JSON.stringify(""));
    localStorage.setItem("isAuthenticated", false);
  };

  const currentURL = window.location.href;
  const isLogin = currentURL.split("/").pop();

  const [facebook1, setFacebook1] = useState();
  const [instagram1, setInstagram1] = useState();
  const [twitter1, setTwitter1] = useState();
  const [youtube1, setYoutube1] = useState();
  const [telefone1, setTelefone1] = useState();
  const [whatsapp1, setWhatsapp1] = useState();
  const [linkedin1, setLinkedin1] = useState();
  const [horario1, setHorario1] = useState();
  const [horario2, setHorario2] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/1`)
      .then((response) => response.json())
      .then((data) => {
        setFacebook1(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/2`)
      .then((response) => response.json())
      .then((data) => {
        setInstagram1(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/3`)
      .then((response) => response.json())
      .then((data) => {
        setTwitter1(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/4`)
      .then((response) => response.json())
      .then((data) => {
        setYoutube1(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/6`)
      .then((response) => response.json())
      .then((data) => {
        setTelefone1(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/7`)
      .then((response) => response.json())
      .then((data) => {
        setWhatsapp1(data.data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/8`)
      .then((response) => response.json())
      .then((data) => {
        setLinkedin1(data.data);
      });

    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/9`)
      .then((response) => response.json())
      .then((data) => {
        setHorario1(data.data);
      });

    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/10`)
      .then((response) => response.json())
      .then((data) => {
        setHorario2(data.data);
      });
  }, []);

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [loader2, setLoader2] = useState(false);
  const [mediaID, setMediaID] = useState();
  const [nome, setNome] = useState();
  const [mediaLink, setMediaLink] = useState();

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorNome === false && errorLink === false) {
      editar();
    } else {
      console.log("N");
    }
  };

  const dados = {
    nome: nome,
    mediaLink: mediaLink,
  };

  const editar = () => {
    setLoader2(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: mediaID,
        nome: dados.nome,
        descricao: dados.mediaLink,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/links/edit", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/9`)
          .then((response) => response.json())
          .then((data) => {
            setHorario1(data.data);
            setLoader2(false);
            setShowEdit(false);
            setOpen(true);
          });
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorLink, setErrorLink] = useState(false);
  const [errorLinkMessage, setErrorLinkMessage] = useState("");

  const requiredFields = () => {
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }

    if (mediaLink === "" || mediaLink === undefined) {
      setErrorLink(true);
      setErrorLinkMessage("Por favor, preencha este campo!");
    } else {
      setErrorLink(false);
      setErrorLinkMessage(true);
    }
  };

  const [showEdit, setShowEdit] = useState(false);
  const [iconModal, setIconModal] = useState("");

  const openModalEdit = (id, nome, mediaLink) => {
    setMediaID(id);
    setNome(nome);
    setMediaLink(mediaLink);
    setShowEdit(true);
  };

  const [showContacts, setShowContacts] = useState(false);

  return (
    <div>
      {/* ------- ------- FOOTER ------- ------- */}
      <div
        className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn text-start"
        data-wow-delay="0.1s"
      >
        <div className="footer-container">
          <div className="container py-0 pb-1">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6">
                <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
                  Hope
                </h4>
                <span
                  className="btn btn-link"
                  onClick={() => navigate("/sobre")}
                >
                  Sobre nós
                </span>

                <span
                  className="btn btn-link"
                  onClick={() => setShowContacts(true)}
                >
                  Contacte nos
                </span>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
                  Contacto
                </h4>

                <a
                  href={
                    "https://www.google.com/maps/place/Academia+Virtual+de+Pesquisa/@-25.9608233,32.5768422,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x35cdf8c3d182c84e!8m2!3d-25.9608233!4d32.5790309?hl=pt-PT"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="mb-2 text-light mail-to d-block"
                >
                  <i className="fa fa-map-marker-alt me-3"></i>
                  Bairro da Coop, Rua D, Nr 6 - Maputo
                </a>

                {/* <a
                href="https://wa.me/827890800"
                target="_blank"
                rel="noreferrer"
                className="mb-2 text-light mail-to d-block"
              >
                <WhatsAppIcon className="me-2" style={{ fontSize: 20 }} />{" "}
                +(258) 82 789 0800
              </a> */}

                <span hidden={telefone1?.descricao === "-"}>
                  <a
                    href={"tel:" + telefone1?.descricao}
                    className="mb-2 text-light mail-to d-block"
                    hidden
                  >
                    <i className="fa fa-phone-alt me-3"></i>
                    {telefone1?.descricao}
                  </a>
                </span>

                <a
                  href="mailto:info@academiavirtual.ac.mz"
                  className="mb-2 text-light mail-to"
                >
                  <i className="fa fa-envelope me-3"></i>
                  info@academiavirtual.ac.mz
                </a>
                <div className="d-flex pt-3">
                  <span hidden={whatsapp1?.descricao === "-"}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-outline-light btn-social"
                      href={"https://wa.me/" + whatsapp1?.descricao}
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </span>
                  <span hidden={instagram1?.descricao === "-"}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-outline-light btn-social"
                      href={instagram1?.descricao}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </span>
                  <span hidden={facebook1?.descricao === "-"}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-outline-light btn-social"
                      href={facebook1?.descricao}
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </span>
                  <span hidden={twitter1?.descricao === "-"}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-outline-light btn-social"
                      href={twitter1?.descricao}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </span>
                  <span hidden={youtube1?.descricao === "-"}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-outline-light btn-social"
                      href={youtube1?.descricao}
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </span>
                  <span hidden={linkedin1?.descricao === "-"}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-outline-light btn-social"
                      href={linkedin1?.descricao}
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
                  Abertos
                </h4>

                {usuarioLogado !== "" ? (
                  <>
                    {usuarioLogado?.tipo !== "Normal" ? (
                      <>
                        <h5 className="text-light fw-normal">
                          {horario1?.nome}
                        </h5>
                        <StyledBadge
                          badgeContent={<EditIcon />}
                          color=""
                          onClick={() => {
                            openModalEdit(
                              horario1?.id,
                              horario1?.nome,
                              horario1?.descricao
                            );
                            setIconModal(
                              <i className="bi bi-clock-history me-3"></i>
                            );
                          }}
                        >
                          <p>
                            <i className="bi bi-clock-history me-3"></i>
                            {horario1?.descricao} {"  "}
                          </p>
                        </StyledBadge>

                        <h5 className="text-light fw-normal">
                          {horario2?.nome}
                        </h5>

                        <StyledBadge
                          badgeContent={<EditIcon />}
                          color=""
                          onClick={() => {
                            openModalEdit(
                              horario2?.id,
                              horario2?.nome,
                              horario2?.descricao
                            );
                            setIconModal(
                              <i className="bi bi-dash-circle me-3"></i>
                            );
                          }}
                        >
                          <p>
                            <i className="bi bi-dash-circle me-3"></i>
                            {horario2?.descricao} {"  "}
                          </p>
                        </StyledBadge>
                      </>
                    ) : (
                      <>
                        <h5 className="text-light fw-normal">
                          {horario1?.nome}
                        </h5>

                        <p>
                          <i className="bi bi-clock-history me-3"></i>
                          {horario1?.descricao} {"  "}
                        </p>

                        <h5 className="text-light fw-normal">
                          {horario2?.nome}
                        </h5>

                        <p>
                          <i className="bi bi-dash-circle me-3"></i>
                          {horario2?.descricao} {"  "}
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <h5 className="text-light fw-normal">{horario1?.nome}</h5>

                    <p>
                      <i className="bi bi-clock-history me-3"></i>
                      {horario1?.descricao} {"  "}
                    </p>

                    <h5 className="text-light fw-normal">{horario2?.nome}</h5>

                    <p>
                      <i className="bi bi-dash-circle me-3"></i>
                      {horario2?.descricao} {"  "}
                    </p>
                  </>
                )}
              </div>
              <div className="col-lg-3 col-md-6 text-center pb-2">
                <span className="text-start text-primary mb-1">
                  <img
                    src={academia}
                    alt="Academia de Pesquisa Virtual"
                    title="Academia de Pesquisa Virtual"
                    className="academia-logo"
                    //style={{ width: "50px", height: "50px" }}
                  />
                </span>

                {usuarioLogado !== "" ? (
                  <Button
                    variant="contained"
                    type="submit"
                    className="fw-bold bg-primary py-2 px-4 contact-us text-light mt-2"
                    style={{ width: "200px" }}
                    onClick={() => setShow(true)}
                  >
                    Terminar sessão
                  </Button>
                ) : (
                  <div className="ps-3 mt-2">
                    <Button
                      hidden={isLogin === "registar"}
                      variant="contained"
                      type="submit"
                      className="fw-bold bg-primary py-2 px-4 contact-us text-light"
                      style={{ width: "140px" }}
                      onClick={() => navigate("/registar")}
                    >
                      Registe-se
                    </Button>
                    <br />

                    <Button
                      hidden={isLogin === "login"}
                      variant="contained"
                      type="submit"
                      className="fw-bold bg-primary py-2 px-4 contact-us text-light mt-3"
                      style={{ width: "140px" }}
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              <div className="row">
                <div className="col-md-6 text-center text-md-start mb-0 mb-md-0">
                  &copy; <span className="border-bottom">hopefortheworld</span>
                  <br />
                  Todos direitos reservados. <br />
                  {/* <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***--> */}
                  Desenvolvido por{" "}
                  {/* <a
                  className="border-bottom mail-to"
                  href="https://academiavirtual.ac.mz"
                  target="_blank"
                  rel="noreferrer"
                > */}
                  {"<KX LABS/>"}
                  {/* </a> */}
                  <br />
                  Uma iniciativa da{" "}
                  <a
                    className="border-bottom mail-to"
                    href="https://academiavirtual.ac.mz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Academia de Pesquisa Virtual
                  </a>
                </div>
                {/* {usuarioLogado.tipo === "Normal" ? ( */}
                <div className="col-md-6 text-center text-md-end pt-4">
                  <div className="footer-menu">
                    <NavLink to="/formulario" className="footerMenu">
                      Denúncias
                    </NavLink>
                    <NavLink to="/formSaude" className="footerMenu">
                      Saúde
                    </NavLink>
                    <NavLink to="/formEstudos" className="footerMenu">
                      Estudos
                    </NavLink>
                    <NavLink to="/formIdeias" className="footerMenu">
                      Ideias
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showEdit}
        style={{ marginTop: 0 }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-dark">
          <Modal.Title>
            {" "}
            <span className="text-light" style={{ fontSize: 21 }}>
              <EditIcon /> {"   "}
              Editar link Horário
            </span>{" "}
          </Modal.Title>
          <CloseButton variant="white" onClick={() => setShowEdit(false)} />
        </Modal.Header>
        <Modal.Body>
          <div className="row g-4  div-detalhes mt-1">
            <form onSubmit={formSubmit}>
              <div className="row g-1">
                <div className="col-md-12">
                  <ThemeProvider theme={theme}>
                    <TextField
                      variant="outlined"
                      size={"small"}
                      fullWidth
                      name="nome"
                      label="Nome da rede"
                      className="mb-4"
                      type={"text"}
                      id="nome"
                      value={nome}
                      defaultValue={nome}
                      error={errorNome}
                      helperText={errorNomeMessage}
                      onChange={(e) => setNome(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {iconModal}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ThemeProvider>
                </div>
                <div className="col-md-12">
                  <ThemeProvider theme={theme}>
                    <TextField
                      variant="outlined"
                      size={"small"}
                      fullWidth
                      name="mediaLink"
                      label="Link"
                      className="mb-4"
                      type={"text"}
                      value={mediaLink}
                      defaultValue={mediaLink}
                      error={errorLink}
                      helperText={errorLinkMessage}
                      onChange={(e) => setMediaLink(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon
                              icon="akar-icons:link-chain"
                              width="24"
                              height="24"
                              inline={true}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ThemeProvider>
                </div>
                <div className="col-md-12 text-end">
                  <div className="">
                    <Button
                      style={{ color: "white", textTransform: "capitalize" }}
                      onClick={() => setShowEdit(false)}
                      className="bg-danger me-3 fw-bold"
                    >
                      <Icon
                        icon="iconoir:cancel"
                        color="white"
                        width="22"
                        height="22"
                        inline={true}
                      />
                      Cancelar
                    </Button>
                    <Button
                      style={{ color: "white", textTransform: "capitalize" }}
                      className="text-light bg-dark fw-bold"
                      type="submit"
                      onClick={() => requiredFields()}
                      disabled={loader2}
                    >
                      {loader2 ? (
                        <Spinner
                          animation="border"
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <Icon
                          className="me-2 text-light"
                          icon="fluent:save-arrow-right-20-regular"
                          width="24"
                          height="24"
                          inline={true}
                        />
                      )}
                      Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showContacts}
        style={{ marginTop: 0 }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-light">
          <Modal.Title>
            {" "}
            <span className="text-dark" style={{ fontSize: 21 }}>
              Entre em contacto connosco
            </span>{" "}
          </Modal.Title>
          <CloseButton variant="grey" onClick={() => setShowContacts(false)} />
        </Modal.Header>
        <Modal.Body>
          <small>Escolha um dos meios:</small>
          <div className="w-100 d-flex justify-content-center">
            <span style={{ width: "30%" }} className="p-4">
              <a href="mailto:info@academiavirtual.ac.mz">
                <img
                  src={mailgif}
                  alt="Enviar email"
                  title="Enviar email"
                  className="academia-logo"
                />
              </a>
            </span>

            <span
              style={{ width: "30%" }}
              className="p-3"
              hidden={whatsapp1?.descricao === "-"}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://wa.me/" + whatsapp1?.descricao}
              >
                <img
                  src={whatsgif22}
                  alt="Enviar whatsapp"
                  title="Enviar whatsapp"
                  className="academia-logo"
                />
              </a>
            </span>

            <span
              style={{ width: "30%" }}
              className="p-2"
              hidden={telefone1?.descricao === "-"}
            >
              <a href={"tel:" + telefone1?.descricao}>
                <img
                  src={phonegif}
                  alt="Ligar para academia"
                  title="Ligar para academia"
                  className="academia-logo"
                />
              </a>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Confirmacao
        show={show}
        closeModal={closeModal}
        closeModal1={closeModal1}
        confirmar={"Confirmar"}
        iconn={
          <Icon
            icon="eva:done-all-outline"
            color="white"
            width="24"
            height="24"
            inline={true}
          />
        }
        icon={<ExitToAppIcon width="23" height="23" color="text-danger" />}
        titulo="Terminar sessão"
        descricao={"Tem certeza que pretende terminar sessão?"}
        color1="text-danger"
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={"Horário actualizado com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />
      {/* ------- ------- FOOTER ------- ------- */}
    </div>
  );
}
