import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./footer";
import {
  Button,
  Collapse,
  Container,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  ThemeProvider,
  createTheme,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FilterListIcon from "@material-ui/icons/FilterList";
import BlockIcon from "@material-ui/icons/Block";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Sidebar from "./appbarAdmin";
import SnackBar from "./snackbar";
import { Spinner } from "react-bootstrap";
import Confirmacao from "./confirmarOperacao";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import nothing22 from "../img/box32.png";
import searchGif from "../img/searchGif.gif";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});
const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(11),
    backgroundColor: "",
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
}));

export default function Utilizadores(props) {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  const classes = useStyles();
  const [openSide, setOpenSide] = useState(false);
  const [loader, setLoader] = useState(false);

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [utilizadores, setUtilizadores] = useState();
  const [utilizadoresCount, setUtilizadoresCount] = useState();
  const [utilizadoresNormal, setUtilizadoresNormal] = useState();

  const fetchData = () => {
    setLoading(true);
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/filterByTipo/Administrador")
      .then((response) => response.json())
      .then((data) => {
        setUtilizadores(data);
        setLoading(false);
        if (data.length !== 0) {
          setExistentes(true);
        } else {
          setExistentes(false);
        }
      });
  };

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetchData();
  }, []);

  useEffect(() => {
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/countAll`)
      .then((response) => response.json())
      .then((data) => {
        //console.log("Utilizadores", data);
        setUtilizadoresCount(data);
      });
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/countByTipo/Normal`)
      .then((response) => response.json())
      .then((data) => {
        //console.log("Utilizadores", data);
        setUtilizadoresNormal(data);
      });

    return;
  }, []);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [nome, setNome] = useState();
  const [nomeModal, setNomeModal] = useState();
  const [userID, setUserID] = useState();
  const [username, setUsername] = useState();
  const [senha, setSenha] = useState();
  const [email, setEmail] = useState();
  const [contacto, setContacto] = useState();
  const [tipo, setTipo] = useState();
  const [mensagem, setMensagem] = useState();
  const [snackColor, setSnackColor] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [existentes, setExistentes] = useState(true);
  const [existentes1, setExistentes1] = useState(false);

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorUsername, setErrorUsername] = useState(false);
  const [errorUsernameMessage, setErrorUsernameMessage] = useState("");

  const [errorSenha, setErrorSenha] = useState(false);
  const [errorSenhaMessage, setErrorSenhaMessage] = useState(
    "*Pelo menos 6 digitos"
  );

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorContacto, setErrorContacto] = useState(false);
  const [errorContactoMessage, setErrorContactoMessage] = useState("");

  const [errorTipo, setErrorTipo] = useState(false);
  const [errorTipoMessage, setErrorTipoMessage] = useState("");

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const handleChangeTipo = (event) => {
    setTipo(event.target.value);
  };

  const dados = {
    nome: nome,
    senha: senha,
    email: email,
    username: username,
    contacto: contacto,
    tipo: tipo,
    estado: "Activo",
  };

  const cleanFields = () => {
    setNome("");
    setSenha("");
    setEmail("");
    setUsername("");
    setContacto("");
    setTipo("");
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorUsername === false &&
      errorSenha === false &&
      errorContacto === false &&
      errorEmail === false &&
      errorTipo === false
    ) {
      salvar();
    } else {
      console.log("N");
    }
  };

  const requiredFields = () => {
    const re = /^[0-9\b]+$/;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (username === "" || username === undefined) {
      setErrorUsername(true);
      setErrorUsernameMessage("Por favor, preencha este campo!");
    } else {
      setErrorUsername(false);
      setErrorUsernameMessage(true);
    }
    if (senha === "" || senha === undefined || senha?.length < 6) {
      setErrorSenha(true);
      setErrorSenhaMessage("Senha inválida, pelo menos 6 dígitos!");
    } else {
      setErrorSenha(false);
      setErrorSenhaMessage(true);
    }
    if (
      contacto === "" ||
      contacto?.length < 9 ||
      contacto === undefined ||
      !contacto?.match(re)
    ) {
      setErrorContacto(true);
      setErrorContactoMessage(
        "Número de telefone inválido, campo obrigatório!"
      );
    } else {
      setErrorContacto(false);
      setErrorContactoMessage(true);
    }
    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }

    if (tipo === "" || tipo === undefined) {
      setErrorTipo(true);
      setErrorTipoMessage("Por favor, preencha este campo!");
    } else {
      setErrorTipo(false);
      setErrorTipoMessage(true);
    }
  };

  const salvar = () => {
    //console.log(dados);
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        nome: dados.nome,
        contacto: dados.contacto,
        email: dados.email,
        usuario: dados.username,
        img_link: "Sem imagem",
        senha: dados.senha,
        tipo: dados.tipo,
        estado: dados.estado,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 100) {
          setMensagem(
            "Usuário ou email já foi registado. Tente novamente, por favor!"
          );
          setSnackColor("error");
          setOpen(true);
          setLoader(false);
        } else {
          fetch(
            "https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/filterByTipo/Administrador"
          )
            .then((response) => response.json())
            .then((data) => {
              saveEvent(
                usuarioLogado.usuario,
                "Registo",
                `Registou o utilizador "${dados.nome}".`
              );
              setUtilizadores(data);
              cleanFields();
              setLoader(false);
              setMensagem("Utilizador registado com sucesso!");
              setSnackColor("success");
              setOpen(true);
            });
        }
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [blocking, setBlocking] = useState(false);
  const [showBlock, setShowBlock] = useState(false);

  const openModalBlock = (id, nome) => {
    setUserID(id);
    setNomeModal(nome);
    setShowBlock(true);
  };
  const closeModalBlock = () => {
    setShowBlock(false);
  };

  const [blocking1, setBlocking1] = useState(false);
  const [showBlock1, setShowBlock1] = useState(false);

  const openModalBlock1 = (id, nome) => {
    setUserID(id);
    setNomeModal(nome);
    setShowBlock1(true);
  };
  const closeModalBlock1 = () => {
    setShowBlock1(false);
  };

  const bloquear = () => {
    setBlocking(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: userID,
        estado: "Bloqueado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/mudarEstado", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(
          "https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/filterByTipo/Administrador"
        )
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Bloqueio",
              `Bloqueou o utilizador "${nomeModal}".`
            );
            setUtilizadores(data);
            setBlocking(false);
            setShowBlock(false);
            setOpen(true);
            setMensagem("Utilizador bloqueado com sucesso!");
          });
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const desbloquear = () => {
    setBlocking1(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: userID,
        estado: "Activo",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/mudarEstado", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(
          "https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/filterByTipo/Administrador"
        )
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Desbloqueio",
              `Desbloqueou o utilizador "${nomeModal}".`
            );
            setUtilizadores(data);
            setBlocking1(false);
            setShowBlock1(false);
            setOpen(true);
            setMensagem("Utilizador desbloqueado com sucesso!");
          });
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [showDelete, setShowDelete] = useState(false);

  const openModalDelete = (id, nome) => {
    setUserID(id);
    setNomeModal(nome);
    setShowDelete(true);
  };
  const closeModalDelete = () => {
    setShowDelete(false);
  };

  const [deleting, setDeleting] = useState(false);

  const apagar = () => {
    setDeleting(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(
      `https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/delete/${userID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        fetch(
          "https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/filterByTipo/Administrador"
        )
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Eliminação",
              `Eliminou o utilizador "${nomeModal}".`
            );
            setUtilizadores(data);
            setDeleting(false);
            setShowDelete(false);
            setOpen(true);
            setMensagem("Utilizador eliminado com sucesso!");
          });
      });
  };

  const [searching, setSearching] = useState(false);
  const [searchNome, setSearchNome] = useState();

  const searchByNome = (nome) => {
    setSearching(true);
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/searchByName/${nome}`)
      .then((response) => response.json())
      .then((data) => {
        setUtilizadores(data);
        setSearching(false);
        if (data.length === 0) {
          setExistentes1(true);
        } else {
          setExistentes1(false);
        }
      });
  };

  const saveEvent = (user, event, description) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: event,
        descricao: description,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        <div className="container-xxl mt-2">
          <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
            <h3 className="section-title ff-secondary text-center text-primary fw-normal ">
              Gestão de utilizadores
            </h3>
            <h4 className="mb-3">Academia</h4>
          </div>
          <div className="row g-4">
            {/* -------------------FORMULÁRIO DE REGISTO------------- */}
            <div className="col-md-9">
              <Paper elevation={1} className="p-4">
                <form onSubmit={formSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            size="small"
                            margin="normal"
                            fullWidth
                            name="nome"
                            label="Nome Completo"
                            type={"text"}
                            id="nome"
                            value={nome}
                            defaultValue={nome}
                            error={errorNome}
                            helperText={errorNomeMessage}
                            onChange={(e) => setNome(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          size="small"
                          fullWidth
                          name="username"
                          label="Username"
                          type={"text"}
                          id="username"
                          value={username}
                          defaultValue={username}
                          error={errorUsername}
                          helperText={errorUsernameMessage}
                          onChange={(e) => setUsername(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon style={{ color: "#EA8B0F" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          size="small"
                          margin="normal"
                          fullWidth
                          name="email"
                          label="Email"
                          type={"text"}
                          id="email"
                          value={email}
                          defaultValue={email}
                          error={errorEmail}
                          helperText={errorEmailMessage}
                          onChange={(e) => setEmail(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon style={{ color: "#EA8B0F" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          size="small"
                          margin="normal"
                          fullWidth
                          name="contacto"
                          label="Contacto"
                          type={"text"}
                          id="contacto"
                          value={contacto}
                          defaultValue={contacto}
                          error={errorContacto}
                          helperText={errorContactoMessage}
                          onChange={(e) => setContacto(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CallIcon style={{ color: "#EA8B0F" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          size="small"
                          margin="normal"
                          fullWidth
                          name="senha"
                          label="Senha"
                          type={showPassword ? "text" : "password"}
                          id="senha"
                          value={senha}
                          defaultValue={senha}
                          error={errorSenha}
                          helperText={errorSenhaMessage}
                          onChange={(e) => setSenha(e.target.value)}
                          autoComplete="current-password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon style={{ color: "#EA8B0F" }} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </div>

                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          select
                          id="outlined-basic"
                          variant="outlined"
                          label="Nível de utilizador"
                          className="mt-3"
                          size="small"
                          style={{ width: "100%" }}
                          value={tipo}
                          error={errorTipo}
                          helperText={errorTipoMessage}
                          onChange={handleChangeTipo}
                        >
                          <MenuItem disabled> Seleccione o nível</MenuItem>
                          <MenuItem value="Super Administrador">
                            Super Administrador
                          </MenuItem>
                          <MenuItem value="Administrador">
                            Administrador
                          </MenuItem>
                        </TextField>
                      </ThemeProvider>
                    </div>

                    <div className="col-12 text-end">
                      <Button
                        variant="contained"
                        type="submit"
                        className="bg-primary text-light fw-bold"
                        disabled={loader}
                        startIcon={
                          loader ? (
                            <Spinner
                              animation="border"
                              style={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <SaveIcon />
                          )
                        }
                        onClick={() => requiredFields()}
                      >
                        Registar
                      </Button>
                    </div>
                  </div>
                </form>
              </Paper>
            </div>
            <div className="col-md-3">
              <Paper elevation={1} className="p-4 paper-users">
                <span className="fw-bold text-secondary">
                  Utilizadores da plataforma
                </span>
                <Paper elevation={0} className="p-4 m-3">
                  <div className="">
                    <h6 className="text-primary">Academia</h6>
                    <span className="text-primary">
                      ({utilizadoresCount - utilizadoresNormal})
                    </span>
                  </div>
                </Paper>
                <div className="pt-1" style={{ height: 121 }}>
                  <NavLink
                    className="sub-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#ea8b0f" : "white",
                    })}
                    to={"/utilizadores/publico"}
                  >
                    <Paper
                      elevation={1}
                      className="p-3 m-3 bg-primary button-users"
                    >
                      <h6 className="text-light text-decoration-underline">
                        Público
                      </h6>
                      {/* <span className="text-dark">(25)</span> */}
                    </Paper>
                  </NavLink>
                </div>
              </Paper>
            </div>

            {/* -------------------TABELA DE LISTAGEM------------- */}

            <div className="col-md-12">
              <TableContainer component={Paper}>
                {/* --------------------------- */}
                <div className="row g-3">
                  <div className="col-6 text-start p-3">
                    <h5>Lista de utilizadores</h5>
                  </div>

                  <div className="col-6 text-end">
                    <Button
                      hidden={!existentes}
                      name="Basic button"
                      value="default"
                      size="medium"
                      onClick={() => setFilter(!filter)}
                      aria-expanded={filter}
                      aria-controls="collapse-filter"
                      title="Filtrar"
                    >
                      <FilterListIcon
                        style={{ fontSize: 32, color: "#ea8b0f" }}
                      />
                      {filter ? (
                        <ArrowDropUpIcon style={{ color: "#ea8b0f" }} />
                      ) : (
                        <ArrowDropDownIcon style={{ color: "#ea8b0f" }} />
                      )}
                    </Button>
                  </div>
                </div>
                {/* -------------------------- */}

                <Collapse
                  in={filter}
                  timeout="auto"
                  unmountOnExit
                  disablePadding
                >
                  <div className="row g-4 mb-2 d-flex justify-content-end me-2">
                    <div
                      className="position-relative col-md-6"
                      style={{ maxWidth: "320px", marginTop: 20 }}
                    >
                      <input
                        className="form-control border-primary py-3 ps-4 pe-5"
                        type="text"
                        placeholder="Pesquisar por nome"
                        style={{ height: 45 }}
                        value={searchNome}
                        onChange={(e) => setSearchNome(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search "
                        style={{ width: 55, height: 45 }}
                        onClick={() =>
                          searchNome ? searchByNome(searchNome) : fetchData()
                        }
                        disabled={searching}
                      >
                        {searching ? (
                          <Spinner
                            animation="border"
                            style={{ width: 24, height: 24 }}
                          />
                        ) : (
                          <SearchIcon
                            className="text-light btn-search"
                            style={{ marginTop: -5 }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </Collapse>

                {loading ? (
                  <div className="  text-center d-flex w-100 align-items-center justify-content-center">
                    <div
                      className="spinner-border text-primary"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {existentes ? (
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell className="fw-bold">ID</TableCell>
                            <TableCell className="fw-bold">
                              Nome completo
                            </TableCell>
                            <TableCell className="fw-bold">Email</TableCell>
                            <TableCell className="fw-bold">Username</TableCell>
                            <TableCell className="fw-bold">Contacto</TableCell>
                            <TableCell className="fw-bold">Nível</TableCell>
                            <TableCell className="fw-bold">Estado</TableCell>
                            <TableCell className="fw-bold" align="right">
                              Acções
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {utilizadores?.map((d) => (
                            <>
                              <TableRow key={d.id}>
                                <TableCell component="th" scope="row">
                                  {d.id}
                                </TableCell>
                                <TableCell>{d.nome}</TableCell>
                                <TableCell>{d.email}</TableCell>
                                <TableCell>{d.usuario}</TableCell>
                                <TableCell>{d.contacto}</TableCell>
                                <TableCell>{d.tipo}</TableCell>
                                <TableCell>{d.estado}</TableCell>
                                <TableCell align="right" className="ms-4">
                                  <div className="text-end dropdownrounded">
                                    <Button
                                      id="dropdownMenu2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <MoreVertIcon
                                        style={{ color: "#ea8b0f" }}
                                      />
                                    </Button>
                                    <ul
                                      className="dropdown-menu detalhes-menu"
                                      aria-labelledby="dropdownMenu2"
                                    >
                                      <li>
                                        {d.estado === "Activo" ? (
                                          <button
                                            className="dropdown-item"
                                            type="button"
                                            onClick={() =>
                                              openModalBlock(d.id, d.nome)
                                            }
                                          >
                                            Bloquear
                                          </button>
                                        ) : (
                                          <button
                                            className="dropdown-item"
                                            type="button"
                                            onClick={() =>
                                              openModalBlock1(d.id, d.nome)
                                            }
                                          >
                                            Desbloquear
                                          </button>
                                        )}
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          type="button"
                                          onClick={() =>
                                            openModalDelete(d.id, d.nome)
                                          }
                                        >
                                          Eliminar
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <div className="p-5 text-center d-inline-block w-100 align-items-center justify-content-center">
                        {/* <span>{props.nada}</span> */}
                        <div>
                          <img
                            src={nothing22}
                            alt=""
                            title="Nada para mostrar"
                            style={{ width: "170px" }}
                          />
                        </div>
                        <span className="text-secondary">
                          Nenhum utilizador registado!
                        </span>{" "}
                      </div>
                    )}
                    {existentes1 && (
                      <div className="mt-3 text-center d-inline-block w-100 align-items-center justify-content-center">
                        {/* <span>{props.nada}</span> */}
                        <div>
                          <img
                            src={searchGif}
                            alt=""
                            title="Nada para mostrar"
                            style={{ width: "170px" }}
                          />
                        </div>
                        <span className="text-secondary">
                          Nenhum resultado encontrado!
                        </span>{" "}
                      </div>
                    )}
                  </>
                )}
              </TableContainer>
            </div>
          </div>
        </div>
      </Container>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={mensagem}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={snackColor}
      />

      <Confirmacao
        show={showBlock}
        closeModal={closeModalBlock}
        closeModal1={() => bloquear()}
        confirmar={"Confirmar"}
        iconn={
          blocking ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <LockOpenIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={
          <>
            Tem certeza que pretende bloquear o utilizador
            <span className="fw-bold"> {nomeModal}</span> ?
          </>
        }
        color1="text-danger"
      />

      <Confirmacao
        show={showBlock1}
        closeModal={closeModalBlock1}
        closeModal1={() => desbloquear()}
        confirmar={"Confirmar"}
        iconn={
          blocking1 ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <BlockIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={
          <>
            Tem certeza que pretende desbloquear o utilizador
            <span className="fw-bold"> {nomeModal}</span> ?
          </>
        }
        color1="text-danger"
      />

      <Confirmacao
        show={showDelete}
        closeModal={closeModalDelete}
        closeModal1={() => apagar()}
        confirmar={"Confirmar"}
        iconn={
          deleting ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <DeleteOutlineIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={
          <>
            Tem certeza que pretende eliminar o utilizador
            <span className="fw-bold"> {nomeModal}</span> ?
          </>
        }
        color1="text-danger"
      />

      <Footer />
    </div>
  );
}
