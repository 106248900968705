import React from "react";
import Appbar from "./appbar";
import Footer from "./footer";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../img/logo.png";
import happy from "../img/happynice.gif";
import { useState } from "react";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";
import SnackBar from "./snackbar";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});
const Registar = () => {
  const [nome, setNome] = useState();
  const [senha, setSenha] = useState();
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [contacto, setContacto] = useState();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorUsername, setErrorUsername] = useState(false);
  const [errorUsernameMessage, setErrorUsernameMessage] = useState("");

  const [errorSenha, setErrorSenha] = useState(false);
  const [errorSenhaMessage, setErrorSenhaMessage] = useState(
    "*Pelo menos 6 digitos"
  );

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorContacto, setErrorContacto] = useState(false);
  const [errorContactoMessage, setErrorContactoMessage] = useState("");

  const dados = {
    nome: nome,
    senha: senha,
    email: email,
    contacto: contacto,
    username: username,
    tipo: "Normal",
    estado: "Activo",
  };

  const cleanFields = () => {
    setNome("");
    setSenha("");
    setEmail("");
    setUsername("");
    setContacto("");
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorUsername === false &&
      errorSenha === false &&
      errorContacto === false &&
      errorEmail === false
    ) {
      salvar();
    } else {
      console.log("N");
    }
  };

  const requiredFields = () => {
    const re = /^[0-9\b]+$/;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (username === "" || username === undefined) {
      setErrorUsername(true);
      setErrorUsernameMessage("Por favor, preencha este campo!");
    } else {
      setErrorUsername(false);
      setErrorUsernameMessage(true);
    }
    if (senha === "" || senha === undefined || senha?.length < 6) {
      setErrorSenha(true);
      setErrorSenhaMessage("Senha inválida, pelo menos 6 dígitos!");
    } else {
      setErrorSenha(false);
      setErrorSenhaMessage(true);
    }
    if (
      contacto === "" ||
      contacto?.length < 9 ||
      contacto === undefined ||
      !contacto?.match(re)
    ) {
      setErrorContacto(true);
      setErrorContactoMessage(
        "Número de telefone inválido, campo obrigatório!"
      );
    } else {
      setErrorContacto(false);
      setErrorContactoMessage(true);
    }
    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }
  };

  const salvar = () => {
    //console.log(dados);
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        nome: dados.nome,
        contacto: dados.contacto,
        email: dados.email,
        usuario: dados.username,
        senha: dados.senha,
        img_link: "Sem imagem",
        tipo: dados.tipo,
        estado: dados.estado,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.message === 100) {
          setShowError(true);
        } else {
          cleanFields();
          setShow(true);
        }
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [showError, setShowError] = useState(false);

  const handleCloseError = () => {
    setShowError(false);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div>
      <Appbar />
      <div className="container-fluid py-5 bg-dark hero-header-login">
        <div className="container text-center my-5 pt-5 pb-4">
          <Paper
            className="container paper  justify-content-center pe-5 "
            elevation={5}
            style={{ paddingLeft: 11 }}
          >
            <div className="row g-4">
              <div
                className="col-md-6 wow fadeIn bg-dark mt-0 pt-5 pb-0 mb-0"
                data-wow-delay="0.1s"
                //style={{ height: '1rem' }}
              >
                <img
                  src={logo}
                  alt="Holding hands"
                  style={{ width: "55%", marginLeft: -25, marginTop: "22%" }}
                />
              </div>
              <div className="col-md-6 pb-5 ps-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                  <h5 className="section-title ff-secondary text-center text-primary fw-normal ">
                    Hope for the worl
                  </h5>

                  <h3 className="mb-5 mt-3">Registo</h3>
                </div>
                <div className="wow fadeInUp mt-4" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-3">
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="nome"
                            label="Nome Completo"
                            type={"text"}
                            id="nome"
                            value={nome}
                            defaultValue={nome}
                            error={errorNome}
                            helperText={errorNomeMessage}
                            onChange={(e) => setNome(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="email"
                            label="Email"
                            type={"text"}
                            id="email"
                            value={email}
                            defaultValue={email}
                            error={errorEmail}
                            helperText={errorEmailMessage}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="contacto"
                            label="Contacto"
                            type={"text"}
                            id="contacto"
                            value={contacto}
                            defaultValue={contacto}
                            error={errorContacto}
                            helperText={errorContactoMessage}
                            onChange={(e) => setContacto(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CallIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="username"
                            label="Username"
                            type={"text"}
                            id="username"
                            value={username}
                            defaultValue={username}
                            error={errorUsername}
                            helperText={errorUsernameMessage}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountCircleIcon
                                    style={{ color: "#EA8B0F" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="senha"
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            id="senha"
                            value={senha}
                            defaultValue={senha}
                            error={errorSenha}
                            helperText={errorSenhaMessage}
                            onChange={(e) => setSenha(e.target.value)}
                            autoComplete="current-password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-12 text-end">
                        <NavLink
                          to={"/login"}
                          style={{ textDecoration: "underline", color: "grey" }}
                          className="link-login"
                        >
                          <small for="senha">Já tenho uma conta! Entrar</small>
                        </NavLink>
                      </div>

                      <div className="col-12">
                        <Button
                          variant="contained"
                          type="submit"
                          className=" w-100 bg-primary text-light fw-bold"
                          //startIcon={<SaveIcon />}
                          onClick={() => requiredFields()}
                          disabled={loader}
                        >
                          {loader ? "A enviar dados" : "Registar"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Paper>

          <div className="container paper1" style={{ marginTop: 35 }}>
            <div className="row g-4" style={{ marginTop: -80 }}>
              <div
                className="col-md-6 wow fadeIn bg-dark p-3"
                data-wow-delay="0.1s"
              >
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                  <h5 className="section-title ff-secondary text-center text-primary fw-normal ">
                    Hope for the world
                  </h5>
                  <h3 className="mt-3 mb-2 text-light">Registo</h3>
                </div>
                <img
                  src={logo}
                  alt="Holding hands"
                  style={{ width: "50%", marginLeft: -25 }}
                />
              </div>
              <div className="col-md-6 bg-light mt-0 pb-4 pt-3">
                <div className="wow fadeInUp" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-3">
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="nome"
                            label="Nome Completo"
                            type={"text"}
                            id="nome"
                            value={nome}
                            defaultValue={nome}
                            error={errorNome}
                            helperText={errorNomeMessage}
                            onChange={(e) => setNome(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="email"
                            label="Email"
                            type={"text"}
                            id="email"
                            value={email}
                            defaultValue={email}
                            error={errorEmail}
                            helperText={errorEmailMessage}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="contacto"
                            label="Contacto"
                            type={"text"}
                            id="contacto"
                            value={contacto}
                            defaultValue={contacto}
                            error={errorContacto}
                            helperText={errorContactoMessage}
                            onChange={(e) => setContacto(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CallIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="username"
                            label="Username"
                            type={"text"}
                            id="username"
                            value={username}
                            defaultValue={username}
                            error={errorUsername}
                            helperText={errorUsernameMessage}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountCircleIcon
                                    style={{ color: "#EA8B0F" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="senha"
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            id="senha"
                            value={senha}
                            defaultValue={senha}
                            error={errorSenha}
                            helperText={errorSenhaMessage}
                            onChange={(e) => setSenha(e.target.value)}
                            autoComplete="current-password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon style={{ color: "#EA8B0F" }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-12 text-end">
                        <NavLink
                          to={"/login"}
                          style={{ textDecoration: "underline", color: "grey" }}
                          className="link-login"
                        >
                          <small for="senha">Já tenho uma conta! Entrar</small>
                        </NavLink>
                      </div>

                      <div className="col-12">
                        <Button
                          variant="contained"
                          type="submit"
                          className=" w-100 bg-primary text-light fw-bold"
                          //startIcon={<SaveIcon />}
                          onClick={() => requiredFields()}
                          disabled={loader}
                        >
                          {loader ? "A enviar dados" : "Registar"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        show={show}
        style={{ marginTop: 5 }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-dark">
          <Modal.Title className="text-light">
            {" "}
            Registou-se com sucesso!
          </Modal.Title>
          <Button
            className="me-1 fw-bold ps-3 pe-3 accordion-buttons text-secondary"
            onClick={handleClose}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <span className="text-dark" style={{ fontSize: 21 }}>
              <div className="pt-3">Bem vindo ao </div>
              <h3 className="text-primary ms-2">
                H
                <img
                  src={logo}
                  alt="Change Logo"
                  width="35px"
                  style={{ marginTop: -10 }}
                />
                PE
              </h3>
            </span>
            <br /> <br />
            <div className="ms-2">
              <img
                src={happy}
                alt="Happy - Bem Vindo"
                width="100px"
                style={{ marginTop: 14 }}
              />
            </div>
          </div>
          <div className="text-center" style={{ color: "#1D69BC" }}>
            <small>
              Por favor insira seus dados de acesso para autenticar-se!
            </small>
          </div>
          {/* <div className="row g-4  div-detalhes mt-1">
            <div className="col-md-12">
              <div className="wow fadeInUp mt-2" data-wow-delay="0.2s">
                <h1>BEM VINDOOOO</h1>
              </div>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-between">
            <small
              className="text-decoration-underline mt-2"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/home")}
            >
              Autenticar-me depois
            </small>
            <Button
              className="fw-bold text-dark"
              onClick={() => navigate("/login")}
              endIcon={<KeyboardArrowRightIcon />}
            >
              Ir para autenticação
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <SnackBar
        open={showError}
        handleClose={handleCloseError}
        message={
          "Usuário ou email já foi registado. Tente novamente, por favor!"
        }
        key={"bottom" + "center"}
        autoHideDuration={4000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={"error"}
      />
    </div>
  );
};

export default Registar;
