import React from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminPag from "./pages/administrador";
import DenunciasAprovadas from "./pages/denuncias/aprovadas";
import DenunciasFechadas from "./pages/denuncias/fechadas";
import DenunciasPendentes from "./pages/denuncias/pendentes";
import DenunciasRejeitadas from "./pages/denuncias/rejeitadas";
import DetalhesPag from "./pages/detalhes";
import DetalhesAdminPag from "./pages/detalhesAdmin";
import DetalhesMeusPag from "./pages/detalhesMeus";
import EstudosFechadas from "./pages/estudos/fechadas";
import EstudosAprovadas from "./pages/estudos/aprovadas";
import EstudosPendentes from "./pages/estudos/pendentes";
import EstudosRejeitadas from "./pages/estudos/rejeitadas";
import FormularioEstudos from "./pages/formEstudos";
import FormularioIdeias from "./pages/formIdeias";
import FormularioSaude from "./pages/formSaude";
import FormularioPag from "./pages/formulario";
import HomePage from "./pages/home";
import IdeiasAprovadas from "./pages/ideias/aprovadas";
import IdeiasFechadas from "./pages/ideias/fechadas";
import IdeiasPendentes from "./pages/ideias/pendentes";
import IdeiasRejeitadas from "./pages/ideias/rejeitadas";
import LoginPag from "./pages/login";
import MeusPostsPag from "./pages/meusPosts";
import RegistoPag from "./pages/registo";
import SaudeAprovadas from "./pages/saude/aprovadas";
import SaudeFechadas from "./pages/saude/fechadas";
import SaudePendentes from "./pages/saude/pendentes";
import SaudeRejeitadas from "./pages/saude/rejeitadas";
import RegistoDoadores from "./pages/doadores/registo";
import ListaDoadores from "./pages/doadores/lista";
import UtilizadoresAcademia from "./pages/utilizadores/academia";
import UtilizadoresPublico from "./pages/utilizadores/publico";
import TestemunhosPag from "./pages/testemunhos";
import EditarDoador from "./pages/doadores/editar";
import CasosPendentes from "./pages/casosPendentes";
import RegistoLinks from "./pages/socialMedia/registo";
import UtilizadoresHistorico from "./pages/utilizadores/historico";
import EditarCasoPag from "./pages/editarCaso";
import PerfilAdminPag from "./pages/utilizadores/perfilAdmin";
import PerfilNormalPag from "./pages/utilizadores/perfilNormal";
import NotFoundPage from "./pages/notfound";
import SobrePag from "./pages/sobre";
import ProtectedRoutes from "./protectedRoutes";
import NaoPermitido from "./components/naoPermitido";
import DetalhesDenunciaPag from "./pages/detalhesDenuncia";
import PublicRoutes from "./publicRoutes";


const Routing = () => {

  return (
    <HashRouter>
      <Routes>


        <Route exact path="/" element={<Navigate replace to={'home'} />} />
        <Route exact path="*" element={<NotFoundPage />} />
        <Route exact path="/home" element={<HomePage />} />
        <Route exact path="/sobre" element={<SobrePag />} />

        <Route exact path="/formulario" element={<FormularioPag />} />
        <Route exact path="/formSaude" element={<FormularioSaude />} />
        <Route exact path="/formEstudos" element={<FormularioEstudos />} />
        <Route exact path="/formIdeias" element={<FormularioIdeias />} />
        <Route exact path="/detalhes/:id" element={<DetalhesPag />} />
        <Route exact path="/detalhesDenuncia/:id" element={<DetalhesDenunciaPag />} />
        <Route exact path="/login" element={<LoginPag />} />
        <Route exact path="/registar" element={<RegistoPag />} />


        <Route exact path="/permissiondenied" element={<NaoPermitido />} />

        <Route path="/" element={<ProtectedRoutes/>}>
          {/* <Route path="dashboard" element={<Navigate replace to="dashboard" />} /> */}
          <Route path="/dashboard" element={<AdminPag />}/>

          <Route exact path="/detalhesAdmin/:id" element={<DetalhesAdminPag />} />

          
          {/* -------------- CASOS -------------------- */}
          <Route exact path="/casosPendentes" element={<CasosPendentes />} />
          
          {/* -------------- DENÚNCIAS -------------------- */}
          <Route exact path="/denuncias/fechadas" element={<DenunciasFechadas />} />
          <Route exact path="/denuncias/aprovadas" element={<DenunciasAprovadas />} />
          <Route exact path="/denuncias/pendentes" element={<DenunciasPendentes />} />
          <Route exact path="/denuncias/rejeitadas" element={<DenunciasRejeitadas />} />

          {/* -------------- SAÚDE -------------------- */}
          <Route exact path="/saude/fechadas" element={<SaudeFechadas />} />
          <Route exact path="/saude/aprovadas" element={<SaudeAprovadas />} />
          <Route exact path="/saude/pendentes" element={<SaudePendentes />} />
          <Route exact path="/saude/rejeitadas" element={<SaudeRejeitadas />} />

          {/* -------------- ESTUDOS -------------------- */}
          <Route exact path="/estudos/fechadas" element={<EstudosFechadas />} />
          <Route exact path="/estudos/aprovadas" element={<EstudosAprovadas />} />
          <Route exact path="/estudos/pendentes" element={<EstudosPendentes />} />
          <Route exact path="/estudos/rejeitadas" element={<EstudosRejeitadas />} />

          {/* -------------- IDEIAS -------------------- */}
          <Route exact path="/ideias/fechadas" element={<IdeiasFechadas />} />
          <Route exact path="/ideias/aprovadas" element={<IdeiasAprovadas />} />
          <Route exact path="/ideias/pendentes" element={<IdeiasPendentes />} />
          <Route exact path="/ideias/rejeitadas" element={<IdeiasRejeitadas />} />

          {/* -------------- DOADORES -------------------- */}
          <Route exact path="/doadores/registo" element={<RegistoDoadores />} />
          <Route exact path="/doadores/lista" element={<ListaDoadores />} />
          <Route exact path="/doadores/editar/:id" element={<EditarDoador />} />

          {/* -------------- UTILIZADORES -------------------- */}
          <Route exact path="/utilizadores/academia" element={<UtilizadoresAcademia />} />
          <Route exact path="/utilizadores/publico" element={<UtilizadoresPublico />} />
          <Route exact path="/utilizadores/historico" element={<UtilizadoresHistorico />} />
          <Route exact path="/perfilAdmin" element={<PerfilAdminPag />} />

          {/* -------------- TESTEMUNHOS -------------------- */}
          <Route exact path="/testemunhos" element={<TestemunhosPag />} />

          {/* -------------- SOCIAL MEDIA -------------------- */}
          <Route exact path="/media/registo" element={<RegistoLinks />} />
        </Route>


        <Route path="/" element={<PublicRoutes/>}>
          <Route exact path="/perfilNormal" element={<PerfilNormalPag />} />
          <Route exact path="/editarCaso/:id" element={<EditarCasoPag />} />
          <Route exact path="/detalhesMeus/:id" element={<DetalhesMeusPag />} />
          <Route exact path="/meusPosts" element={<MeusPostsPag />} />
        </Route>

        
      </Routes>
    </HashRouter>

    
  );
};

export default Routing;

/* 

        <Route path="/" element={<PublicRoutes/>}>
          <Route exact path="/perfilNormal" element={<PerfilNormalPag />} />
          <Route exact path="/editarCaso/:id" element={<EditarCasoPag />} />
          <Route exact path="/detalhesMeus/:id" element={<DetalhesMeusPag />} />
          <Route exact path="/meusPosts" element={<MeusPostsPag />} />
          <Route exact path="/sobre" element={<SobrePag />} />
        </Route>
*/
