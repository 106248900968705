import React from "react";
import '@splidejs/splide/dist/css/splide.min.css';
import "./App.css";
import "./css/style.css";
import "./css/pagination.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./css/bootstrap.min.css";
import Routing from "./routes";
import jquery from "jquery";
import Splide from "@splidejs/splide";
import BackTop from "./components/backTop";

const App = () => {
  (function ($) {
    "use strict";

    // Spinner
    var spinner = function () {
      setTimeout(function () {
        if ($("#spinner").length > 0) {
          $("#spinner").removeClass("show");
        }
      }, 1);
    };
    spinner();
  })(jquery);
  document.addEventListener("DOMContentLoaded", function () {
    // Full Carousel
    document.querySelectorAll(".carousel-full").forEach((carousel) =>
      new Splide(carousel, {
        perPage: 1,
        rewind: true,
        type: "loop",
        gap: 0,
        arrows: false,
        pagination: false,
      }).mount()
    );

    // Single Carousel
    document.querySelectorAll(".carousel-single").forEach((carousel) =>
      new Splide(carousel, {
        perPage: 3,
        rewind: true,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 1,
          },
          991: {
            perPage: 2,
          },
        },
      }).mount()
    );

    // Multiple Carousel
    document.querySelectorAll(".carousel-multiple").forEach((carousel) =>
      new Splide(carousel, {
        perPage: 4,
        rewind: true,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 2,
          },
          991: {
            perPage: 3,
          },
        },
      }).mount()
    );

    // Small Carousel
    document.querySelectorAll(".carousel-small").forEach((carousel) =>
      new Splide(carousel, {
        perPage: 9,
        rewind: false,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 4,
          },
          991: {
            perPage: 7,
          },
        },
      }).mount()
    );

    // Slider Carousel
    document.querySelectorAll(".carousel-slider").forEach((carousel) =>
      new Splide(carousel, {
        perPage: 1,
        rewind: false,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: true,
      }).mount()
    );

    // Stories Carousel
    document.querySelectorAll(".story-block").forEach((carousel) =>
      new Splide(carousel, {
        perPage: 16,
        rewind: false,
        type: "slide",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: false,
        breakpoints: {
          500: {
            perPage: 4,
          },
          768: {
            perPage: 7,
          },
          1200: {
            perPage: 11,
          },
        },
      }).mount()
    );
  });

  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  if (!usuarioLogado) {
    localStorage.setItem("userLogado", JSON.stringify(""));
    localStorage.setItem("isAuthenticated", false);
  }

  return (
    <div className="App container-fluid p-0">
      {/* ---------------- Spinner Start -------------- */}
      {/*       <div
        id="spinner"
        className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
      {/* ---------------- Spinner End -------------- */}
      <Routing />
      <BackTop />
    </div>
  );
};

export default App;
