import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import hero from "../img/hero.png";
import Appbar from "./appbar";
import saudee from "../img/saude.png";
import ideiass from "../img/ideias.png";
import estudoss from "../img/estudos.png";
import denunciass from "../img/denuncias.png";
import logoprov from "../img/logoprov.png";
import smiling from "../img/smiling.png";
import Footer from "./footer";
import dateFormat from "dateformat";
import { NavLink } from "react-router-dom";
import Slide from "./slide";
import emailjs from "@emailjs/browser";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import { Button, Snackbar, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});

export default function Home(props) {
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  const [existentesDenuncias, setExistentesDenuncias] = useState(true);
  const [seemoreD, setSeemoreD] = useState(true);
  const [existentesSaude, setExistentesSaude] = useState(true);
  const [seemoreS, setSeemoreS] = useState(true);
  const [existentesEstudos, setExistentesEstudos] = useState(true);
  const [seemoreE, setSeemoreE] = useState(true);
  const [existentesIdeias, setExistentesIdeias] = useState(true);
  const [seemoreI, setSeemoreI] = useState(true);
  const [existentesTestemunhos, setExistentesTestemunhos] = useState(true);

  const [estudos, setEstudos] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Estudos",
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        //setEstudos(data);
        if (data?.length !== 0) {
          var estudos1 = [];
          /* 
          for (let es = 0; es < 4; es++) {
            estudos1.push(data[getRandomInt(data?.length)]);
          } */
          if (data?.length <= 4) {
            for (let es = 0; es < data?.length; es++) {
              estudos1.push(data[es]);
            }
          } else {
            setSeemoreE(false);
            for (let es = 0; es < 4; es++) {
              estudos1.push(data[es]);
            }
          }
          let uniqueChars = [...new Set(estudos1)];
          setEstudos(estudos1);
          setExistentesEstudos(true);
        } else {
          setExistentesEstudos(false);
        }
      });
  }, []);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  // expected output: 0, 1 or 2

  const [ideias, setIdeias] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Projecto",
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        //setIdeias(data);
        if (data?.length !== 0) {
          var ideias1 = [];

          if (data?.length <= 4) {
            for (let es = 0; es < data?.length; es++) {
              ideias1.push(data[es]);
            }
          } else {
            setSeemoreI(false);
            for (let es = 0; es < 4; es++) {
              ideias1.push(data[es]);
            }
          }
          //let uniqueChars = [...new Set(ideias1)];
          setIdeias(ideias1);
          setExistentesIdeias(true);
        } else {
          setExistentesIdeias(false);
        }
      });
  }, []);

  const [saude, setSaude] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        //setSaude(data);
        if (data?.length !== 0) {
          var saude1 = [];

          if (data?.length <= 4) {
            for (let es = 0; es < data?.length; es++) {
              saude1.push(data[es]);
            }
          } else {
            setSeemoreS(false);
            for (let es = 0; es < 4; es++) {
              saude1.push(data[es]);
            }
          }
          setSaude(saude1);
          setExistentesSaude(true);
        } else {
          setExistentesSaude(false);
        }
      });
  }, []);

  const [denuncias, setdenuncias] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByDenuncia")
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);

        if (data?.length !== 0) {
          var denuncias1 = [];

          if (data?.length <= 4) {
            for (let es = 0; es < data?.length; es++) {
              denuncias1.push(
                data?.sort(function (a, b) {
                  return new Date(b.created_at) - new Date(a.created_at);
                })[es]
              );
            }
          } else {
            setSeemoreD(false);
            for (let es = 0; es < 4; es++) {
              denuncias1.push(
                data?.sort(function (a, b) {
                  return new Date(b.created_at) - new Date(a.created_at);
                })[es]
              );
            }
          }

          setdenuncias(denuncias1);
          setExistentesDenuncias(true);
        } else {
          setExistentesDenuncias(false);
        }
      });
  }, []);

  const [testemunhos, setTestemunhos] = useState();

  useEffect(() => {
    axios
      .get("https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos", {
        params: {
          _limit: 1,
        },
      })
      .then((res) => {
        setTestemunhos(res.data.data);
        if (res.data.data.length !== 0) {
          setExistentesTestemunhos(true);
        } else {
          setExistentesTestemunhos(false);
        }
        setLoading(false);
      });
  }, []);

  const form = useRef();
  const [nameSender, setNameSender] = useState();
  const [emailSender, setEmailSender] = useState();
  const [message, setMessage] = useState();

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageMessage, setErrorMessageMessage] = useState("");

  const [sending, setSending] = useState(false);

  const sendEmail = () => {
    setSending(true);

    emailjs
      .sendForm(
        "service_xwg0ysa",
        "template_0ijgwzf",
        form.current,
        "f1ibuUWzvcn7ZVdey"
      )
      .then(
        (result) => {
          setSending(false);
          setNameSender("");
          setEmailSender("");
          setMessage("");
          setOpen(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorNome === false && errorEmail === false && errorMessage === false) {
      sendEmail();
    } else {
      console.log("N");
    }
  };

  const requiredFields = () => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (nameSender === "" || nameSender === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }

    if (
      emailSender === "" ||
      emailSender === undefined ||
      !emailSender?.match(mailformat)
    ) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }

    if (message === "" || message === undefined) {
      setErrorMessage(true);
      setErrorMessageMessage("Por favor, preencha este campo!");
    } else {
      setErrorMessage(false);
      setErrorMessageMessage(true);
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const [videoHome1, setVideoHome1] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/links/show/5`)
      .then((response) => response.json())
      .then((data) => {
        setVideoHome1(data.data);
      });
  }, []);

  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Appbar />
        {/* -----------------BANNER - CAPPA--------------- */}
        <div className="container-fluid py-5 bg-dark hero-header mb-5">
          <div className="container my-5 py-4">
            <div className="row align-items-center g-5">
              <div className="col-lg-6 text-center text-lg-start">
                <h1 className="display-3 text-white animated slideInLeft">
                  Torne o mundo num lugar melhor
                  <br />
                  para todos.
                </h1>
                <p className="text-white animated slideInLeft mb-4 pb-2">
                  Esperamos ver o Mundo mudar para melhor a cada dia, alguns
                  desejam que as guerras acabem e alguns simplesmente sonham que
                  o aquecimento global pare, mas tão pouco é feito para mudar
                  tudo e, o que o nosso mundo precisa é de acção!
                </p>
                <span
                  className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInLeft"
                  onClick={() => executeScroll()}
                >
                  Torne o mundo melhor
                </span>
              </div>
              <div className="col-lg-6 text-center text-lg-end overflow-hidden">
                <img src={hero} alt="hero" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------- SOBRE OS SERVIÇOS -------------- */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item card-servicos rounded pt-3">
                <div className="p-4 ">
                  <div className="container d-flex justify-content-center images ">
                    <img src={denunciass} alt="Denúncias" />
                  </div>
                  <h5 className="d-flex justify-content-center">Denúncias</h5>
                  <p className="d-flex justify-content-center text-center texto-servicos">
                    Se você está a passar por uma situação ou presenciou uma e,
                    não hesite, em contar para nós!
                  </p>
                  <div className="container">
                    <div className="row row-cols-1 d-block justify-content-center text-center">
                      <NavLink to="/formulario" className="">
                        <button
                          type="button"
                          className="btn btn-dark bt-card mt-2"
                          style={{ maxWidth: "7rem" }}
                        >
                          {" "}
                          Ver mais
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item card-servicos rounded pt-3">
                <div className="p-4">
                  <div className="container d-flex justify-content-center images ">
                    <img src={saudee} alt="Saúde" />
                  </div>
                  <h5 className="d-flex justify-content-center "> Saúde </h5>
                  <p className="d-flex justify-content-center texto-servicos">
                    Precisa de ajuda seja ela monetária ou não para cuidar de
                    sua saúde? Aqui é o lugar certo.
                  </p>
                  <div className="container">
                    <div className="row row-cols-1 d-block justify-content-center text-center">
                      <NavLink to="/formSaude" className="">
                        <button
                          type="button"
                          className="btn btn-dark bt-card mt-2"
                          style={{ maxWidth: "7rem" }}
                        >
                          {" "}
                          Ver mais
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item card-servicos rounded pt-3">
                <div className="p-4">
                  <div className="container d-flex justify-content-center images ">
                    <img src={estudoss} alt="Estudos" />
                  </div>
                  <h5 className="d-flex justify-content-center "> Estudos </h5>
                  <p className="d-flex justify-content-center texto-servicos">
                    Com dificuldades em arcar com os estudos e precisa de ajuda?
                    Não hesite, peça ajuda aqui.
                  </p>
                  <div className="container">
                    <div className="row row-cols-1 d-block justify-content-center text-center">
                      <NavLink to="/formEstudos" className="">
                        <button
                          type="button"
                          className="btn btn-dark bt-card mt-2"
                          style={{ maxWidth: "7rem" }}
                        >
                          {" "}
                          Ver mais
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item card-servicos rounded pt-3">
                <div className="p-4">
                  <div className="container d-flex justify-content-center images ">
                    <img src={ideiass} alt="Ideias" />
                  </div>

                  <h5 className="d-flex justify-content-center ">Ideias</h5>
                  <p className="d-flex justify-content-center texto-servicos">
                    Tem uma ideia incrível, e precisa de um investidor para o
                    trazer a realidade? Consiga um investidor aqui.
                  </p>
                  <div className="container">
                    <div className="row row-cols-1 d-block justify-content-center text-center">
                      <NavLink to="/formIdeias" className="">
                        <button
                          type="button"
                          className="btn btn-dark bt-card mt-2"
                          style={{ maxWidth: "7rem" }}
                        >
                          {" "}
                          Ver mais
                        </button>
                      </NavLink>
                    </div>
                    <div className="container d-flex justify-content-center "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------- SOBRE OS SERVIÇOS -------------- */}

      <Slide />

      {/* ---------------- CASOS REPORTADOS-------------- */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-primary fw-normal">
              Doações
            </h5>
            <h1 className="mb-5">Seu apoio conta muito</h1>
          </div>
          <div
            className="tab-class text-center wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="tabCasos">
              <ul className="nav nav-pills d-inline-flex w-100 justify-content-center border-bottom mb-3 ">
                <li className="nav-item tab-casos w-25 ">
                  <a
                    className="d-flex align-items-center text-start mx-3 ms-0 pb-3 active"
                    data-bs-toggle="pill"
                    href="#tab-1"
                  >
                    <img src={saudee} alt="Saúde" width="40px" />

                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Saúde</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos w-25">
                  <a
                    className="d-flex align-items-center text-start mx-3 ms-0 pb-3 "
                    data-bs-toggle="pill"
                    href="#tab-2"
                  >
                    <img src={denunciass} alt="Denúncias" width="40px" />
                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Denúncias</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos w-25">
                  <a
                    className="d-flex align-items-center text-start mx-3 pb-3"
                    data-bs-toggle="pill"
                    href="#tab-3"
                  >
                    <img src={estudoss} alt="Estudos" width="40px" />

                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Estudos</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos w-25">
                  <a
                    className="d-flex align-items-center text-start mx-3 me-0 pb-3"
                    data-bs-toggle="pill"
                    href="#tab-4"
                  >
                    <img src={ideiass} alt="Ideias" width="40px" />

                    <div className="ps-3 pe-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Ideias</h6>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="tabCasos1">
              <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-3">
                <li className="nav-item tab-casos">
                  <a
                    className="d-flex align-items-center text-start mx-3 ms-0 pb-3 active"
                    data-bs-toggle="pill"
                    href="#tab-1"
                  >
                    <img src={saudee} alt="Saúde" width="40px" />

                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Saúde</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos">
                  <a
                    className="d-flex align-items-center text-start mx-3 ms-0 pb-3 "
                    data-bs-toggle="pill"
                    href="#tab-2"
                  >
                    <img src={denunciass} alt="Denúncias" width="40px" />
                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Denúncias</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos">
                  <a
                    className="d-flex align-items-center text-start pb-3"
                    data-bs-toggle="pill"
                    href="#tab-3"
                  >
                    <img src={estudoss} alt="Estudos" width="40px" />

                    <div className="ps-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Estudos</h6>
                    </div>
                  </a>
                </li>
                <li className="nav-item tab-casos">
                  <a
                    className="d-flex align-items-center text-start mx-3 me-0 pb-3"
                    data-bs-toggle="pill"
                    href="#tab-4"
                  >
                    <img src={ideiass} alt="Ideias" width="40px" />

                    <div className="ps-3 pe-3">
                      <small className="text-body">Casos de</small>
                      <h6 className="mt-n1 mb-0">Ideias</h6>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              {loading && (
                <div className="  text-center d-flex w-100 align-items-center justify-content-center">
                  <div
                    className="spinner-border text-primary"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </div>
              )}

              <div id="tab-1" className="tab-pane fade show p-0 active">
                {existentesSaude ? (
                  <>
                    <div className="row g-4">
                      {saude?.map((d) => (
                        <div className="col-lg-6" key={d.id}>
                          <div className="d-flex align-items-center">
                            <a
                              href={
                                d.img_link === "Sem imagem" ||
                                d.img_link === "capa.png"
                                  ? logoprov
                                  : d.img_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  d.img_link === "Sem imagem" ||
                                  d.img_link === "capa.png"
                                    ? logoprov
                                    : d.img_link
                                }
                                alt={d.nome}
                                className="img-fluid flex-shrink-0 rounded-circle"
                                style={{ width: "80px", height: "80px" }}
                                //style={{ width: "80px" }}
                              />
                            </a>
                            <div className="w-100 d-flex flex-column text-start ps-4">
                              <h5 className="d-flex justify-content-between border-bottom pb-2">
                                <span>{d.nome}</span>

                                <small>
                                  <NavLink to={"/detalhes/" + d.id}>
                                    <span
                                      style={{
                                        fontSize: 14,
                                      }}
                                      className=""
                                    >
                                      Detalhes
                                    </span>
                                  </NavLink>
                                </small>
                              </h5>
                              <small className=" d-flex justify-content-between ">
                                {d.d_curta}
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                    <NavLink to="/formSaude" className="" hidden={seemoreS}>
                      <Button
                        variant="contained"
                        className="fw-bold bg-dark mt-3 text-light"
                      >
                        Ver mais
                      </Button>
                    </NavLink>
                  </>
                ) : (
                  <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                    {/* <div>
                      <img
                        src={nothing22}
                        alt=""
                        title="Nada para mostrar"
                        style={{ width: "100px" }}
                      />
                    </div> */}
                    {/* <span>{props.nada}</span> */}
                    <span className="text-secondary">
                      Nada para mostrar aqui, seja o primeiro a
                    </span>{" "}
                    <NavLink
                      to="/formSaude"
                      className="text-decoration-underline"
                    >
                      submeter pedido
                    </NavLink>
                    !
                  </div>
                )}
              </div>
              <div id="tab-2" className="tab-pane fade show p-0">
                {existentesDenuncias ? (
                  <>
                    <div className="row g-4">
                      {denuncias?.map((d, index) => (
                        <div className="col-lg-6" key={d.id}>
                          <div className="d-flex align-items-center">
                            <a
                              href={
                                d.img_link === "Sem imagem" ||
                                d.img_link === "capa.png"
                                  ? logoprov
                                  : d.img_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  d.img_link === "Sem imagem" ||
                                  d.img_link === "capa.png"
                                    ? logoprov
                                    : d.img_link
                                }
                                alt={"Anónimo"}
                                title={"Anónimo"}
                                className="img-fluid flex-shrink-0 rounded-circle"
                                style={{ width: "80px", height: "80px" }}
                              />
                            </a>

                            <div className="w-100 d-flex flex-column text-start ps-4">
                              <h5 className="d-flex justify-content-between border-bottom pb-2">
                                <span className="blur-name-home1">
                                  {!(index % 2)
                                    ? "Academia de Pesquisa"
                                    : "Academia Virtual Hope"}
                                </span>
                                {/* <span className="">{d.d_curta}</span> */}
                                <small>
                                  <NavLink to={"/detalhesDenuncia/" + d.id}>
                                    <span
                                      style={{
                                        fontSize: 14,
                                      }}
                                      className=""
                                    >
                                      Detalhes
                                    </span>
                                  </NavLink>
                                </small>
                              </h5>
                              <small className=" d-flex justify-content-between ">
                                {/* {dateFormat(d.created_at, "dd-mm-yyyy")} */}
                                {d.d_curta}
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                    <NavLink to="/formulario" className="" hidden={seemoreD}>
                      <Button
                        variant="contained"
                        className="fw-bold bg-dark mt-3 text-light"
                      >
                        Ver mais
                      </Button>
                    </NavLink>
                  </>
                ) : (
                  <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                    <span className="text-secondary">
                      Nada para mostrar aqui, seja o primeiro a
                    </span>{" "}
                    <NavLink
                      to="/formulario"
                      className="text-decoration-underline"
                    >
                      submeter pedido
                    </NavLink>
                    !
                  </div>
                )}
                {/* <div className="divider"></div> */}
              </div>
              <div id="tab-3" className="tab-pane fade show p-0">
                {existentesEstudos ? (
                  <>
                    <div className="row g-4">
                      {estudos?.map((d) => (
                        <div className="col-lg-6" key={d.id}>
                          <div className="d-flex align-items-center">
                            <a
                              href={
                                d.img_link === "Sem imagem" ||
                                d.img_link === "capa.png"
                                  ? logoprov
                                  : d.img_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  d.img_link === "Sem imagem" ||
                                  d.img_link === "capa.png"
                                    ? logoprov
                                    : d.img_link
                                }
                                alt={d.nome}
                                className="img-fluid flex-shrink-0 rounded-circle"
                                style={{ width: "80px", height: "80px" }}
                              />
                            </a>
                            <div className="w-100 d-flex flex-column text-start ps-4">
                              <h5 className="d-flex justify-content-between border-bottom pb-2">
                                <span>{d.nome}</span>

                                <small>
                                  <NavLink to={"/detalhes/" + d.id}>
                                    <span
                                      style={{
                                        fontSize: 14,
                                      }}
                                      className=""
                                    >
                                      Detalhes
                                    </span>
                                  </NavLink>
                                </small>
                              </h5>
                              <small className=" d-flex justify-content-between ">
                                {d.d_curta}
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                    <NavLink to="/formEstudos" className="" hidden={seemoreE}>
                      <Button
                        variant="contained"
                        className="fw-bold bg-dark mt-3 text-light"
                      >
                        Ver mais
                      </Button>
                    </NavLink>
                  </>
                ) : (
                  <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                    {/* <div>
                      <img
                        src={nothing22}
                        alt=""
                        title="Nada para mostrar"
                        style={{ width: "100px" }}
                      />
                    </div> */}
                    {/* <span>{props.nada}</span> */}
                    <span className="text-secondary">
                      Nada para mostrar aqui, seja o primeiro a
                    </span>{" "}
                    <NavLink
                      to="/formEstudos"
                      className="text-decoration-underline"
                    >
                      submeter pedido
                    </NavLink>
                    !
                  </div>
                )}
              </div>
              <div id="tab-4" className="tab-pane fade show p-0">
                {existentesIdeias ? (
                  <>
                    <div className="row g-4">
                      {ideias?.map((d) => (
                        <div className="col-lg-6" key={d.id}>
                          <div className="d-flex align-items-center">
                            <a
                              href={
                                d.img_link === "Sem imagem" ||
                                d.img_link === "capa.png"
                                  ? logoprov
                                  : d.img_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  d.img_link === "Sem imagem" ||
                                  d.img_link === "capa.png"
                                    ? logoprov
                                    : d.img_link
                                }
                                alt={d.nome}
                                className="img-fluid flex-shrink-0 rounded-circle"
                                style={{ width: "80px", height: "80px" }}
                              />
                            </a>
                            <div className="w-100 d-flex flex-column text-start ps-4">
                              <h5 className="d-flex justify-content-between border-bottom pb-2">
                                <span>{d.nome}</span>

                                <small>
                                  <NavLink to={"/detalhes/" + d.id}>
                                    <span
                                      style={{
                                        fontSize: 14,
                                      }}
                                      className=""
                                    >
                                      Detalhes
                                    </span>
                                  </NavLink>
                                </small>
                              </h5>
                              <small className=" d-flex justify-content-between ">
                                {d.d_curta}
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                    <NavLink to="/formIdeias" className="" hidden={seemoreI}>
                      <Button
                        variant="contained"
                        className="fw-bold bg-dark mt-3 text-light"
                      >
                        Ver mais
                      </Button>
                    </NavLink>
                  </>
                ) : (
                  <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                    {/* <div>
                      <img
                        src={nothing22}
                        alt=""
                        title="Nada para mostrar"
                        style={{ width: "100px" }}
                      />
                    </div> */}
                    {/* <span>{props.nada}</span> */}
                    <span className="text-secondary">
                      Nada para mostrar aqui, seja o primeiro a
                    </span>{" "}
                    <NavLink
                      to="/formIdeias"
                      className="text-decoration-underline"
                    >
                      submeter pedido
                    </NavLink>
                    !
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div ref={myRef}></div>
      </div>
      {/* ---------------- CASOS REPORTADOS -------------- */}

      {/* ---------------- HORA DE AGIR -------------- */}
      <div
        className="container-xxl py-5 px-0 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="row g-0">
          <div className="col-md-6">
            <div className="video">
              <button
                type="button"
                className="btn-play"
                data-bs-toggle="modal"
                data-src={videoHome1?.descricao}
                data-bs-target="#videoModal"
              >
                <span></span>
              </button>
            </div>
          </div>
          <div className="col-md-6 bg-dark d-flex align-items-center">
            <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
              <h5 className="section-title ff-secondary text-start text-primary fw-normal">
                Hora de agir
              </h5>
              <h1 className="text-white mb-4">
                Como podemos tornar o mundo melhor?
              </h1>
              <form ref={form} onSubmit={formSubmit}>
                <div
                  className="row g-3 pb-2"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="col-12">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="nameSender"
                        label="Nome completo*"
                        type={"text"}
                        id="nameSender"
                        value={nameSender}
                        defaultValue={nameSender}
                        error={errorNome}
                        helperText={errorNomeMessage}
                        onChange={(e) => setNameSender(e.target.value)}
                        InputProps={{}}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="col-12">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="emailSender"
                        label="Email do remetente*"
                        type={"text"}
                        id="emailSender"
                        value={emailSender}
                        defaultValue={emailSender}
                        error={errorEmail}
                        helperText={errorEmailMessage}
                        onChange={(e) => setEmailSender(e.target.value)}
                        InputProps={{}}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="col-12">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        name="message"
                        label="Escreva aqui sua sugestão..."
                        multiline
                        rows={3}
                        type={"text"}
                        id="message"
                        value={message}
                        defaultValue={message}
                        error={errorMessage}
                        helperText={errorMessageMessage}
                        onChange={(e) => setMessage(e.target.value)}
                        InputProps={{}}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      value="Send"
                      onClick={() => requiredFields()}
                    >
                      {sending ? "A enviar resposta" : "Enviar resposta"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="videoModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Youtube Video
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* 16:9 aspect ratio  */}
              <div className="ratio ratio-16x9">
                <iframe
                  className="embed-responsive-item"
                  title="video"
                  src={videoHome1?.descricao}
                  id="video"
                  allowFullScreen
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------- HORA DE AGIR -------------- */}

      {/* -------------- O QUE AS PESSOAS CONTAM -------------- */}
      <div
        className="container-xxl py-5 wow fadeInUp"
        data-wow-delay="0.1s"
        hidden={!existentesTestemunhos}
      >
        <div className="container">
          <div className="text-center">
            <h5 className="section-title ff-secondary text-center text-primary fw-normal">
              Testemunhos
            </h5>
            <h1 className="mb-5">O que as pessoas contam!</h1>
          </div>
          <div className="owl-carousel testimonial-carousel text-start">
            {testemunhos?.map((t) => (
              <div
                className="testimonial-item bg-transparent border rounded p-4 mb-4"
                key={t.id}
              >
                <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                <p>{t.descricao}</p>
                <div className="d-flex align-items-center">
                  <img
                    className="img-fluid flex-shrink-0 rounded-circle"
                    src={smiling}
                    alt="Menina"
                    style={{ width: "50px", height: "50px" }}
                  />

                  <div className="ps-3">
                    <h5 className="mb-1">{t.nome}</h5>
                    <small>{dateFormat(t.created_at, "dd-mm-yyyy")}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* -------------- O QUE AS PESSOAS CONTAM -------------- */}

      {/* ------- ------- FOOTER ------- ------- */}
      <Footer />
      {/* ------- ------- FOOTER ------- ------- */}

      {/* <BackTop /> */}

      <Snackbar
        key={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          <>
            <EmojiEmotionsIcon className="text-primary" /> A sua sugestão foi
            enviada com sucesso!
          </>
        }
      />
    </div>
  );
}
