import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Button,
  createTheme,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import testemunho from "../img/happy.jpg";
import { CloseButton, Spinner } from "react-bootstrap";
import SnackBar from "./snackbar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});

const Formulario = (props) => {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  /* --------------------DADOS DO FORMULÁRIO---------------------- */

  const [loader, setLoader] = useState(false);
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");

  const dados = {
    nome: nome,
    descricao: descricao,
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorNome === false && errorDesc === false) {
      salvar();
    } else {
      console.log("N");
    }
  };

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorDesc, setErrorDesc] = useState(false);
  const [errorDescMessage, setErrorDescMessage] = useState("");

  const requiredFields = () => {
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }

    if (descricao === "" || descricao === undefined) {
      setErrorDesc(true);
      setErrorDescMessage("Por favor, preencha este campo!");
    } else {
      setErrorDesc(false);
      setErrorDescMessage(true);
    }
  };

  const salvar = () => {
    //console.log(dados);
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        nome: dados.nome,
        descricao: dados.descricao,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          saveEvent(
            usuarioLogado.usuario,
            "Registo",
            `Registou o testemunho de  "${dados.nome}".`
          );
          setOpen(true);
        }
        fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos")
          .then((response) => response.json())
          .then((data) => {
            setLoader(false);
            setNome("");
            setDescricao("");
            props.closeModal();
            props.setTestemunhos(data.data);
          });
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const saveEvent = (user, event, description) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: event,
        descricao: description,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };
  return (
    <>
      <Modal
        show={props.show}
        style={{ marginTop: 5 }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className="">
            {" "}
            <AssignmentIcon className="text-light" />{" "}
            <span className="text-light" style={{ fontSize: 21 }}>
              Registo de testemunho
            </span>
          </Modal.Title>
          <CloseButton variant="white" onClick={props.closeModal} />
        </Modal.Header>
        <Modal.Body>
          <div className="row  div-detalhes mt-1">
            <div
              className="col-md-6 wow fadeIn text-center"
              data-wow-delay="0.1s"
            >
              <img
                src={testemunho}
                alt="Holding hands"
                style={{ width: "95%", height: "95%" }}
              />
            </div>
            <div className="col-md-6">
              <div className="wow fadeInUp mt-2" data-wow-delay="0.2s">
                <form onSubmit={formSubmit}>
                  <div className="row g-1">
                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          size={"small"}
                          fullWidth
                          name="nome"
                          label="Nome Completo"
                          className="mb-4"
                          type={"text"}
                          id="nome"
                          value={nome}
                          defaultValue={""}
                          error={errorNome}
                          helperText={errorNomeMessage}
                          onChange={(e) => setNome(e.target.value)}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="descricao"
                          label="Descrição"
                          multiline
                          rows={5}
                          type={"text"}
                          id="descricao"
                          value={descricao}
                          defaultValue={descricao}
                          error={errorDesc}
                          helperText={errorDescMessage}
                          onChange={(e) => setDescricao(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-12 text-end">
                      <div className="">
                        {/*                 <Button
                  variant="contained"
                  className="me-1 bg-danger text-light fw-bold"
                  //onClick={() => salvar()}
                  style={{ width: "49%" }}
                  onClick={props.closeModal}
                >
                  <CancelIcon className="me-2" fontSize="small" />
                  Cancelar
                </Button> */}

                        <Button
                          variant="contained"
                          type="submit"
                          className="ms-1 bg-primary text-light fw-bold mb-2"
                          onClick={() => requiredFields()}
                          style={{ width: 140 }}
                          disabled={loader}
                        >
                          {loader ? (
                            <Spinner
                              animation="border"
                              style={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <Icon
                              className="me-2"
                              icon="fluent:save-arrow-right-20-regular"
                              color="white"
                              width="24"
                              height="24"
                              inline={true}
                            />
                          )}
                          Salvar
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={"Testemunho registado com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />
    </>
  );
};

export default Formulario;
