import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./footer";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { Button, Container } from "@material-ui/core";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import Sidebar from "./appbarAdmin";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import LaunchIcon from "@material-ui/icons/Launch";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Spinner } from "react-bootstrap";
import SnackBar from "./snackbar";
import dateFormat from "dateformat";
import Confirmacao from "./confirmarOperacao";
import EventIcon from "@material-ui/icons/Event";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import logoprov from "../img/logoprov.png";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(11),
    backgroundColor: "",
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
}));

export default function Detalhes(props) {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  const classes = useStyles();
  const navigate = useNavigate();
  const [openSide, setOpenSide] = useState(false);
  const [loading, setLoading] = useState(true);
  /* ------------------------------------------ */
  const { id } = useParams();
  const [detalhe, setDetalhe] = useState();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/show/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setDetalhe(data.data);
        setLoading(false);
      });
  }, [id]);

  const [approving, setApproving] = useState(false);
  const [titulo, setTitulo] = useState("aprovar");
  const [rejecting, setRejecting] = useState(false);
  const [closing, setClosing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fixing, setFixing] = useState(false);
  const [fixing1, setFixing1] = useState(false);
  const [titulo1, setTitulo1] = useState("");
  const [titulo2, setTitulo2] = useState("");

  const [mensagem, setMensagem] = useState();
  const [open, setOpen] = useState();
  const closeSnackbar = () => {
    setOpen(false);
  };

  /* -------------------------------- */
  const aprovar = () => {
    setApproving(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: id,
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/mudarEstado", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/show/${id}`)
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              titulo2,
              `${titulo1} o caso  "${detalhe.d_curta}", de ${detalhe.nome}.`
            );
            setApproving(false);
            setShowApprove(false);
            setOpen(true);
            setMensagem("Pedido aprovado com sucesso!");
            setDetalhe(data.data);
          });
      });
  };

  const rejeitar = () => {
    setRejecting(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: id,
        estado: "Rejeitado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/mudarEstado", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/show/${id}`)
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Rejeicão",
              `Reprovou o caso  "${detalhe.d_curta}", de ${detalhe.nome}.`
            );
            setRejecting(false);
            setShowReject(false);
            setOpen(true);
            setMensagem("Pedido rejeitado com sucesso!");
            setDetalhe(data.data);
            //window.location.reload(false);
          });
      });
  };

  const fechar = () => {
    setClosing(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: id,
        estado: "Fechado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/mudarEstado", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/show/${id}`)
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Encerramento",
              `Fechou o caso "${detalhe.d_curta}", de ${detalhe.nome}.`
            );
            setClosing(false);
            setShowClose(false);
            setOpen(true);
            setMensagem("Pedido fechado com sucesso!");
            setDetalhe(data.data);
          });
      });
  };

  const apagar = () => {
    setDeleting(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/delete/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        saveEvent(
          usuarioLogado.usuario,
          "Eliminação",
          `Apagou o caso "${detalhe.d_curta}", de ${detalhe.nome}.`
        );
        setDeleting(false);
        setShowDelete(false);
        setOpen(true);
        setMensagem("Pedido eliminado com sucesso!");
        navigate(-1);
        setDetalhe(data.data);
      });
  };

  const afixar = () => {
    setFixing(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/afixar/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/show/${id}`)
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Destaque",
              `Afixou o caso  "${detalhe.d_curta}", de ${detalhe.nome}.`
            );
            setFixing(false);
            setOpen(true);
            setMensagem("Pedido afixado com sucesso!");
            setDetalhe(data.data);
          });
      });
  };

  const desafixar = () => {
    setFixing1(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(
      `https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/desafixar/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/show/${id}`)
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Desafixação",
              `Desafixou o caso  "${detalhe.d_curta}", de ${detalhe.nome}.`
            );
            setFixing1(false);
            setOpen(true);
            setMensagem("Pedido desafixado com sucesso!");
            setDetalhe(data.data);
          });
      });
  };

  /* ----------MODAL - APROVAR-------- */
  const [showApprove, setShowApprove] = useState(false);
  const openModalApprove = () => {
    setShowApprove(true);
  };
  const closeModalApprove = () => {
    setShowApprove(false);
  };

  /* ----------MODAL - REJEITAR-------- */
  const [showReject, setShowReject] = useState(false);
  const openModalReject = () => {
    setShowReject(true);
  };
  const closeModalReject = () => {
    setShowReject(false);
  };

  /* ----------MODAL - FECHAR-------- */
  const [showClose, setShowClose] = useState(false);
  const openModalClose = () => {
    setShowClose(true);
  };
  const closeModalClose = () => {
    setShowClose(false);
  };

  /* ----------MODAL - APAGAR-------- */
  const [showDelete, setShowDelete] = useState(false);
  const openModalDelete = () => {
    setShowDelete(true);
  };
  const closeModalDelete = () => {
    setShowDelete(false);
  };

  const saveEvent = (user, event, description) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: event,
        descricao: description,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };
  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        {/* End hero unit */}
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h2 className="section-title ff-secondary text-center text-primary fw-normal mb-3">
            Detalhes - {detalhe?.tipo_caso === "Projecto" && "Ideia"}{" "}
            {detalhe?.tipo_caso === "Saude" && "Saúde"}{" "}
            {detalhe?.tipo_caso === "Estudos" && "Estudos"}{" "}
            {detalhe?.tipo_caso === "Denuncia" && "Denúncia"}
          </h2>
          <h3 className="mb-2">{props.categoria}</h3>
        </div>
        {loading ? (
          <div className="  text-center d-flex w-100 align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <div className="container-xxl py-2 mt-5">
            <div className="container">
              <div className="row g-4" style={{ marginTop: -50 }}>
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                  <a
                    href={
                      detalhe?.img_link === "Sem imagem" ||
                      detalhe?.img_link === "capa.png"
                        ? logoprov
                        : detalhe?.img_link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        detalhe?.img_link === "Sem imagem" ||
                        detalhe?.img_link === "capa.png"
                          ? logoprov
                          : detalhe?.img_link
                      }
                      alt={detalhe?.nome}
                      title={detalhe?.nome}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </a>
                </div>
                <div className="col-md-6 text-start">
                  <div className="wow fadeInUp" data-wow-delay="0.2s">
                    <form>
                      <div className="row g-3">
                        <div className="col-12 text-center">
                          <h3>{detalhe?.nome}</h3>
                        </div>

                        <div className="col-md-4">
                          <small className="fw-bold text-primary">
                            Morada:{" "}
                          </small>
                        </div>
                        <div className="col-md-8">
                          <LocationOnIcon
                            className="me-1"
                            style={{ fontSize: 17, marginTop: -2 }}
                          />
                          {detalhe?.morada}
                        </div>
                        <div className="col-md-4">
                          <small className="fw-bold text-primary">
                            Contacto:{" "}
                          </small>
                        </div>
                        <div className="col-md-8">
                          <LocalPhoneIcon
                            className="me-1"
                            style={{ fontSize: 17, marginTop: -2 }}
                          />
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              opacity: 0.7,
                            }}
                            title="Entrar em contacto"
                            href={"tel:+258" + detalhe?.contacto}
                          >
                            {detalhe?.contacto}
                          </a>{" "}
                          /{" "}
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              opacity: 0.7,
                            }}
                            title="Entrar em contacto"
                            href={"tel:+258" + detalhe?.contacto_op}
                          >
                            {detalhe?.contacto_op}
                          </a>{" "}
                        </div>

                        <div className="col-md-4">
                          <small className="fw-bold text-primary">
                            Breve descrição:{" "}
                          </small>
                        </div>
                        <div className="col-md-8">
                          <AccessibilityNewIcon
                            className="me-1"
                            style={{ fontSize: 17, marginTop: -2 }}
                          />
                          {detalhe?.d_curta}
                        </div>

                        <div className="col-md-12">
                          <small className="fw-bold text-primary">
                            História:{" "}
                          </small>
                        </div>
                        <div className="col-md-12">{detalhe?.d_longa}</div>

                        <div className="col-md-4">
                          <small className="fw-bold text-primary">
                            Data de submissão:{" "}
                          </small>
                        </div>
                        <div className="col-md-8">
                          <EventIcon
                            className="me-1"
                            style={{ fontSize: 17, marginTop: -2 }}
                          />
                          {dateFormat(detalhe?.created_at, "dd/mm/yyyy")}
                        </div>

                        {/* <div className="col-md-4">
                          <small className="fw-bold text-primary">
                            Responsável pelo pedido:{" "}
                          </small>
                        </div>
                        <div className="col-md-8">{detalhe?.usuario}</div> */}

                        <div className="col-md-4">
                          <small className="fw-bold text-primary">
                            Estado:{" "}
                          </small>
                        </div>
                        <div className="col-md-8">
                          {detalhe.estado === "Pendente" && (
                            <>
                              <span className="text-primary fw-bold">
                                <HourglassEmptyIcon
                                  className="me-1"
                                  style={{ fontSize: 17, marginTop: -2 }}
                                />

                                {detalhe?.estado}
                              </span>
                            </>
                          )}

                          {detalhe.estado === "Aprovado" && (
                            <>
                              <span className="text-success fw-bold">
                                <CheckCircleOutlineIcon
                                  className="me-1"
                                  style={{ fontSize: 17, marginTop: -2 }}
                                />

                                {detalhe?.estado}
                              </span>
                            </>
                          )}

                          {detalhe.estado === "Rejeitado" && (
                            <>
                              <span className="text-danger fw-bold">
                                <ThumbDownIcon
                                  className="me-1"
                                  style={{ fontSize: 17, marginTop: -2 }}
                                />

                                {detalhe?.estado}
                              </span>
                            </>
                          )}

                          {detalhe.estado === "Fechado" && (
                            <>
                              <span className="text-dark fw-bold">
                                <BlockIcon
                                  className="me-1"
                                  style={{ fontSize: 17, marginTop: -2 }}
                                />

                                {detalhe?.estado}
                              </span>
                            </>
                          )}
                        </div>

                        <div className="divider"></div>

                        <div className="">
                          {detalhe?.estado === "Pendente" && (
                            <div className="d-flex w-100 justify-content-between text-center">
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-danger p-2 ps-5 pe-5 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => openModalReject()}
                                  startIcon={<ThumbDownAltIcon />}
                                >
                                  Rejeitar
                                </Button>
                              </div>
                              <div className=" ">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-success p-2 ps-5 pe-5 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => {
                                    setTitulo("aprovar");
                                    setTitulo1("Aprovou");
                                    setTitulo2("Aprovação");
                                    openModalApprove();
                                  }}
                                  startIcon={<CheckCircleIcon />}
                                >
                                  Aprovar
                                </Button>
                              </div>
                            </div>
                          )}

                          {detalhe?.estado === "Aprovado" && (
                            <div className="d-flex w-100 justify-content-between text-center">
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-danger p-2 ps-5 pe-5 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => openModalClose()}
                                  startIcon={<BlockIcon />}
                                >
                                  Fechar
                                </Button>
                              </div>

                              <div className="w-50 text-center">
                                {detalhe.afixado === "Nao" ? (
                                  <Button
                                    size="small"
                                    color="primary"
                                    className="fw-bold p-2 ps-5 pe-5 actions-button"
                                    style={{
                                      textTransform: "capitalize",
                                      color: "#3f51b5",
                                    }}
                                    onClick={() => afixar()}
                                    disabled={fixing}
                                    startIcon={
                                      fixing ? (
                                        <Spinner
                                          animation="border"
                                          style={{ width: 24, height: 24 }}
                                        />
                                      ) : (
                                        <Icon
                                          icon="bi:pin-fill"
                                          width="16"
                                          height="16"
                                        />
                                      )
                                    }
                                  >
                                    Afixar
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    color="primary"
                                    className="fw-bold p-2 ps-5 pe-5 actions-button"
                                    style={{
                                      textTransform: "capitalize",
                                      color: "#3f51b5",
                                    }}
                                    onClick={() => desafixar()}
                                    disabled={fixing1}
                                    startIcon={
                                      fixing1 ? (
                                        <Spinner
                                          animation="border"
                                          style={{ width: 24, height: 24 }}
                                        />
                                      ) : (
                                        <Icon
                                          icon="ic:baseline-pin-off"
                                          width="16"
                                          height="16"
                                        />
                                      )
                                    }
                                  >
                                    Desafixar
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}

                          {detalhe?.estado === "Rejeitado" && (
                            <div className="d-flex w-100 justify-content-between text-center">
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-danger p-2 ps-5 pe-5 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => openModalDelete(detalhe?.id)}
                                  startIcon={<DeleteIcon />}
                                >
                                  Apagar
                                </Button>
                              </div>
                              <div className=" ">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-success p-2 ps-5 pe-5 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => {
                                    setTitulo("reaprovar");
                                    setTitulo1("Reaprovou");
                                    setTitulo2("Reaprovação");
                                    openModalApprove();
                                  }}
                                  startIcon={<DoneAllIcon />}
                                >
                                  Reaprovar
                                </Button>
                              </div>
                            </div>
                          )}

                          {detalhe?.estado === "Fechado" && (
                            <div className="d-flex w-100 justify-content-center text-center">
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-success p-2 ps-5 pe-5 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => {
                                    setTitulo("reabrir");
                                    setTitulo1("Reabriu");
                                    setTitulo2("Reabertura");
                                    openModalApprove();
                                  }}
                                  startIcon={<LaunchIcon />}
                                >
                                  Reabrir
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={mensagem}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />

      {/* ---------CONFIRMAÇÃO DE APROVAÇÃO DE CASO--------- */}
      <Confirmacao
        show={showApprove}
        closeModal={closeModalApprove}
        closeModal1={() => aprovar()}
        confirmar={"Confirmar"}
        iconn={
          approving ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <CheckCircleIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={`Tem certeza que pretende ${titulo} o caso?`}
        color1="text-danger"
      />

      {/* ---------CONFIRMAÇÃO DE REJEIÇÃO DE CASO--------- */}
      <Confirmacao
        show={showReject}
        closeModal={closeModalReject}
        closeModal1={() => rejeitar()}
        confirmar={"Confirmar"}
        iconn={
          rejecting ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <ThumbDownAltIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={"Tem certeza que pretende rejeitar o caso?"}
        color1="text-danger"
      />

      {/* ---------CONFIRMAÇÃO DE FECHAMENTO DE CASO--------- */}
      <Confirmacao
        show={showClose}
        closeModal={closeModalClose}
        closeModal1={() => fechar()}
        confirmar={"Confirmar"}
        iconn={
          closing ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <BlockIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={"Tem certeza que pretende fechar o caso?"}
        color1="text-danger"
      />

      {/* ---------CONFIRMAAÇÃO DE ELIMINAÇÃO DE CASO--------- */}
      <Confirmacao
        show={showDelete}
        closeModal={closeModalDelete}
        closeModal1={() => apagar()}
        confirmar={"Confirmar"}
        iconn={
          deleting ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <DeleteOutlineIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={"Tem certeza que pretende eliminar a publicação?"}
        color1="text-danger"
      />

      <Footer />
    </div>
  );
}
