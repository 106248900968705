import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import nothing22 from "../img/nothingWaw.gif";
import Footer from "./footer";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import LaunchIcon from "@material-ui/icons/Launch";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Delete";
import searchGif from "../img/searchGif.gif";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import logoprov from "../img/logoprov.png";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "./appbarAdmin";
import SnackBar from "./snackbar";
import { Spinner } from "react-bootstrap";
import Confirmacao from "./confirmarOperacao";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FilterListIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
}));

export default function Admin(props) {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));
  const currentURL = window.location.href;
  const rota = currentURL.split("/").pop();

  const classes = useStyles();
  const [openSide, setOpenSide] = useState(false);
  const [casoID, setCasoID] = useState();
  const [approving, setApproving] = useState(false);
  const [titulo, setTitulo] = useState("aprovar");
  const [titulo1, setTitulo1] = useState("");
  const [titulo2, setTitulo2] = useState("");
  const [rejecting, setRejecting] = useState(false);
  const [closing, setClosing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  /* ------DADOS PARA SALVAR OPERAÇAO */
  const [nomeEvent, setNomeEvent] = useState();
  const [descEvent, setDescEvent] = useState();

  const [mensagem, setMensagem] = useState();
  const [open, setOpen] = useState();
  const closeSnackbar = () => {
    setOpen(false);
  };

  const aprovar = () => {
    setApproving(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: casoID,
        estado: "Aprovado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/mudarEstado", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        saveEvent(
          usuarioLogado.usuario,
          titulo2,
          `${titulo1} o caso  "${descEvent}", de ${nomeEvent}.`
        );
        setApproving(false);
        setShowApprove(false);
        setOpen(true);
        setMensagem("Pedido aprovado com sucesso!");
        props.setDados();
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const rejeitar = () => {
    setRejecting(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: casoID,
        estado: "Rejeitado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/mudarEstado", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        saveEvent(
          usuarioLogado.usuario,
          "Rejeicão",
          `Reprovou o caso  "${descEvent}", de ${nomeEvent}.`
        );
        setRejecting(false);
        setShowReject(false);
        setOpen(true);
        setMensagem("Pedido rejeitado com sucesso!");
        props.setDados();
      });
  };

  const fechar = () => {
    setClosing(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: casoID,
        estado: "Fechado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/mudarEstado", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        saveEvent(
          usuarioLogado.usuario,
          "Encerramento",
          `Fechou o caso "${descEvent}", de ${nomeEvent}.`
        );
        setClosing(false);
        setShowClose(false);
        setOpen(true);
        setMensagem("Pedido fechado com sucesso!");
        props.setDados();
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const apagar = () => {
    setDeleting(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(
      `https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/delete/${casoID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        saveEvent(
          usuarioLogado.usuario,
          "Eliminação",
          `Apagou o caso "${descEvent}", de ${nomeEvent}.`
        );
        setDeleting(false);
        setShowDelete(false);
        setOpen(true);
        setMensagem("Pedido eliminado com sucesso!");
        props.setDados();
      });
  };

  const afixar = (id, nom, desc) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/afixar/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        saveEvent(
          usuarioLogado.usuario,
          "Destaque",
          `Afixou o caso  "${desc}", de ${nom}.`
        );
        setOpen(true);
        setMensagem("Pedido afixado com sucesso!");
        props.setDados();
      });
  };

  const desafixar = (id, nom, desc) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(
      `https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/desafixar/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        saveEvent(
          usuarioLogado.usuario,
          "Desafixação",
          `Desafixou o caso  "${desc}", de ${nom}.`
        );
        setOpen(true);
        setMensagem("Pedido desafixado com sucesso!");
        props.setDados();
      });
  };

  /* ----------MODAL - APROVAR-------- */
  const [showApprove, setShowApprove] = useState(false);
  const openModalApprove = (id, nome, desc) => {
    setCasoID(id);
    setNomeEvent(nome);
    setDescEvent(desc);
    setShowApprove(true);
  };
  const closeModalApprove = () => {
    setShowApprove(false);
  };

  /* ----------MODAL - REJEITAR-------- */
  const [showReject, setShowReject] = useState(false);
  const openModalReject = (id, nome, desc) => {
    setCasoID(id);
    setNomeEvent(nome);
    setDescEvent(desc);
    setShowReject(true);
  };
  const closeModalReject = () => {
    setShowReject(false);
  };

  /* ----------MODAL - FECHAR-------- */
  const [showClose, setShowClose] = useState(false);
  const openModalClose = (id, nome, desc) => {
    setCasoID(id);
    setNomeEvent(nome);
    setDescEvent(desc);
    setShowClose(true);
  };
  const closeModalClose = () => {
    setShowClose(false);
  };

  /* ----------MODAL - APAGAR-------- */
  const [showDelete, setShowDelete] = useState(false);
  const openModalDelete = (id, nome, desc) => {
    setCasoID(id);
    setNomeEvent(nome);
    setDescEvent(desc);
    setShowDelete(true);
  };
  const closeModalDelete = () => {
    setShowDelete(false);
  };
  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [filter, setFilter] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searching1, setSearching1] = useState(false);

  const [searchNome, setSearchNome] = useState();
  const [searchDescricao, setSearchDescricao] = useState();

  const searchByNome = (nome) => {
    setSearching(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: props.tipoCaso,
        estado: props.estado,
        nome: nome,
      }),
    };
    fetch(
      "https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAllName",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setSearching(false);
        props.setResultado(data);
        if (data?.length === 0) {
          props.setExistentes1(true);
        } else {
          props.setExistentes1(false);
        }
      });
  };

  const searchByDescricao = (descricao) => {
    setSearching1(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: props.tipoCaso,
        estado: props.estado,
        d_curta: descricao,
      }),
    };
    fetch(
      "https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAllDesc",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setSearching1(false);
        props.setResultado(data);
        if (data?.length === 0) {
          props.setExistentes1(true);
        } else {
          props.setExistentes1(false);
        }
      });
  };

  const searchByNome1 = (nome) => {
    setSearching(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        estado: "Pendente",
        nome: nome,
      }),
    };
    fetch(
      "https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByNameAndEstado",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setSearching(false);
        props.setResultado(data);
        if (data?.length === 0) {
          props.setExistentes1(true);
        } else {
          props.setExistentes1(false);
        }
      });
  };

  const searchByDescricao1 = (descricao) => {
    setSearching1(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        estado: "Pendente",
        d_curta: descricao,
      }),
    };
    fetch(
      "https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByDescAndEstado",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setSearching1(false);
        props.setResultado(data);
        if (data?.length === 0) {
          props.setExistentes1(true);
        } else {
          props.setExistentes1(false);
        }
      });
  };

  const saveEvent = (user, event, description) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: event,
        descricao: description,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  var snackState = JSON.parse(localStorage.getItem("editado"));
  const [openEditado, setOpenEditado] = useState(snackState);
  const handleCloseEditado = (event, reason) => {
    setOpenEditado(false);
    localStorage.setItem("editado", JSON.stringify(false));
  };

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        {/* End hero unit */}
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h2 className="section-title ff-secondary text-center text-primary fw-normal mb-3">
            {props.titulo}
          </h2>
          <h3 className="mb-2">{props.categoria}</h3>
        </div>
        <div className="w-100 text-end mb-2" hidden={!props.existentes}>
          <Button
            name="Basic button"
            value="default"
            size="medium"
            onClick={() => setFilter(!filter)}
            aria-expanded={filter}
            aria-controls="collapse-filter"
            title="Filtrar"
          >
            <FilterListIcon style={{ fontSize: 32, color: "#ea8b0f" }} />
            {filter ? (
              <ArrowDropUpIcon style={{ color: "#ea8b0f" }} />
            ) : (
              <ArrowDropDownIcon style={{ color: "#ea8b0f" }} />
            )}
          </Button>
        </div>
        <Collapse in={filter} timeout="auto" unmountOnExit disablePadding>
          <div className="row g-4 mb-4 d-flex justify-content-end me-2">
            <div
              className="position-relative col-md-6"
              style={{ maxWidth: "320px", marginTop: 20 }}
            >
              <input
                className="form-control border-primary py-3 ps-4 pe-5"
                type="text"
                placeholder="Pesquisar por nome"
                style={{ height: 45 }}
                value={searchNome}
                onChange={(e) => setSearchNome(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search "
                style={{ width: 55, height: 45 }}
                disabled={searching}
                onClick={() =>
                  rota === "casosPendentes"
                    ? searchNome
                      ? searchByNome1(searchNome)
                      : props.setDados()
                    : searchNome
                    ? searchByNome(searchNome)
                    : props.setDados()
                }
              >
                {searching ? (
                  <Spinner
                    animation="border"
                    style={{ width: 24, height: 24 }}
                  />
                ) : (
                  <SearchIcon
                    className="text-light btn-search"
                    style={{ marginTop: -5 }}
                  />
                )}
              </button>
            </div>
            <div
              className="position-relative col-md-6"
              style={{ maxWidth: "320px", marginTop: 20 }}
            >
              <input
                className="form-control border-primary py-3 ps-4 pe-5"
                type="text"
                placeholder="Pesquisar por título"
                style={{ height: 45 }}
                value={searchDescricao}
                onChange={(e) => setSearchDescricao(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-primary pt-2 position-absolute top-0 end-0  justify-content-center btn-search "
                style={{ width: 55, height: 45 }}
                disabled={searching1}
                onClick={() =>
                  rota === "casosPendentes"
                    ? searchDescricao
                      ? searchByDescricao1(searchDescricao)
                      : props.setDados()
                    : searchDescricao
                    ? searchByDescricao(searchDescricao)
                    : props.setDados()
                }
              >
                {searching1 ? (
                  <Spinner
                    animation="border"
                    style={{ width: 24, height: 24 }}
                  />
                ) : (
                  <SearchIcon
                    className="text-light btn-search"
                    style={{ marginTop: -5 }}
                  />
                )}
              </button>
            </div>
          </div>
        </Collapse>
        {props.loading ? (
          <div className="  text-center d-flex w-100 align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <>
            {props.existentes ? (
              <Grid container spacing={4}>
                {props?.lista
                  ?.sort(function (a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                  })
                  .map((caso) => (
                    <Grid item key={caso.id} xs={12} sm={6} md={4}>
                      <Card className={classes.card}>
                        <div className="row g-3">
                          <div className="col-6 text-center p-3">
                            <span hidden={rota !== "casosPendentes"}>
                              <h6 className="text-primary">
                                {" "}
                                {caso.tipo_caso === "Projecto" && "Ideia"}{" "}
                                {caso.tipo_caso === "Saude" && "Saúde"}{" "}
                                {caso.tipo_caso === "Estudos" && "Estudos"}{" "}
                                {caso.tipo_caso === "Denuncia" && "Denúncia"}{" "}
                              </h6>
                            </span>
                          </div>

                          <div className="col-6 text-end dropdownrounded">
                            <Button
                              id="dropdownMenu2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <MoreVertIcon style={{ color: "#ea8b0f" }} />
                            </Button>
                            <ul
                              className="dropdown-menu detalhes-menu"
                              aria-labelledby="dropdownMenu2"
                            >
                              <NavLink to={"/detalhesAdmin/" + caso.id}>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    Detalhes
                                  </button>
                                </li>
                              </NavLink>

                              <Link
                                to={"/editarCaso/" + caso.id}
                                state={{ state: caso }}
                              >
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    Editar
                                  </button>
                                </li>
                              </Link>
                            </ul>
                          </div>
                        </div>

                        <CardMedia
                          className="sub-link centerr"
                          style={{ height: "170px" }}
                        >
                          <a
                            href={
                              caso.img_link === "Sem imagem" ||
                              caso.img_link === "capa.png"
                                ? logoprov
                                : caso.img_link
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                caso.img_link === "Sem imagem" ||
                                caso.img_link === "capa.png"
                                  ? logoprov
                                  : caso.img_link
                              }
                              alt={caso.nome}
                              title={caso.nome}
                              style={{ width: "180px", height: "180px" }}
                            />
                          </a>
                        </CardMedia>

                        <CardContent className={classes.cardContent}>
                          <h5 className="fw-bold">{caso.nome}</h5>
                          <Typography>{caso.d_curta}</Typography>
                        </CardContent>
                        <div className="divider"></div>
                        <CardActions className="">
                          {caso.estado === "Pendente" && (
                            <div className="d-flex w-100 justify-content-between text-center">
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-danger p-2 w-100 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() =>
                                    openModalReject(
                                      caso.id,
                                      caso.nome,
                                      caso.d_curta
                                    )
                                  }
                                  startIcon={<ThumbDownAltIcon />}
                                >
                                  Rejeitar
                                </Button>
                              </div>
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-success p-2 w-100 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => {
                                    setTitulo("aprovar");
                                    setTitulo1("Aprovou");
                                    setTitulo2("Aprovação");
                                    openModalApprove(
                                      caso.id,
                                      caso.nome,
                                      caso.d_curta
                                    );
                                  }}
                                  startIcon={<CheckCircleIcon />}
                                >
                                  Aprovar
                                </Button>
                              </div>
                            </div>
                          )}

                          {caso.estado === "Aprovado" && (
                            <div className="d-flex w-100 justify-content-between text-center">
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-danger p-2 w-100 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() =>
                                    openModalClose(
                                      caso.id,
                                      caso.nome,
                                      caso.d_curta
                                    )
                                  }
                                  startIcon={<BlockIcon />}
                                >
                                  Fechar
                                </Button>
                              </div>

                              <div className="w-50 text-center">
                                {caso.afixado === "Nao" ? (
                                  <Button
                                    size="small"
                                    color="primary"
                                    className="fw-bold p-2 w-100 actions-button"
                                    style={{
                                      textTransform: "capitalize",
                                      color: "#3f51b5",
                                    }}
                                    onClick={() =>
                                      afixar(caso.id, caso.nome, caso.d_curta)
                                    }
                                    startIcon={
                                      /* fixing ? (
                                      <Spinner
                                        animation="border"
                                        style={{ width: 24, height: 24 }}
                                      />
                                    ) : ( */
                                      <Icon
                                        icon="bi:pin-fill"
                                        width="16"
                                        height="16"
                                      />
                                      /* ) */
                                    }
                                  >
                                    Afixar
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    color="primary"
                                    className="fw-bold p-2 w-100 actions-button"
                                    style={{
                                      textTransform: "capitalize",
                                      color: "#3f51b5",
                                    }}
                                    onClick={() =>
                                      desafixar(
                                        caso.id,
                                        caso.nome,
                                        caso.d_curta
                                      )
                                    }
                                    startIcon={
                                      /* fixing1 ? (
                                      <Spinner
                                        animation="border"
                                        style={{ width: 24, height: 24 }}
                                      />
                                    ) : ( */
                                      <Icon
                                        icon="ic:baseline-pin-off"
                                        width="16"
                                        height="16"
                                      />
                                      /* ) */
                                    }
                                  >
                                    Desafixar
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}

                          {caso.estado === "Rejeitado" && (
                            <div className="d-flex w-100 justify-content-between text-center">
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-danger p-2 w-100 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() =>
                                    openModalDelete(
                                      caso.id,
                                      caso.nome,
                                      caso.d_curta
                                    )
                                  }
                                  startIcon={<DeleteIcon />}
                                >
                                  Apagar
                                </Button>
                              </div>
                              <div className="w-50 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-success p-2 w-100 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => {
                                    setTitulo("reaprovar");
                                    setTitulo1("Reaprovou");
                                    setTitulo2("Reaprovação");
                                    openModalApprove(
                                      caso.id,
                                      caso.nome,
                                      caso.d_curta
                                    );
                                  }}
                                  startIcon={<DoneAllIcon />}
                                >
                                  Reaprovar
                                </Button>
                              </div>
                            </div>
                          )}

                          {caso.estado === "Fechado" && (
                            <div className="d-flex w-100 justify-content-center text-center">
                              <div className="w-100 text-center">
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold text-success p-2 w-100 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={() => {
                                    setTitulo("reabrir");
                                    setTitulo1("Reabriu");
                                    setTitulo2("Reabertura");
                                    openModalApprove(
                                      caso.id,
                                      caso.nome,
                                      caso.d_curta
                                    );
                                  }}
                                  startIcon={<LaunchIcon />}
                                >
                                  Reabrir
                                </Button>
                              </div>
                            </div>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <div className="  text-center d-inline-block w-100 align-items-center justify-content-center">
                <div>
                  <img
                    src={nothing22}
                    alt=""
                    title="Nada para mostrar"
                    style={{ width: "100px" }}
                  />
                </div>
                <span className="text-dark">Ups!!</span> <br />
                <span className="text-dark">{props.nada}</span>
              </div>
            )}

            {props.existentes1 && (
              <div className="mt-3 text-center d-inline-block w-100 align-items-center justify-content-center">
                {/* <span>{props.nada}</span> */}
                <div>
                  <img
                    src={searchGif}
                    alt=""
                    title="Nada para mostrar"
                    style={{ width: "170px" }}
                  />
                </div>
                <span className="text-secondary">
                  Nenhum resultado encontrado!
                </span>{" "}
              </div>
            )}
          </>
        )}
      </Container>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={mensagem}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />

      {/* ---------CONFIRMAÇÃO DE APROVAÇÃO DE CASO--------- */}
      <Confirmacao
        show={showApprove}
        closeModal={closeModalApprove}
        closeModal1={() => aprovar()}
        confirmar={"Confirmar"}
        iconn={
          approving ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <CheckCircleIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={`Tem certeza que pretende ${titulo} o caso?`}
        color1="text-danger"
      />

      {/* ---------CONFIRMAÇÃO DE REJEIÇÃO DE CASO--------- */}
      <Confirmacao
        show={showReject}
        closeModal={closeModalReject}
        closeModal1={() => rejeitar()}
        confirmar={"Confirmar"}
        iconn={
          rejecting ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <ThumbDownAltIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={"Tem certeza que pretende rejeitar o caso?"}
        color1="text-danger"
      />

      {/* ---------CONFIRMAÇÃO DE FECHAMENTO DE CASO--------- */}
      <Confirmacao
        show={showClose}
        closeModal={closeModalClose}
        closeModal1={() => fechar()}
        confirmar={"Confirmar"}
        iconn={
          closing ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <BlockIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={"Tem certeza que pretende fechar o caso?"}
        color1="text-danger"
      />

      {/* ---------CONFIRMAAÇÃO DE ELIMINAÇÃO DE CASO--------- */}
      <Confirmacao
        show={showDelete}
        closeModal={closeModalDelete}
        closeModal1={() => apagar()}
        confirmar={"Confirmar"}
        iconn={
          deleting ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <DeleteOutlineIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={"Tem certeza que pretende eliminar a publicação?"}
        color1="text-danger"
      />

      <Snackbar
        key={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openEditado}
        autoHideDuration={6000}
        onClose={handleCloseEditado}
        message={
          <>
            <DoneAllIcon className="me-1 text-primary" />
            Post actualizado com sucesso!
          </>
        }
      />

      <Footer />
    </div>
  );
}
