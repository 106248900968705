import React, { useEffect } from "react";
import Perfil from "../../../components/perfilAdmin";



const PerfilAdminPag = () => {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, []);
  return (
    <div >
      <Perfil />
    </div>
  );
};
export default PerfilAdminPag;

