import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./footer";
import {
  Button,
  Collapse,
  Container,
  TableContainer,
  Paper,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
  AccordionActions,
  Card,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddIcon from "@material-ui/icons/Add";
import Sidebar from "./appbarAdmin";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Formulario from "./formTestemunhos";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import Confirmacao from "./confirmarOperacao";
import { Icon } from "@iconify/react";
import { CloseButton, Modal, Spinner } from "react-bootstrap";
import { createTheme, TextField, ThemeProvider } from "@material-ui/core";
import testemunho from "../img/testemunho.jpg";
import SnackBar from "./snackbar";
import nothing22 from "../img/box32.png";
import searchGif from "../img/searchGif.gif";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "7%",
    flexShrink: 0,
    color: theme.palette.text.primary,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.dark,
  },
}));

export default function Testemunhos(props) {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  const classes = useStyles();
  const [openSide, setOpenSide] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [testemunhos, setTestemunhos] = useState();
  const [existentes, setExistentes] = useState(true);
  const [existentes1, setExistentes1] = useState(false);

  const fetchData = () => {
    setLoading(true);
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos")
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data);
        setTestemunhos(data.data);
        setLoading(false);
        if (data.data.length !== 0) {
          setExistentes(true);
        } else {
          setExistentes(false);
        }
      });
  };

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetchData();
  }, []);

  const [showForm, setShowForm] = useState(false);
  const closeForm = () => {
    setShowForm(false);
  };

  const [showEdit, setShowEdit] = useState(false);

  const openModalEdit = (id, nome, descricao) => {
    setCasoID(id);
    setNome(nome);
    setDescricao(descricao);
    setShowEdit(true);
  };

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = useState();

  const closeSnackbar1 = () => {
    setOpen1(false);
  };

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [loader, setLoader] = useState(false);
  const [casoID, setCasoID] = useState();
  const [nome, setNome] = useState();
  const [descricao, setDescricao] = useState();

  /* ------DADOS PARA SALVAR OPERAÇAO */
  const [nomeEvent, setNomeEvent] = useState();

  const dados = {
    nome: nome,
    descricao: descricao,
  };

  const formSubmit = (event) => {
    event.preventDefault();
    if (errorNome === false && errorDesc === false) {
      editar();
    } else {
      console.log("N");
    }
  };

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorDesc, setErrorDesc] = useState(false);
  const [errorDescMessage, setErrorDescMessage] = useState("");

  const requiredFields = () => {
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }

    if (descricao === "" || descricao === undefined) {
      setErrorDesc(true);
      setErrorDescMessage("Por favor, preencha este campo!");
    } else {
      setErrorDesc(false);
      setErrorDescMessage(true);
    }
  };

  const editar = () => {
    //console.log(dados);
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: casoID,
        nome: dados.nome,
        descricao: dados.descricao,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos/edit", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos")
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Edição",
              `Editou o testemunho de  "${dados.nome}".`
            );
            setLoader(false);
            setShowEdit(false);
            setOpen1(true);
            setTestemunhos(data.data);
          });
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [showDelete, setShowDelete] = useState(false);
  const openModalDelete = (id, nome) => {
    setCasoID(id);
    setNomeEvent(nome);
    setShowDelete(true);
  };
  const closeModalDelete = () => {
    setShowDelete(false);
  };

  const [deleting, setDeleting] = useState(false);

  const apagar = () => {
    setDeleting(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(
      `https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos/delete/${casoID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos")
          .then((response) => response.json())
          .then((data) => {
            saveEvent(
              usuarioLogado.usuario,
              "Eliminação",
              `Apagou o testemunho de  "${nomeEvent}".`
            );
            setDeleting(false);
            setShowDelete(false);
            setOpen(true);
            setTestemunhos(data.data);
          });
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  /* --------------------ACCORDION------------------------------------ */
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /* --------------------SEARCHING------------------------------------ */
  const [searching, setSearching] = useState(false);
  const [searchNome, setSearchNome] = useState();

  const searchByNome = (nome) => {
    setSearching(true);
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/testemunhos/searchByName/${nome}`)
      .then((response) => response.json())
      .then((data) => {
        setTestemunhos(data);
        setSearching(false);
        if (data.length === 0) {
          setExistentes1(true);
        } else {
          setExistentes1(false);
        }
      });
  };

  const saveEvent = (user, event, description) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: event,
        descricao: description,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        <div className="container-xxl py-2 mt-2">
          <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h2 className="section-title ff-secondary text-center text-primary fw-normal ">
                Testemunhos
              </h2>
              <h3 className="">Listagem de testemunhos</h3>
            </div>
            <div className="w-100 text-end mb-2">
              <Button
                hidden={!existentes}
                name="Basic button"
                value="default"
                size="medium"
                onClick={() => setFilter(!filter)}
                aria-expanded={filter}
                aria-controls="collapse-filter"
                title="Filtrar"
              >
                <FilterListIcon style={{ fontSize: 32, color: "#ea8b0f" }} />
                {filter ? (
                  <ArrowDropUpIcon style={{ color: "#ea8b0f" }} />
                ) : (
                  <ArrowDropDownIcon style={{ color: "#ea8b0f" }} />
                )}
              </Button>
              <Button
                variant="contained"
                className="me-1 bg-primary text-light fw-bold"
                startIcon={<AddIcon />}
                style={{ textTransform: "capitalize" }}
                onClick={() => setShowForm(true)}
              >
                Adicionar
              </Button>
            </div>
            <Collapse in={filter} timeout="auto" unmountOnExit disablePadding>
              <div className="row g-4 mb-2 d-flex justify-content-end ">
                <div
                  className="position-relative col-12 pe-3"
                  style={{ maxWidth: "320px", marginTop: 20 }}
                >
                  <input
                    className="form-control border-primary py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Pesquisar por nome"
                    style={{ height: 45 }}
                    value={searchNome}
                    onChange={(e) => setSearchNome(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary pt-2 position-absolute top-0 justify-content-center btn-search "
                    style={{ width: 55, height: 45, right: 15 }}
                    onClick={() =>
                      searchNome ? searchByNome(searchNome) : fetchData()
                    }
                    disabled={searching}
                  >
                    {searching ? (
                      <Spinner
                        animation="border"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : (
                      <SearchIcon
                        className="text-light btn-search"
                        style={{ marginTop: -5 }}
                      />
                    )}
                  </button>
                </div>
              </div>
            </Collapse>

            <TableContainer component={Paper}>
              {loading ? (
                <div className="  text-center d-flex w-100 align-items-center justify-content-center">
                  <div
                    className="spinner-border text-primary"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </div>
              ) : (
                <>
                  {existentes ? (
                    <>
                      {testemunhos?.map((t, index) => (
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                          className="text-start"
                          key={index}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={
                              expanded === `panel${index}`
                                ? "accordion-back"
                                : "accordion-title"
                            }
                          >
                            <Typography className={classes.heading}>
                              <Card
                                className="fw-bold text-primary bg-dark me-2 text-center align-items-center"
                                style={{ width: 20, height: 25 }}
                              >
                                {t.id}
                              </Card>
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                              {t.nome}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{t.descricao}</Typography>
                          </AccordionDetails>
                          <Divider />
                          <AccordionActions>
                            <Button
                              className="me-1 text-danger fw-bold ps-3 pe-3 accordion-buttons"
                              startIcon={<DeleteOutlineIcon />}
                              onClick={() => openModalDelete(t.id, t.nome)}
                            >
                              Apagar
                            </Button>

                            <Button
                              className="me-1 fw-bold ps-3 pe-3 accordion-buttons"
                              color="primary"
                              startIcon={<EditIcon />}
                              onClick={() => {
                                openModalEdit(t.id, t.nome, t.descricao);
                                setErrorNome(false);
                                setErrorDesc(false);
                              }}
                            >
                              Editar
                            </Button>
                          </AccordionActions>
                        </Accordion>
                      ))}
                    </>
                  ) : (
                    <div className="p-5 text-center d-inline-block w-100 align-items-center justify-content-center">
                      {/* <span>{props.nada}</span> */}
                      <div>
                        <img
                          src={nothing22}
                          alt=""
                          title="Nada para mostrar"
                          style={{ width: "170px" }}
                        />
                      </div>
                      <span className="text-secondary">
                        Nenhum testemunho registado!
                      </span>{" "}
                    </div>
                  )}
                  {existentes1 && (
                    <div className="mt-3 text-center d-inline-block w-100 align-items-center justify-content-center">
                      {/* <span>{props.nada}</span> */}
                      <div>
                        <img
                          src={searchGif}
                          alt=""
                          title="Nada para mostrar"
                          style={{ width: "170px" }}
                        />
                      </div>
                      <span className="text-secondary">
                        Nenhum resultado encontrado!
                      </span>{" "}
                    </div>
                  )}
                </>
              )}
            </TableContainer>
          </div>
        </div>
      </Container>

      <Formulario
        show={showForm}
        closeModal={closeForm}
        setTestemunhos={setTestemunhos}
      />

      <Confirmacao
        show={showDelete}
        closeModal={closeModalDelete}
        closeModal1={() => apagar()}
        confirmar={"Confirmar"}
        iconn={
          deleting ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <DeleteOutlineIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-2"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={"Tem certeza que pretende apagar este testemunho?"}
        color1="text-danger"
      />

      <Modal
        show={showEdit}
        style={{ marginTop: 5 }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className="">
            {" "}
            <EditIcon className="text-light" />{" "}
            <span className="text-light" style={{ fontSize: 21 }}>
              Editar testemunho
            </span>
          </Modal.Title>
          <CloseButton variant="white" onClick={() => setShowEdit(false)} />
        </Modal.Header>
        <Modal.Body>
          <div className="row g-4  div-detalhes mt-1">
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <img
                src={testemunho}
                alt="Holding hands"
                style={{ width: "95%", height: "95%" }}
              />
            </div>
            <div className="col-md-6">
              <div className="wow fadeInUp mt-2" data-wow-delay="0.2s">
                <form onSubmit={formSubmit}>
                  <div className="row g-1">
                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          size={"small"}
                          fullWidth
                          name="nome"
                          label="Nome Completo"
                          className="mb-4"
                          type={"text"}
                          id="nome"
                          value={nome}
                          defaultValue={nome}
                          error={errorNome}
                          helperText={errorNomeMessage}
                          onChange={(e) => setNome(e.target.value)}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-12">
                      <ThemeProvider theme={theme}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="descricao"
                          label="Descrição"
                          multiline
                          rows={5}
                          type={"text"}
                          id="descricao"
                          value={descricao}
                          defaultValue={descricao}
                          error={errorDesc}
                          helperText={errorDescMessage}
                          onChange={(e) => setDescricao(e.target.value)}
                          InputProps={{}}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-12 text-end">
                      <div className="">
                        <Button
                          variant="contained"
                          type="submit"
                          className="ms-1 bg-primary text-light fw-bold mb-1"
                          onClick={() => requiredFields()}
                          style={{ width: "49%" }}
                          disabled={loader}
                          startIcon={
                            loader ? (
                              <Spinner
                                animation="border"
                                style={{ width: 24, height: 24 }}
                              />
                            ) : (
                              <Icon
                                className="me-2"
                                icon="fluent:save-arrow-right-20-regular"
                                color="white"
                                width="24"
                                height="24"
                                inline={true}
                              />
                            )
                          }
                        >
                          Salvar
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={"Testemunho eliminado com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />

      <SnackBar
        open={open1}
        handleClose={closeSnackbar1}
        message={"Dados actualizados com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />

      <Footer />
    </div>
  );
}
