import React from "react";
import jquery from "jquery";
import WOW from "../lib/wow/wow";
import { Fab } from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default function BackTop(props) {
  (function ($) {
    "use strict";

    // Initiate the wowjs
    new WOW().init();

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $(".back-to-top").fadeIn("slow");
      } else {
        $(".back-to-top").fadeOut("slow");
      }
    });
  })(jquery);

  return (
    <div>
      <span
        className=" back-to-top"
        onClick={() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          })
        }
      >
        <Fab className="bg-primary text-white" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </span>
      
    </div>
  );
}
