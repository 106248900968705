import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./footer";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
  Paper,
  Fab,
  Avatar,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import SaveIcon from "@material-ui/icons/Save";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import Sidebar from "./appbarAdmin";
import SnackBar from "./snackbar";
import { Spinner } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import { storage } from "../firebase";
import { useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});
const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(11),
    backgroundColor: "",
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  floatingLabelFocusStyle: {
    "& .MuiFormLabel-root": {
      color: "#ea8b0f",
    },
  },
}));

export default function Perfil(props) {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  let navigate = useNavigate();
  const classes = useStyles();
  const [openSide, setOpenSide] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editable, setEditable] = useState(false);


  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [nome, setNome] = useState(usuarioLogado?.nome);
  const [username, setUsername] = useState(usuarioLogado?.usuario);
  const [senha, setSenha] = useState(usuarioLogado?.senha);
  const [email, setEmail] = useState(usuarioLogado?.email);
  const [contacto, setContacto] = useState(usuarioLogado?.contacto);
  const [tipo, setTipo] = useState(usuarioLogado?.tipo);
  const [filename, setFilename] = useState("");
  const [mensagem, setMensagem] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorUsername, setErrorUsername] = useState(false);
  const [errorUsernameMessage, setErrorUsernameMessage] = useState("");

  const [errorSenha, setErrorSenha] = useState(false);
  const [errorSenhaMessage, setErrorSenhaMessage] = useState("");

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorContacto, setErrorContacto] = useState(false);
  const [errorContactoMessage, setErrorContactoMessage] = useState("");

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const handleChangeTipo = (event) => {
    setTipo(event.target.value);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorUsername === false &&
      errorSenha === false &&
      errorContacto === false &&
      errorEmail === false
    ) {
      handleUpload();
    } else {
      console.log("N");
    }
  };

  const requiredFields = () => {
    const re = /^[0-9\b]+$/;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (username === "" || username === undefined) {
      setErrorUsername(true);
      setErrorUsernameMessage("Por favor, preencha este campo!");
    } else {
      setErrorUsername(false);
      setErrorUsernameMessage(true);
    }
    if (senha === "" || senha === undefined || senha?.length < 6) {
      setErrorSenha(true);
      setErrorSenhaMessage("Senha inválida, pelo menos 6 dígitos!");
    } else {
      setErrorSenha(false);
      setErrorSenhaMessage(true);
    }
    if (
      contacto === "" ||
      contacto?.length < 9 ||
      contacto === undefined ||
      !contacto?.match(re)
    ) {
      setErrorContacto(true);
      setErrorContactoMessage(
        "Número de telefone inválido, campo obrigatório!"
      );
    } else {
      setErrorContacto(false);
      setErrorContactoMessage(true);
    }
    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }
  };

  const salvar = (imgLink) => {
    const dados = {
      id: usuarioLogado?.id,
      nome: nome,
      senha: senha,
      email: email,
      usuario: username,
      contacto: contacto,
      img_link: imgLink,
      tipo: tipo,
      estado: usuarioLogado?.estado,
    };
    //console.log(dados);

    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        id: usuarioLogado?.id,
        nome: dados.nome,
        contacto: dados.contacto,
        email: dados.email,
        usuario: dados.username,
        senha: dados.senha,
        img_link: imgLink,
        tipo: dados.tipo,
        estado: dados.estado,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/usuarios/edit", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setMensagem("Dados actualizados com sucesso!");
        setEditable(!editable);
        localStorage.setItem("userLogado", JSON.stringify(dados));
        setOpen(true);

      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const cancelEdit = () => {
    setEditable(!editable);
    setNome(usuarioLogado?.nome);
    setSenha(usuarioLogado?.senha);
    setEmail(usuarioLogado?.email);
    setUsername(usuarioLogado?.userName);
    setContacto(usuarioLogado?.contacto);
    setUsername(usuarioLogado?.usuario);

    setErrorNome(false);
    setErrorNomeMessage("");

    setErrorUsername(false);
    setErrorUsernameMessage("");

    setErrorSenha(false);
    setErrorSenhaMessage("");

    setErrorContacto(false);
    setErrorContactoMessage("");

    setErrorEmail(false);
    setErrorEmailMessage("");
  };

  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState("Nenhuma foto seleccionada");
  const [progress, setProgress] = useState(0);

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImage1(e.target.files[0].name);
    }
  };

  const handleUpload = () => {
    setLoader(true);
/*     if (usuarioLogado?.img_link !== "Sem imagem") {
      var desertRef = storage.refFromURL(usuarioLogado?.img_link);

      // Delete the file
      desertRef
        .delete()
        .then(function () {
          console.log("url deleted");
          // File deleted successfully
        })
        .catch(function (error) {
          console.log("Não apagado");
          // Uh-oh, an error occurred!
        });
    } */
    if (image) {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setFilename(url);
              setImage1(image.name);
              salvar(url);
            });
        }
      );
    } else {
      salvar("Sem imagem");
    }
  };

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        <div className="container-xxl mt-2">
          <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
            <h3 className="section-title ff-secondary text-center text-primary fw-normal ">
              Meu perfil
            </h3>
          </div>
          <div className="row g-4 justify-content-center">
            {/* -------------------FORMULÁRIO DE REGISTO------------- */}
            <div className="col-xl-8">
              <Paper elevation={1} className="p-4">
                <form onSubmit={formSubmit}>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      {usuarioLogado?.img_link === "Sem imagem" ? (
                        <Avatar
                          alt={usuarioLogado?.nome}
                          title={usuarioLogado?.nome}
                          className="avatar-style1"
                        />
                      ) : (
                        <Avatar
                          src={usuarioLogado?.img_link}
                          alt={usuarioLogado?.nome}
                          title={usuarioLogado?.nome}
                          className="avatar-style1"
                        />
                      )}
                    </div>
                    <div className="col-12 justify-content-center mb-3">
                      <h3 className="">{usuarioLogado?.nome}</h3>
                      <small className="text-success">
                        {usuarioLogado?.tipo}
                      </small>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating">
                        <ThemeProvider theme={theme}>
                          <TextField
                            size="small"
                            margin="normal"
                            label="Nome completo:"
                            fullWidth
                            name="nome"
                            type={"text"}
                            id="nome"
                            value={nome}
                            className={classes.floatingLabelFocusStyle}
                            defaultValue={nome}
                            error={errorNome}
                            helperText={errorNomeMessage}
                            onChange={(e) => setNome(e.target.value)}
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon
                                    /* style={{ color: "#EA8B0F" }} */ className="text-dark"
                                  />
                                </InputAdornment>
                              ),
                              disableUnderline: !editable,
                            }}
                          />
                        </ThemeProvider>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          margin="normal"
                          size="small"
                          fullWidth
                          name="username:"
                          label="Username"
                          type={"text"}
                          id="username"
                          className={classes.floatingLabelFocusStyle}
                          value={username}
                          defaultValue={username}
                          error={errorUsername}
                          helperText={errorUsernameMessage}
                          onChange={(e) => setUsername(e.target.value)}
                          InputProps={{
                            readOnly: !editable,
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonOutlineIcon
                                  /* style={{ color: "#EA8B0F" }} */ className="text-dark"
                                />
                              </InputAdornment>
                            ),
                            disableUnderline: !editable,
                          }}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          size="small"
                          margin="normal"
                          fullWidth
                          name="email"
                          label="Email:"
                          type={"text"}
                          id="email"
                          className={classes.floatingLabelFocusStyle}
                          value={email}
                          defaultValue={email}
                          error={errorEmail}
                          helperText={errorEmailMessage}
                          onChange={(e) => setEmail(e.target.value)}
                          InputProps={{
                            readOnly: !editable,
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon
                                  /* style={{ color: "#EA8B0F" }} */ className="text-dark"
                                />
                              </InputAdornment>
                            ),
                            disableUnderline: !editable,
                          }}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          size="small"
                          margin="normal"
                          fullWidth
                          name="contacto"
                          label="Contacto:"
                          type={"text"}
                          id="contacto"
                          className={classes.floatingLabelFocusStyle}
                          value={contacto}
                          defaultValue={contacto}
                          error={errorContacto}
                          helperText={errorContactoMessage}
                          onChange={(e) => setContacto(e.target.value)}
                          InputProps={{
                            readOnly: !editable,
                            startAdornment: (
                              <InputAdornment position="start">
                                <CallIcon
                                  /* style={{ color: "#EA8B0F" }} */ className="text-dark"
                                />
                              </InputAdornment>
                            ),
                            disableUnderline: !editable,
                          }}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-md-6">
                      <ThemeProvider theme={theme}>
                        <TextField
                          size="small"
                          margin="normal"
                          fullWidth
                          name="senha"
                          label="Senha:"
                          type={showPassword ? "text" : "password"}
                          id="senha"
                          className={classes.floatingLabelFocusStyle}
                          value={senha}
                          defaultValue={senha}
                          error={errorSenha}
                          helperText={errorSenhaMessage}
                          onChange={(e) => setSenha(e.target.value)}
                          autoComplete="current-password"
                          InputProps={{
                            readOnly: !editable,
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon
                                  /* style={{ color: "#EA8B0F" }} */ className="text-dark"
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            disableUnderline: !editable,
                          }}
                        />
                      </ThemeProvider>
                    </div>

                    {editable ? (
                      <div className="col-md-6 text-start pt-1 d-flex">
                        <input
                          type="file"
                          id="filename"
                          hidden
                          onChange={handleChangeImage}
                        />

                        <label for="filename" className="label1 mt-4">
                          <Icon
                            icon="heroicons-outline:upload"
                            className="pt-1"
                            width="24"
                            height="24"
                            inline={true}
                          />
                          Editar foto
                        </label>

                        <small id="file-chosen" style={{ marginTop: 30 }}>
                          {image1}
                        </small>
                      </div>
                    ) : (
                      <div className="col-md-6">
                        <ThemeProvider theme={theme}>
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            name="tipo"
                            label="Nível de utilizador:"
                            type={"text"}
                            id="tipo"
                            className={classes.floatingLabelFocusStyle}
                            value={tipo}
                            defaultValue={tipo}
                            onChange={handleChangeTipo}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AssignmentIndIcon
                                    /* style={{ color: "#EA8B0F" }} */ className="text-dark"
                                  />
                                </InputAdornment>
                              ),
                              disableUnderline: true,
                            }}
                          />
                        </ThemeProvider>
                      </div>
                    )}

                    {/* <div className="divider"></div> */}

                    <div className="row g-3 ">
                      {editable ? (
                        <>
                          <div className="col-6 text-start">
                            <Button
                              style={{
                                color: "white",
                                textTransform: "capitalize",
                                width: 130,
                              }}
                              onClick={() => cancelEdit()}
                              className="bg-danger text-light fw-bold"
                              variant="contained"
                              startIcon={
                                <CancelIcon className="" fontSize="small" />
                              }
                            >
                              Cancelar
                            </Button>
                          </div>

                          <div className="col-6 text-end">
                            <Button
                              variant="contained"
                              type="submit"
                              className="bg-primary text-light fw-bold"
                              style={{
                                color: "white",
                                textTransform: "capitalize",
                                width: 130,
                              }}
                              disabled={loader}
                              startIcon={
                                loader ? (
                                  <Spinner
                                    animation="border"
                                    style={{ width: 24, height: 24 }}
                                  />
                                ) : (
                                  <SaveIcon />
                                )
                              }
                              onClick={() => requiredFields()}
                            >
                              Salvar
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-6 text-start">
                            <Fab
                              variant="extended"
                              className=" text-light bg-danger fw-bold ms-1 button-details1 mb-2"
                              onClick={() => navigate(-1)}
                              style={{
                                backgroundColor: "#3f51b5",
                              }}
                            >
                              <Icon
                                className="me-2"
                                icon="icon-park-outline:back"
                                color="white"
                                width="20"
                                height="20"
                                inline={true}
                              />
                            </Fab>
                          </div>{" "}
                          <div className="col-6 text-end">
                            <Fab
                              variant="extended"
                              className=" text-light bg-primary fw-bold ms-1 button-details1 mb-2"
                              onClick={() => setEditable(true)}
                              style={{
                                backgroundColor: "#3f51b5",
                              }}
                            >
                              <EditIcon className="" />
                            </Fab>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </Paper>
            </div>
          </div>
        </div>
      </Container>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={mensagem}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />

      <Footer />
    </div>
  );
}
