import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import doadores from "../img/doadores.png";
import Footer from "./footer";
import {
  Button,
  Container,
  TextField,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./appbarAdmin";
import { Spinner } from "react-bootstrap";
import SnackBar from "./snackbar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EA8B0F", //your color
    },
  },
});
const useStyles = makeStyles((theme) => ({
  list: {
    width: 270,
    maxHeight: "auto",
    height: "310%",
    color: "white",
    backgroundColor: "#0f172b",
    overflowX: "hidden",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(11),
    backgroundColor: "",
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
  cardGrid: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
  },
}));

export default function Doadores(props) {
  var usuarioLogado = JSON.parse(localStorage.getItem("userLogado"));

  const classes = useStyles();
  const [openSide, setOpenSide] = useState(false);
  const [loader, setLoader] = useState(false);
  let navigate = useNavigate();

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [contacto, setContacto] = useState();
  const [morada, setMorada] = useState();
  const [pais, setPais] = useState();
  const [empresa, setEmpresa] = useState();

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorContacto, setErrorContacto] = useState(false);
  const [errorContactoMessage, setErrorContactoMessage] = useState("");

  const [errorMorada, setErrorMorada] = useState(false);
  const [errorMoradaMessage, setErrorMoradaMessage] = useState("");

  const [errorPais, setErrorPais] = useState(false);
  const [errorPaisMessage, setErrorPaisMessage] = useState("");

  const [errorEmpresa, setErrorEmpresa] = useState(false);
  const [errorEmpresaMessage, setErrorEmpresaMessage] = useState("");

  const dados = {
    nome: nome,
    email: email,
    contacto: contacto,
    morada: morada,
    pais: pais,
    empresa: empresa,
  };

  const cleanFields = () => {
    setNome("");
    setEmail("");
    setContacto("");
    setMorada("");
    setPais("");
    setEmpresa("");
  };

  const formSubmit = (event) => {
    event.preventDefault();
    if (
      errorNome === false &&
      errorMorada === false &&
      errorContacto === false &&
      errorEmail === false &&
      errorPais === false &&
      errorEmpresa === false
    ) {
      salvar();
    } else {
      console.log("N");
    }
  };

  const requiredFields = () => {
    const re = /^[0-9\b]+$/;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (morada === "" || morada === undefined) {
      setErrorMorada(true);
      setErrorMoradaMessage("Por favor, preencha este campo!");
    } else {
      setErrorMorada(false);
      setErrorMoradaMessage(true);
    }
    if (
      contacto === "" ||
      contacto?.length < 9 ||
      contacto === undefined ||
      !contacto?.match(re)
    ) {
      setErrorContacto(true);
      setErrorContactoMessage(
        "Número de telefone inválido, campo obrigatório!"
      );
    } else {
      setErrorContacto(false);
      setErrorContactoMessage(true);
    }
    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }

    if (pais === "" || pais === undefined) {
      setErrorPais(true);
      setErrorPaisMessage("Por favor, preencha este campo!");
    } else {
      setErrorPais(false);
      setErrorPaisMessage(true);
    }
    if (empresa === "" || empresa === undefined) {
      setErrorEmpresa(true);
      setErrorEmpresaMessage("Por favor, preencha este campo!");
    } else {
      setErrorEmpresa(false);
      setErrorEmpresaMessage(true);
    }
  };

  const salvar = () => {
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        nome: dados.nome,
        email: dados.email,
        contacto: dados.contacto,
        morada: dados.morada,
        pais: dados.pais,
        empresa: dados.empresa,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/doadores/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        saveEvent(
          usuarioLogado.usuario,
          "Registo",
          `Registou o doador  "${dados.nome}", da empresa ${dados.empresa}.`
        );
        cleanFields();
        setLoader(false);
        setOpen(true);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const saveEvent = (user, event, description) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        usuario: user,
        evento: event,
        descricao: description,
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/logs/save", requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  return (
    <div>
      <Sidebar openSide={openSide} setOpenSide={setOpenSide} />

      <Container className={classes.cardGrid}>
        <div className="container-xxl py-2 mt-2">
          <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h2 className="section-title ff-secondary text-center text-primary fw-normal ">
                Doadores
              </h2>
              <h3 className="mb-5">Formulário de registo</h3>
            </div>
            <div className="row g-4" style={{ marginTop: -50 }}>
              <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                <a href={doadores}>
                  <img
                    src={doadores}
                    alt="Holding hands"
                    style={{ width: "100%", height: "100%" }}
                  />
                </a>
              </div>
              <div className="col-md-6">
                <div className="wow fadeInUp mt-2" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-1">
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            size={"small"}
                            fullWidth
                            name="nome"
                            label="Nome Completo*"
                            className="mb-4"
                            type={"text"}
                            id="nome"
                            value={nome}
                            error={errorNome}
                            helperText={errorNomeMessage}
                            onChange={(e) => setNome(e.target.value)}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            size={"small"}
                            fullWidth
                            name="morada"
                            className="mb-4"
                            label="Morada*"
                            type={"text"}
                            id="morada"
                            value={morada}
                            error={errorMorada}
                            helperText={errorMoradaMessage}
                            onChange={(e) => setMorada(e.target.value)}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            size={"small"}
                            fullWidth
                            name="contacto"
                            className="mb-4"
                            label="Contacto*"
                            type={"text"}
                            id="contacto"
                            value={contacto}
                            error={errorContacto}
                            helperText={errorContactoMessage}
                            onChange={(e) => setContacto(e.target.value)}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            size={"small"}
                            fullWidth
                            name="email"
                            className="mb-4"
                            label="Email*"
                            type={"text"}
                            id="email"
                            value={email}
                            error={errorEmail}
                            helperText={errorEmailMessage}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-md-6">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            size={"small"}
                            fullWidth
                            name="pais"
                            className="mb-4"
                            label="País de residência*"
                            type={"text"}
                            id="pais"
                            value={pais}
                            error={errorPais}
                            helperText={errorPaisMessage}
                            onChange={(e) => setPais(e.target.value)}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-md-6">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            size={"small"}
                            fullWidth
                            name="empresa"
                            className="mb-4"
                            label="Empresa*"
                            type={"text"}
                            id="empresa"
                            value={empresa}
                            error={errorEmpresa}
                            helperText={errorEmpresaMessage}
                            onChange={(e) => setEmpresa(e.target.value)}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-12 justify-content-center d-flex ">
                        <Button
                          variant="contained"
                          className="me-1 bg-danger text-light fw-bold"
                          style={{ width: "49%" }}
                          onClick={() => navigate(-1)}
                        >
                          <Icon
                            className="me-2"
                            icon="icon-park-outline:back"
                            color="white"
                            width="20"
                            height="20"
                            inline={true}
                          />
                          Voltar
                        </Button>

                        <Button
                          variant="contained"
                          type="submit"
                          className="ms-1 bg-primary text-light fw-bold"
                          onClick={() => requiredFields()}
                          style={{ width: "49%" }}
                          disabled={loader}
                        >
                          {loader ? (
                            <Spinner
                              animation="border"
                              style={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <Icon
                              className="me-2"
                              icon="fluent:save-arrow-right-20-regular"
                              color="white"
                              width="24"
                              height="24"
                              inline={true}
                            />
                          )}
                          Salvar
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={"Doador registado com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={4000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => navigate("/doadores/lista")}
            style={{ textDecoration: "underline" }}
          >
            Ver lista
          </Button>
        }
      />

      <Footer />
    </div>
  );
}
