import React, { useEffect, useState } from "react";
import Admin from "../../../components/casosAdmin";

const SaudeRejeitadas = () => {
  const [rejeitadas, setRejeitadas] = useState();
  const [loading, setLoading] = useState(true);
  const [existentes, setExistentes] = useState(false);
  const [existentes1, setExistentes1] = useState(false);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setDados();
  }, []);

  const setDados = () => {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        tipo_caso: "Saude",
        estado: "Rejeitado",
      }),
    };
    fetch("https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/filterByAll", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setRejeitadas(data);
        if (data?.length !== 0) {
          setExistentes(true);
        } else {
          setExistentes(false);
        }
      });
  };

  return (
    <div>
      <Admin
        titulo={"Saúde"}
        lista={rejeitadas}
        categoria={"Casos rejeitados"}
        loading={loading}
        existentes={existentes}
        setExistentes={setExistentes}
        setExistentes1={setExistentes1}
        existentes1={existentes1}
        setDados={setDados}
        nada={"Não tem casos de saúde rejeitados."}
        tipoCaso={"Saude"}
        estado={"Rejeitado"}
        setResultado={setRejeitadas}
      />
    </div>
  );
};
export default SaudeRejeitadas;
