import React, { useEffect, useState } from "react";
import Appbar from "./appbar";
import logoprov from "../img/logoprov.png";
import jquery from "jquery";
import Footer from "./footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Snackbar } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Spinner } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import { Icon } from "@iconify/react";
import EventIcon from "@material-ui/icons/Event";
import Confirmacao from "./confirmarOperacao";
import EditIcon from "@material-ui/icons/Edit";
import dateFormat from "dateformat";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DoneAllIcon from "@material-ui/icons/DoneAll";

export default function Detalhes(props) {
  (function ($) {
    "use strict";

    // Spinner
    var spinner = function () {
      setTimeout(function () {
        if ($("#spinner").length > 0) {
          $("#spinner").removeClass("show");
        }
      }, 1);
    };
    spinner();
  })(jquery);

  const { id } = useParams();
  const navigate = useNavigate();
  const [pedido, setPedido] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/show/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setPedido(data.data);
        setLoading(false);
      });
  }, [id]);

  const [deleting, setDeleting] = useState(false);

  const apagar = () => {
    setDeleting(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(`https://hope.academiavirtual.ac.mz/api_hope.php/api/casos/delete/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDeleting(false);
        setShowDelete(false);
        localStorage.setItem("eliminado", JSON.stringify(true));
        navigate("/meusPosts");
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  /* ----------MODAL - APAGAR-------- */
  const [showDelete, setShowDelete] = useState(false);
  const openModalDelete = () => {
    setShowDelete(true);
  };
  const closeModalDelete = () => {
    setShowDelete(false);
  };

  var snackState = JSON.parse(localStorage.getItem("editado"));
  const [open, setOpen] = useState(snackState);
  const handleClose = (event, reason) => {
    setOpen(false);
    localStorage.setItem("editado", JSON.stringify(false));
  };

  return (
    <div>
      <div className="container-fluid position-relative p-0">
        <Appbar />
        {/* -----------------BANNER - CAPPA--------------- */}
        <div className="container-fluid py-5 bg-dark hero-header-detalhes mb-5">
          <div className="container text-center my-5 pt-2">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Detalhes - {pedido?.tipo_caso === "Projecto" && "Ideia"}{" "}
              {pedido?.tipo_caso === "Saude" && "Saúde"}{" "}
              {pedido?.tipo_caso === "Estudos" && "Estudos"}{" "}
              {pedido?.tipo_caso === "Denuncia" && "Denúncia"}
            </h1>
          </div>
        </div>
      </div>

      {/* ---------------- HORA DE AGIR -------------- */}
      <div className="container-xxl py-2 mt-5">
        <div className="container">
          <div className="row g-4" style={{ marginTop: -50 }}>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <a
                href={
                  pedido?.img_link === "Sem imagem" ||
                  pedido?.img_link === "capa.png"
                    ? logoprov
                    : pedido?.img_link
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    pedido?.img_link === "Sem imagem" ||
                    pedido?.img_link === "capa.png"
                      ? logoprov
                      : pedido?.img_link
                  }
                  alt={pedido?.nome}
                  title={pedido?.nome}
                  style={{ width: "100%", height: "auto" }}
                />
              </a>
            </div>
            <div className="col-md-6 text-start">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                {loading ? (
                  <div className="mt-5 pt-5  text-center d-flex w-100 align-items-center justify-content-center">
                    <div
                      className="spinner-border text-primary"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="row g-3">
                      <div className="col-12 text-center">
                        <h3>{pedido?.nome}</h3>
                      </div>

                      <div className="col-md-4">
                        <small className="fw-bold text-primary">Morada: </small>
                      </div>
                      <div className="col-md-8">
                        <LocationOnIcon
                          className="me-1"
                          style={{ fontSize: 17, marginTop: -2 }}
                        />
                        {pedido?.morada}
                      </div>
                      <div className="col-md-4">
                        <small className="fw-bold text-primary">
                          Contacto:{" "}
                        </small>
                      </div>
                      <div className="col-md-8">
                        <LocalPhoneIcon
                          className="me-1"
                          style={{ fontSize: 17, marginTop: -2 }}
                        />
                        {pedido?.contacto} / {pedido?.contacto_op}
                      </div>

                      <div className="col-md-4">
                        <small className="fw-bold text-primary">
                          Breve descrição:{" "}
                        </small>
                      </div>
                      <div className="col-md-8">
                        <AccessibilityNewIcon
                          className="me-1"
                          style={{ fontSize: 17, marginTop: -2 }}
                        />
                        {pedido?.d_curta}
                      </div>

                      <div className="col-md-12">
                        <small className="fw-bold text-primary">
                          História:{" "}
                        </small>
                      </div>
                      <div className="col-md-12">{pedido?.d_longa}</div>

                      <div className="col-md-4">
                        <small className="fw-bold text-primary">
                          Data de publicação:{" "}
                        </small>
                      </div>
                      <div className="col-md-8">
                        <EventIcon
                          className="me-1"
                          style={{ fontSize: 17, marginTop: -2 }}
                        />
                        {dateFormat(pedido?.created_at, "dd/mm/yyyy")}
                      </div>

                      <div className="col-md-4">
                        <small className="fw-bold text-primary">Estado: </small>
                      </div>
                      <div className="col-md-8">
                        {pedido.estado === "Pendente" && (
                          <>
                            <span className="text-primary fw-bold">
                              <HourglassEmptyIcon
                                className="me-1"
                                style={{ fontSize: 17, marginTop: -2 }}
                              />

                              {pedido?.estado}
                            </span>
                          </>
                        )}

                        {pedido.estado === "Aprovado" && (
                          <>
                            <span className="text-success fw-bold">
                              <CheckCircleOutlineIcon
                                className="me-1"
                                style={{ fontSize: 17, marginTop: -2 }}
                              />

                              {pedido?.estado}
                            </span>
                          </>
                        )}

                        {pedido.estado === "Rejeitado" && (
                          <>
                            <span className="text-danger fw-bold">
                              <ThumbDownIcon
                                className="me-1"
                                style={{ fontSize: 17, marginTop: -2 }}
                              />

                              {pedido?.estado}
                            </span>
                          </>
                        )}

                        {pedido.estado === "Fechado" && (
                          <>
                            <span className="text-dark fw-bold">
                              <BlockIcon
                                className="me-1"
                                style={{ fontSize: 17, marginTop: -2 }}
                              />

                              {pedido?.estado}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="divider"></div>

                      <div className="">
                        {pedido.estado !== "Pendente" ? (
                          <div className="d-flex w-100 justify-content-center text-center">
                            <div className="w-50 text-center">
                              <Button
                                size="small"
                                color="primary"
                                className="fw-bold text-danger p-2 ps-5 pe-5 actions-button"
                                style={{ textTransform: "capitalize" }}
                                onClick={() => openModalDelete()}
                                startIcon={<DeleteIcon />}
                              >
                                Apagar
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex w-100 justify-content-between text-center">
                            <div className="w-50 text-center">
                              <Button
                                size="small"
                                color="primary"
                                className="fw-bold text-danger p-2 ps-5 pe-5 actions-button"
                                style={{ textTransform: "capitalize" }}
                                onClick={() => openModalDelete()}
                                startIcon={<DeleteIcon />}
                              >
                                Apagar
                              </Button>
                            </div>
                            <div className=" ">
                              <Link
                                to={"/editarCaso/" + pedido.id}
                                state={{ state: pedido }}
                              >
                                <Button
                                  size="small"
                                  color="primary"
                                  className="fw-bold p-2 ps-5 pe-5 actions-button"
                                  style={{ textTransform: "capitalize" }}
                                  startIcon={<EditIcon className="" />}
                                >
                                  Editar
                                </Button>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------- HORA DE AGIR -------------- */}

      {/* ------- ------- FOOTER ------- ------- */}
      <Footer />
      {/* ------- ------- FOOTER ------- ------- */}

      {/* <BackTop /> */}

      {/* ---------CONFIRMAAÇÃO DE ELIMINAÇÃO DE CASO--------- */}
      <Confirmacao
        show={showDelete}
        closeModal={closeModalDelete}
        closeModal1={() => apagar()}
        confirmar={"Confirmar"}
        iconn={
          deleting ? (
            <Spinner animation="border" style={{ width: 24, height: 24 }} />
          ) : (
            <DeleteOutlineIcon fontSize="small" className="me-1" />
          )
        }
        icon={
          <Icon
            className="me-1"
            icon="emojione:warning"
            width="20"
            height="20"
            inline={true}
          />
        }
        titulo="Atenção!"
        descricao={"Tem certeza que pretende eliminar a publicação?"}
        color1="text-danger"
      />

      <Snackbar
        key={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          <>
            <DoneAllIcon className="me-1 text-primary" />O seu post foi
            actualizado com sucesso!
          </>
        }
      />
    </div>
  );
}
